import { TeacherCourseFormValues } from 'pages/teachers/TeacherCoursePage/components/teacherCourseSchema'

export const buildTeacherCourseParams = (data: TeacherCourseFormValues) => {
  const courseModulesAttributes = data.courseModules?.map(courseModule => {
    const { courseLectures, ...restCourseModule } = courseModule
    const courseLecturesAttributes = courseLectures?.map(courseLecture => {
      const courseLecturePlayableScoresAttributes = courseLecture.courseLecturePlayableScores?.map(
        playableScore => {
          const { rhfId: _, ...restPlayableScore } = playableScore
          return restPlayableScore
        },
      )
      const { courseLecturePlayableScores: _, ...courseLectureParams } = courseLecture
      return {
        ...courseLectureParams,
        courseLecturePlayableScoresAttributes,
      }
    })
    return { ...restCourseModule, courseLecturesAttributes }
  })

  const { title, description } = data
  return { title, description, courseModulesAttributes }
}
