import styled from 'styled-components'

import { variables } from 'styles/variables'

export const TunerContainer = styled.div`
  padding: 60px;
  height: calc(100vh - ${variables.topbarHeight}px);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100vw;
    padding: 0;
    position: absolute;
    left: 0;
  }
`
