import { ReactNode } from 'react'

import { Box, Typography } from '@mui/material'

type Props = {
  index: number
  value: number
  children: ReactNode
}

export const TabPanel = ({ index, value, children, ...rest }: Props) => {
  return (
    value === index && (
      // @ts-ignore update Material UI
      <Box sx={{ p: 1 }} {...rest}>
        <Typography component="div" style={{ lineHeight: 'normal' }}>
          {children}
        </Typography>
      </Box>
    )
  )
}
