import styled, { css } from 'styled-components'

export const containerStyles = css`
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.components.PaAlert.success.background};
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  width: 80px;

  &:after,
  &:before {
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px;
    background: ${({ theme }) => theme.palette.colors.white};
  }

  &:before {
    top: -7px;
    left: -33px;
    transform: rotate(-45deg);
    transform-origin: 60px 60px;
    border-radius: 120px 0 0 120px;
  }

  &:after {
    top: -11px;
    left: 30px;
    transform-origin: 0 60px;
    transform: rotate(-45deg);
    border-radius: 0 120px 120px 0;
  }
`
export const placeholderStyles = css`
  width: 80px;
  height: 80px;
  top: -4px;
  left: -4px;
  position: absolute;
  box-sizing: content-box;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.components.PaAlert.success.shadow};
  z-index: 2;
`
export const iconStyles = css`
  background-color: ${({ theme }) => theme.palette.components.PaAlert.success.background};
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
`
export const Fix = styled.div`
  width: 5px;
  height: 90px;
  top: 8px;
  left: 28px;
  position: absolute;
  transform: rotate(-45deg);
  background: ${({ theme }) => theme.palette.colors.white};
  z-index: 1;
`
