import { useEffect } from 'react'

import { PageContainer } from 'components/ui'
import { CourseList } from 'pages/components'
import { CoursesProvider, useCoursesContext } from 'providers'

export const CoursesPageFC = () => {
  const { fetchCourses, courses } = useCoursesContext()

  useEffect(() => {
    fetchCourses()
  }, [])

  return (
    <PageContainer title="Violin Courses">
      <CourseList courses={courses} />
    </PageContainer>
  )
}

export const CoursesPage = () => (
  <CoursesProvider>
    <CoursesPageFC />
  </CoursesProvider>
)
