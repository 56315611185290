import { useCallback, useEffect, useState } from 'react'

import { TextField } from '@material-ui/core'

import { Container } from './styles'

// TODO: remove in favor of EditableTextField
export const EditableField = ({ text = '', readOnly = false, error, onSubmit = () => {} }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState(text)

  useEffect(() => {
    if (!error) return

    setValue(text)
  }, [error]) /* eslint-disable-line */

  const handleClick = e => {
    e.stopPropagation()
  }

  const handleDoubleClick = useCallback(
    e => {
      e.stopPropagation()

      if (isEdit || readOnly) return
      setIsEdit(true)
    },
    [isEdit, readOnly],
  )

  const handleChange = useCallback(e => {
    setValue(e.target.value)
  }, [])

  const handleBlur = useCallback(() => {
    setIsEdit(false)

    if (value === text) return
    onSubmit(value)
  }, [value, text, onSubmit])

  const handleFocus = useCallback(e => {
    e.target.select()
  }, [])

  const handleKeySubmit = useCallback(
    e => {
      if (e.key === 'Enter') handleBlur()
      if (e.key !== 'Escape') return

      setIsEdit(false)
      setValue(text)
    },
    [text, handleBlur],
  )

  return (
    <Container className="editable-field" onDoubleClick={handleDoubleClick} onClick={handleClick}>
      <p>{value}</p>

      {isEdit && (
        <TextField
          autoFocus
          multiline
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeySubmit}
          variant="outlined"
        />
      )}
    </Container>
  )
}

export default EditableField
