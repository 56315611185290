import { OCTAVE_REGEX } from 'components/Composer/constants'
import { AbcNotation } from 'components/Composer/models/types'

import { MusicalElementSettingDecorator } from './MusicalElementSettingDecorator'

export class OctaveDecorator extends MusicalElementSettingDecorator {
  get abcNotation(): AbcNotation {
    if ('type' in this.musicalElement && this.musicalElement.type !== 'note')
      return this.musicalElement.abcNotation

    this.musicalElement.settings.octave = this.settings.value!
    this.musicalElement.settings.value = this.removeOctave()
    return this.musicalElement.abcNotation
  }

  private removeOctave() {
    return this.musicalElement.settings.value!.replace(OCTAVE_REGEX, '')
  }
}
