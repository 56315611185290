import * as Yup from 'yup'

export const userUpdatePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required().label('Current Password'),
  password: Yup.string().required().min(6).label('New Password'),
  password_confirmation: Yup.string()
    .required()
    .label('Confirm New Password')
    .nullable()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})
