import { useTransition } from 'react-spring'

import { ToastMessage } from 'hooks/useToast'

import { ToastContent } from './ToastContent'
import { Container } from './styles'

type Props = {
  messages: ToastMessage[]
}

export const Toast = ({ messages }: Props) => {
  const messagesWithTransitions = useTransition(messages, {
    key: (message: ToastMessage) => message.id,
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 },
  })

  return (
    <Container>
      {messagesWithTransitions((style, item, transition) => (
        <ToastContent key={transition.key} message={item} style={style} />
      ))}
    </Container>
  )
}
