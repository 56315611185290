import { Score } from 'models'

import { AbcRenderer, MidiJsonNote } from '..'

import {
  ABC_CAPTION_REGEX,
  ABC_NOTES_REGEX,
  DEFAULT_BPM,
  DEFAULT_UNIT_NOTE_LENGTH,
  TEMPO_REGEX,
  TUNE_HEADER_LAST_LINE_REGEX,
} from './constants'

type Constructor = {
  renderer: AbcRenderer
}

export class AbcTune {
  bpm: number
  originalBpm: number
  transposition: number
  renderer: AbcRenderer

  constructor({ renderer }: Constructor) {
    this.renderer = renderer
  }

  initTune(score: Score) {
    const tuneWithFlags = this.#normalizeTune(score)

    this.bpm = score.bpm
    this.originalBpm = score.bpm
    this.transposition = 0

    return tuneWithFlags
  }

  getTune(abcString: string) {
    return this.renderer.transpose(abcString, 0)
  }

  toggleCaption(tune: string, playerNotes: MidiJsonNote[]) {
    const isCaptionOn = tune.match(ABC_CAPTION_REGEX)
    return isCaptionOn ? this.#removeCaption(tune) : this.#addCaption(tune, playerNotes)
  }

  transpose(tune: string, step: number) {
    const offset = step - this.transposition
    this.transposition = step
    return this.renderer.transpose(tune, offset)
  }

  changeBpm(tune: string, bpm: number) {
    this.bpm = bpm

    const tuneWithTempo = tune.replace(TEMPO_REGEX, (_, noteUnitLength) => {
      return `Q:${noteUnitLength}=${bpm}\n`
    })

    return tuneWithTempo
  }

  #addCaption(tune: string, playerNotes: MidiJsonNote[]) {
    const [header, notation, separator] = this.#splitTune(tune)
    let index = 0
    const tuneWithCaption = notation.replace(ABC_NOTES_REGEX, match => {
      const withCaptions = `"${playerNotes[index]?.pitch}"${match}`
      index++
      return withCaptions
    })

    return header + separator + tuneWithCaption
  }

  #removeCaption(tune: string) {
    const [header, notation, separator] = this.#splitTune(tune)
    const tuneWithCaption = notation.replace(ABC_CAPTION_REGEX, '')
    return header + separator + tuneWithCaption
  }

  #splitTune(tune: string) {
    const [header, separator, _, notation] = tune.split(TUNE_HEADER_LAST_LINE_REGEX)
    return [header, notation, separator]
  }

  #normalizeTune(score: Score) {
    /* Normalize the tune string by adding missing flags, such as bpm */
    const [header, notation, separator] = this.#splitTune(score.abc)

    const { groups } = header.match(TEMPO_REGEX) || {}
    const { tempo, noteUnitLength } = groups || {}

    if (tempo && noteUnitLength) return score.abc

    const bpm = tempo?.trim() || DEFAULT_BPM
    const flag = `Q:${DEFAULT_UNIT_NOTE_LENGTH}=${bpm}\n`

    if (!tempo && !noteUnitLength) return header + flag + separator + notation

    const headerWithTempo = score.abc.replace(TEMPO_REGEX, flag)
    return headerWithTempo + separator + notation
  }
}
