import { Box, CardContent, CardMedia, Typography } from '@mui/material'

import { TypographyEllipsis } from 'components/ui'
import { Course } from 'models'
import { parameterize } from 'utils'

import { Card } from './styles'

type Props = {
  course: Course
}

export const CourseCard = ({ course }: Props) => {
  return (
    <Card to={`${parameterize(course.title)}/${course.id}`} key={course.id}>
      <CardMedia component="img" sx={{ width: 150 }} image={course.thumbnailUrl} alt={course.title} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h5">{course.title}</Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            {course.teacher.firstName} {course.teacher.lastName}
          </Typography>
          <TypographyEllipsis variant="body2" color="text.secondary">
            {course.description}
          </TypographyEllipsis>
        </CardContent>
      </Box>
    </Card>
  )
}
