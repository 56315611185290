import { KeepAwake } from '@capacitor-community/keep-awake'

export const useKeepAwake = () => {
  const keepAwake = () => {
    KeepAwake.keepAwake()
  }

  const allowSleep = () => {
    KeepAwake.allowSleep()
  }

  return { keepAwake, allowSleep }
}
