import { Tab as MaterialTab, Tabs as MaterialTabs, ToggleButton as ToggleButtonMaterial } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { lighten, opacify, transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.nav`
  padding: 8px;
  margin-bottom: 32px;
`
export const Tabs = styled(MaterialTabs)`
  &.MuiTabs-root {
    min-height: fit-content;

    .MuiTabs-indicator {
      height: 100%;
      border-radius: 4px;
      z-index: -1;
    }

    .MuiButtonBase-root {
      text-transform: none;

      &:focus {
        outline: none;
      }
      &.Mui-selected {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }
`
export const Tab = styled(MaterialTab)`
  &.MuiTab-root {
    min-width: 100px;
    min-height: 28px;
    padding: 0 12px;
  }
`
export const ToggleButton = styled(ToggleButtonMaterial)`
  &.MuiToggleButton-root {
    width: 50px;
    height: 40px;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
    padding: 8px;
    transition: background-color 0.2s ease;
    border: 1px solid ${({ theme }) => transparentize(0.8, theme.palette.grey[400])};

    &:hover {
      border: 1px solid ${({ theme }) => lighten(0.05, getButtonHoverColor(theme))};
      background-color: ${({ theme }) => getButtonHoverColor(theme)};
    }

    &:active,
    &.Mui-selected {
      outline: 1px solid ${({ theme }) => lighten(0.1, getButtonHoverColor(theme))};
    }

    &.Mui-selected,
    &.Mui-selected:hover {
      color: ${({ theme: { palette } }) => palette.text.primary};
      background-color: ${({ theme }) => opacify(0.2, getButtonHoverColor(theme))};
    }
  }
`
export const Controls = styled.section`
  position: absolute;
  top: 160px;
  right: 30px;
  display: flex;
  gap: 8px;
`

const getButtonHoverColor = (theme: Theme) => {
  const {
    palette: { mode, transparent },
  } = theme
  return mode === 'light' ? transparent.primary.A100 : transparent.primary.A500
}
