import { useCallback, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

export type ToastMessage = {
  id: Id
  title: string
  description?: string
  type: 'error' | 'success' | 'info'
}
export const useToast = () => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const showToast = useCallback(({ type, title, description }: Omit<ToastMessage, 'id'>) => {
    const id = uuidv4()
    const toast = {
      id,
      type,
      title,
      description,
    }

    setMessages(prevMessages => [...prevMessages, toast])
  }, [])

  const hideToast = useCallback((id: Id) => {
    setMessages(state => state.filter(message => message.id !== id))
  }, [])

  return { showToast, hideToast, messages }
}
