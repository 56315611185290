import { useApi } from 'hooks'
import { CustomScoresEndpoint } from 'routes/locations'

import { buildSearchOptions, SearchOptionApi } from '../utils'

export const useCustomScoreOptionsController = () => {
  const [
    fetchCustomScoreOptions,
    scoreOptions,
    { loading: fetchCustomScoreOptionsLoading, meta: fetchCustomScoresMeta },
  ] = useApi<SearchOptionApi[]>(CustomScoresEndpoint, 'GET')

  return {
    fetchCustomScoreOptions,
    customScoreOptions: buildSearchOptions(scoreOptions),
    fetchCustomScoreOptionsLoading,
    fetchCustomScoresMeta,
  }
}
