import { keyframes } from 'styled-components'

export const animateSuccessTip = keyframes`
	0%, 54% {
		width: 0;
		left: 1px;
		top: 19px;
	}

	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}

	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}

	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
`

export const animateSuccessLong = keyframes`
	0%, 65% {
		width: 0;
		right: 46px;
		top: 54px;
	}

	84% {
		width: 55px;
		right: 0;
		top: 35px;
	}

	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
`

export const rotatePlaceholder = keyframes`
	0%, 5% {
		transform: rotate(-45deg);
	}

	100%, 12% {
		transform: rotate(-405deg);
	}
`

export const animateErrorIcon = keyframes`
	0% {
		transform: rotateX(100deg);
		opacity: 0;
	}

	100% {
		transform: rotateX(0deg);
		opacity: 1;
	}
`

export const animateXLeft = keyframes`
	0%,
  65% {
		left: 82px;
		top: 95px;
		width: 0;
	}

	84% {
		left: 14px;
		top: 33px;
		width: 47px;
	}

	100% {
		left: 17px;
		top: 37px;
		width: 47px;
	}
`

export const animateXRight = keyframes`
	0%,
  65% {
		right: 82px;
		top: 95px;
		width: 0;
	}

	84% {
		right: 14px;
		top: 33px;
		width: 47px;
	}

	100% {
		right: 16px;
		top: 37px;
		width: 47px;
	}
`

export const scaleWarning = keyframes`
	0% {
		transform: scale(1);
	}

	30% {
		transform: scale(1.02);
	}

	100% {
		transform: scale(1);
	}
`

export const pulseWarning = keyframes`
	0% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	30% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		background-color: #F8BB86;
		transform: scale(2);
		opacity: 0;
	}
`

export const pulseWarningIns = keyframes`
	0% {
		background-color: #F8D486;
	}

	100% {
		background-color: #F8BB86;
	}
`
