import { CardContent } from '@mui/material'
import { LinkProps } from 'react-router-dom'

import { CardLink, TypographyEllipsis } from 'components/ui'
import { CourseLecture } from 'models'

type Props = {
  courseLecture: CourseLecture
} & Pick<LinkProps, 'to'>

export const CourseLectureCard = ({ courseLecture, to, ...rest }: Props) => {
  return (
    <CardLink to={to} {...rest}>
      <CardContent>
        <TypographyEllipsis variant="body1" color="text.secondary">
          {courseLecture.title}
        </TypographyEllipsis>
      </CardContent>
    </CardLink>
  )
}
