import { ReactNode } from 'react'

import { Capacitor } from '@capacitor/core'

import { useMobileSettings, useMobileTheme } from 'hooks/mobile'

import { createCtx } from './utils'

type Props = {
  children: ReactNode
}

type MobileContext = ReturnType<typeof useMobileSettings>

export const [useMobileContext, MobileContextProvider] = createCtx<MobileContext>()

export const MobileProvider = ({ children }: Props) => {
  const mobileSettings = useMobileSettings()

  useMobileTheme()

  if (!Capacitor.isNativePlatform()) return children

  return <MobileContextProvider value={mobileSettings}>{children}</MobileContextProvider>
}
