import { Fragment, useCallback, useEffect, useState } from 'react'

import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { BsFolderPlus } from 'react-icons/bs'
import {
  FiChevronDown,
  FiChevronRight,
  FiEdit,
  FiFileText,
  FiFolder,
  FiPlayCircle,
  FiTrash2,
  FiUploadCloud,
} from 'react-icons/fi'
import { IoFolderOpenOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link, useMatch } from 'react-router-dom'

import { EditableField, Loader, Menu } from 'components/ui'
import Alert from 'components/ui/Alert'
import Spinner from 'components/ui/Spinner'
import { useBreakpoint, useDragDrop, useFileManager } from 'hooks'
import { useToastContext } from 'providers'

import {
  Aside,
  Container,
  Controls,
  EmptyState,
  File,
  Folder,
  FolderDisplay,
  FolderDisplayContainer,
  MidiUploadInput,
  TreeItem,
  TreeView,
} from './styles'

const FileManager = ({ readOnly }) => {
  const [hasAlert, setHasAlert] = useState(false)
  const {
    selectedFolder,
    root: rootFolder,
    loadingId,
    loadingAction,
    loadingSelect,
    error,
    type,
  } = useSelector(state => state.folders)
  const { error: scoreError, loading: scoreLoading } = useSelector(state => state.customScores.show)

  const {
    isSelectMode,
    selectedItems,
    toggleSelectMode,
    selectItems,
    selectTreeNode,
    toggleTreeNode,
    deleteItems,
    createFolder,
    expandedIds,
    goToShow,
    dropItem,
    updateItem,
    selectFolder,
    handleContextMenu,
    handleCloseContextMenu,
    contextMenu,
    handleMidiUpload,
  } = useFileManager()

  const { init: initDragDrop } = useDragDrop()
  const { showToast } = useToastContext()
  const { downTo } = useBreakpoint()
  const isCustomScoresRoute = !!useMatch('/me/sheet-music')
  const isFolderEmpty = !loadingSelect && (selectedFolder.subfolders?.length || selectedFolder[type]?.length)
  const { x: menuX, y: menuY, id: menuId, title: menuTitle, readOnly: isScoreReadOnly } = contextMenu
  const isCustom = type === 'customScores'
  const isDeleteDisabled = loadingAction === 'delete' || scoreLoading === 'delete'

  useEffect(() => {
    if (!selectedFolder) return
    initDragDrop()
  }, [selectedFolder, initDragDrop])

  useEffect(() => {
    if (!error && !scoreError) return

    const { title, description } = error || scoreError
    showToast({ type: 'error', title, description })
  }, [error, scoreError, showToast])

  const handleCloseAlert = useCallback(async () => {
    setHasAlert(false)
  }, [])

  const handleDelete = useCallback(async () => {
    deleteItems()
    handleCloseAlert()
  }, [deleteItems, handleCloseAlert])

  const handleDeleteAlert = useCallback(async () => {
    const { folders: folderIds, scores } = selectedItems
    if (!folderIds.length && !scores.length) return

    setHasAlert(true)
  }, [selectedItems])

  const renderTree = useCallback(
    folderList => {
      return folderList.map((folder, index) => {
        if (!folder.name) return null
        const loading = loadingId === folder.id

        return (
          <Fragment key={folder.id}>
            <TreeItem
              id={folder.id}
              nodeId={folder.id}
              label={folder.name}
              draggable="true"
              onDrop={dropItem}
              $index={index}
              collapseIcon={loading ? <Spinner size={14} /> : <FiChevronDown />}
            >
              {folder.subfolders?.length ? renderTree(folder.subfolders) : null}
              {folder[type]?.map((score, scoreIndex) => {
                if (!score.title) return null

                return (
                  <TreeItem
                    id={score.id}
                    key={score.id}
                    nodeId={score.id}
                    label={score.title}
                    draggable="true"
                    data-type="file"
                    $index={(folder.subfolders?.length || 0) + scoreIndex}
                  />
                )
              })}
            </TreeItem>
          </Fragment>
        )
      })
    },
    [dropItem, loadingId, type],
  )

  return (
    <Container>
      {downTo('sm') && (
        <Aside>
          {rootFolder ? (
            <TreeView
              expanded={expandedIds}
              defaultCollapseIcon={<FiChevronDown />}
              defaultExpandIcon={<FiChevronRight />}
              onNodeSelect={selectTreeNode}
              onNodeToggle={toggleTreeNode}
              $selected={selectedFolder?.id}
            >
              {renderTree([rootFolder])}
            </TreeView>
          ) : (
            <Loader />
          )}
        </Aside>
      )}
      <FolderDisplayContainer>
        <Controls>
          <IconButton onClick={toggleSelectMode} size="small">
            {isSelectMode ? (
              <AiFillCheckCircle color={({ theme }) => theme.palette.primary.main} size={20} />
            ) : (
              <AiOutlineCheckCircle size={20} />
            )}
          </IconButton>
          {isCustomScoresRoute && (
            <IconButton size="small" disabled={!!loadingAction}>
              {scoreLoading === 'uploadMidi' ? (
                <Spinner size={14} />
              ) : (
                <>
                  <FiUploadCloud size={20} className="no-stroke" />
                  <MidiUploadInput name="file-input" type="file" accept=".mid" onChange={handleMidiUpload} />
                </>
              )}
            </IconButton>
          )}
          <IconButton size="small" onClick={createFolder} disabled={!!loadingAction}>
            {loadingAction === 'create' ? (
              <Spinner size={14} />
            ) : (
              <BsFolderPlus size={20} className="no-stroke" />
            )}
          </IconButton>
          <IconButton size="small" onClick={handleDeleteAlert} disabled={!isSelectMode || !!loadingAction}>
            {isDeleteDisabled ? <Spinner size={14} /> : <FiTrash2 size={20} />}
          </IconButton>
        </Controls>

        <FolderDisplay>
          {!selectedFolder.id || loadingSelect ? (
            <Loader />
          ) : (
            <>
              {isFolderEmpty ? (
                <>
                  {selectedFolder.subfolders.map((folder, index) => (
                    <Folder
                      button
                      id={folder.id}
                      key={folder.id}
                      draggable="true"
                      selected={selectedItems.folders.includes(folder.id)}
                      onDrop={dropItem}
                      onClick={() => selectItems(folder.id, 'folders')}
                      onDoubleClick={() => selectFolder(folder.id)}
                      $index={index}
                    >
                      <FiFolder />
                      <EditableField
                        text={folder.name}
                        error={error}
                        onSubmit={name => updateItem(folder.id, { name })}
                      />
                    </Folder>
                  ))}

                  {selectedFolder[type].map((score, index) => (
                    <File
                      button
                      id={score.id}
                      key={score.id}
                      draggable="true"
                      data-type="file"
                      selected={selectedItems.scores.includes(score.id)}
                      $index={selectedFolder.subfolders.length + index}
                      onClick={() => selectItems(score.id, 'scores')}
                      onDoubleClick={() => goToShow(getScoreId(isCustom, score))}
                      onContextMenu={e => handleContextMenu(e, getScoreId(isCustom, score))}
                    >
                      <FiFileText />
                      <EditableField
                        text={score.title}
                        readOnly={readOnly}
                        error={scoreError}
                        onSubmit={title =>
                          updateItem(score.id, { title }, { type: 'file', parentId: score.folderId })
                        }
                      />
                    </File>
                  ))}
                </>
              ) : (
                <EmptyState>
                  <IoFolderOpenOutline size={100} />
                  <p>empty folder</p>
                </EmptyState>
              )}

              <Menu
                keepMounted
                open={!!menuX}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={menuX && menuY && { top: menuY, left: menuX }}
              >
                <MenuItem
                  component={Link}
                  onClick={handleCloseContextMenu}
                  to={{ pathname: `/sheet-music/${menuTitle}/${menuId}` }}
                  state={{ custom: isCustom }}
                >
                  <ListItemIcon>
                    <FiPlayCircle />
                  </ListItemIcon>
                  <ListItemText primary="Play" />
                </MenuItem>
                {!readOnly && !isScoreReadOnly && process.env.NODE_ENV === 'development' && (
                  <MenuItem component={Link} onClick={handleCloseContextMenu} to={`/composer/${menuId}`}>
                    <ListItemIcon>
                      <FiEdit />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </FolderDisplay>
      </FolderDisplayContainer>

      <Alert
        kind="warning"
        title="Delete Folders & Files"
        content="Are you sure you want to delete selected items?"
        onOkLabel="Delete"
        onOk={handleDelete}
        onCancel={handleCloseAlert}
        open={hasAlert}
      />
    </Container>
  )
}

export default FileManager

const getScoreId = (isCustom, score) => (isCustom ? score.id : score.scoreId)
