import { useApi } from 'hooks'
import { ScoresEndpoint } from 'routes/locations'

import { buildSearchOptions, SearchOptionApi } from '../utils'

export const useScoreOptionsController = () => {
  const [fetchScoreOptions, scoreOptions, { loading: fetchScoreOptionsLoading, meta: fetchScoresMeta }] =
    useApi<SearchOptionApi[]>(ScoresEndpoint, 'GET')

  return {
    fetchScoreOptions,
    scoreOptions: buildSearchOptions(scoreOptions),
    fetchScoreOptionsLoading,
    fetchScoresMeta,
  }
}
