import { LoadingButton as MaterialLoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'

export const LoadingButton = styled(MaterialLoadingButton)(props => ({
  ...props,
  height: 48,
  '&.MuiLoadingButton-loading': {
    '&.Mui-disabled': {
      backgroundColor: props.theme.palette.primary.main,
    },
  },
}))
