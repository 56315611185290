/* eslint-disable */
import { Container, Boat, Text, Link } from './styles'

export const AppError = () => {
  return (
    <Container>
      <Boat src="https://i.imgur.com/U3vTGjX.png" alt="Something went wrong" />

      <Text>Something went wrong</Text>
      <Link href="/">Back to Home</Link>
    </Container>
  )
}
