import { FormControl, FormControlProps, Select as MaterialSelect, MenuItem, SelectProps } from '@mui/material'

type SelectOption = {
  label: string
  value: string
}

type Props = {
  options: SelectOption[]
  containerProps?: FormControlProps
} & SelectProps

export const Select = ({ options, containerProps, ...rest }: Props) => {
  return (
    <FormControl {...containerProps}>
      <MaterialSelect {...rest}>
        {options.map(option => (
          <MenuItem value={option.value} onClick={e => e.stopPropagation()}>
            {option.label}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  )
}
