import { CourseModule, User } from '..'

export class Course {
  id: Id
  title: string
  description: string
  thumbnailUrl: string
  publishedAt: string

  teacher: User
  courseModules: CourseModule[]

  constructor(courseParams: Course) {
    const { courseModules: courseModulesParams, teacher: teacherParams } = courseParams
    const courseModules = courseModulesParams?.map(params => new CourseModule(params)) || []
    const teacher = new User(teacherParams)

    Object.assign(this, {
      ...courseParams,
      teacher,
      courseModules,
    })
  }
}
