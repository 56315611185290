import { useCallback, useState } from 'react'

import { User } from 'models'

import { CURRENT_USER_KEY } from './constants'

type LocalStorageUserData = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'avatarThumbUrl' | 'admin' | 'pendingConfirmation'
>

export const useStorageCurrentUser = () => {
  const [userData, setUserData] = useState<LocalStorageUserData>(
    JSON.parse(localStorage.getItem(CURRENT_USER_KEY) || '{}'),
  )

  const setCurrentUser = useCallback(
    (user: User) => {
      const { email: _email, ...storageUser } = user

      setUserData(storageUser)
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(storageUser))
    },
    [setUserData],
  )

  return { currentUser: userData, setCurrentUser }
}
