import { keyframes } from 'styled-components'

type Directions = 'up' | 'right' | 'bottom' | 'left'

type FadeSlideAnimationArgs = {
  dirX?: Directions
  dirY?: Directions
}

export const fadeSlideAnimation = ({ dirX = 'left', dirY = 'up' }: FadeSlideAnimationArgs = {}) =>
  keyframes`
  0% {
    opacity: 0;
    transform: translate(
      ${dirX === 'left' ? 10 : -10}px,
      ${dirY === 'up' ? 10 : -10}px
    );
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`
export const shrink = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
`
