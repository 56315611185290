import { COMPOSER_STORAGE_KEY, useAutoSave } from 'hooks'
import { StorageCustomScore } from 'models'
import { getNewestObject, getOldestObject } from 'utils'

import { INITIAL_TUNE } from '../constants'

export const useComposerStorageAutoSave = (initialData: StorageCustomScore) => {
  const params = { storageKey: COMPOSER_STORAGE_KEY }
  const [_, setData, composerStorage] = useAutoSave<StorageCustomScore>(initialData, params)

  const handleAutoSave = (newData: StorageCustomScore) => {
    if (Object.values(newData)[0]?.abc === INITIAL_TUNE) return
    const data = limitStorageSize({ ...composerStorage, ...newData })
    setData(data)
  }

  const getNewestStorageScore = () => {
    return getNewestObject(composerStorage, 'updatedAt')
  }

  return { handleAutoSave, getNewestStorageScore, composerStorage }
}

const limitStorageSize = (data: StorageCustomScore) => {
  const keys = Object.keys(data)
  if (keys.length < 4) return data
  const oldestEntry = getOldestObject(data, 'updatedAt') || ({} as StorageCustomScore[string])
  if (!oldestEntry) return data
  delete data[oldestEntry.id]
  return data
}
