import * as Yup from 'yup'

export const userAvatarSchema = Yup.object().shape({
  file: Yup.mixed<File>()
    .required('An avatar is required')
    .test('fileSize', 'File too large', (file: File) => file && file.size <= 1048576)
    .test(
      'fileFormat',
      'Unsupported Format',
      (file: File) => file && ['image/jpeg', 'image/png'].includes(file.type),
    ),
})
