import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LockOutlined } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import { Button, Input } from 'components/ui'
import { useAuth } from 'hooks'
import { useToastContext } from 'providers'
import { ResetPasswordEndpoint } from 'routes/locations'

import { Container, Form, FormContainer, Link, Title } from '../styles'

import { Footer } from './styles'
import { validateToken } from './utils'

export type ResetPasswordFormValues = Yup.InferType<typeof schema>

const schema = Yup.object().shape({
  password: Yup.string().required().label('Password'),
  reset_password_token: Yup.string().required(),
})

export const ResetPassword = () => {
  const [resetPassword, error, loading, status] = useAuth(ResetPasswordEndpoint, 'PUT')
  const { showToast } = useToastContext()
  const { search } = useLocation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { password: '' },
  })

  const onSubmit = useCallback(
    (data: ResetPasswordFormValues) => {
      if (!validateToken(search, data)) {
        showToast({
          type: 'error',
          title: 'Reset password failed!',
          description: 'A valid token is needed to reset your password.',
        })

        return
      }

      resetPassword({ user: data })
    },
    [resetPassword, search, showToast],
  )

  useEffect(() => {
    if (status !== 204) return

    showToast({
      type: 'success',
      title: 'Password reset successful',
      description: 'Your new password has been saved to your account.',
    })
  }, [status, showToast])

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: 'Reset password failed!',
      description: error.message,
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Reset Password</Title>
          <Input<ResetPasswordFormValues>
            type="password"
            name="password"
            label="Password"
            placeholder="Password"
            icon={LockOutlined}
            control={control}
            autoComplete="current-password"
            errorMessage={errors.password?.message as string}
            sx={{ mt: '0.8rem' }}
          />

          <Button type="submit" sx={{ mt: '1rem' }} loading={loading}>
            Submit
          </Button>

          <Footer>
            <Link to="/sign-in">Sign in to your account</Link>
          </Footer>
        </Form>
      </FormContainer>
    </Container>
  )
}
