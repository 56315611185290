import { MEASURE_DURATION } from 'utils/settings'

import { Bar, ElementReplacerOld, MusicalElement, Rest, TempoManager } from '..'
import {
  getElementsDuration,
  getNoteIndex,
  getSelectedElements,
  // pasteMeasureNotes,
  // populateWithNotes,
  // prepareMeasurePaste,
  // sliceClipboard
} from '../../helpers'

import { Accessors } from '.'

export class Measure {
  constructor() {
    this.notes = [new Rest({ value: 'z8' }), new Bar({ value: '|' })]
    this.prevElements = []
    this.nextElements = []
    this.selectedElements = []
    this.newElements = []
    this.index = 0
    this.emptyBeats = MEASURE_DURATION
    this.finalBar = null

    const accessors = Object.create(Accessors.prototype)
    Object.appendChain(this, Object.getPrototypeOf(accessors))
  }

  toAbc() {
    return this.notes.map(note => note.toAbc()).join('')
  }

  addElement({ value, selectedAbc, settings, type }) {
    this.index = getNoteIndex(selectedAbc, this.notes)
    this.newElements = [MusicalElement.for({ value, settings, type })]
    this.setMeasureProps(selectedAbc)

    const replaced = this.selectedElements[0].replace(this)
    if (!replaced) return

    this.notes = [...this.prevElements, ...replaced]
    this.setEmptyBeats()

    return this.newElements
  }

  deleteElement(selectedAbc) {
    this.index = getNoteIndex(selectedAbc, this.notes)
    this.setMeasureProps(selectedAbc)
    const newElements = TempoManager.createRestsfromTempo(this.selectedElements[0].duration)
    this.newElements = newElements

    const replaced = this.selectedElements[0].replace(this)
    if (!replaced) return

    this.notes = [...this.prevElements, ...replaced]
    this.setEmptyBeats()
  }

  paste(clipboard, composer /* ,measureIndex */) {
    const { selectedAbc } = composer
    const { note: noteIndex } = selectedAbc.start
    this.index = noteIndex
    this.setMeasureProps(selectedAbc)
    this.newElements = clipboard
    this.selectedElements = [...this.selectedElements]
    this.notes = [...this.prevElements, ...this.replace()]
    return []
  }

  // paste(clipboard, measureIndex, noteIndex) {
  // const { notes: prevNotes } = this
  // const finalBar = prevNotes.pop()
  // const isLastMeasure = getElementsDuration(clipboard) <= MEASURE_DURATION
  // const initialParams = { measure: this, measureIndex, noteIndex, clipboard }
  // const initial = prepareMeasurePaste(initialParams)

  // this.emptyBeats = initial.emptyBeats
  // this.notes = initial.elements

  // const elements = pasteMeasureNotes(this, clipboard)
  // this.notes = [...this.notes, ...elements]

  // isLastMeasure && populateWithNotes(this, prevNotes, elements)
  // this.notes = [...this.notes, finalBar]

  // const remainderClipboard = sliceClipboard({ ...initialParams, initial })
  // const shouldAddRests = !remainderClipboard.length && this.emptyBeats > 0

  // if (shouldAddRests) this.fillWithRests()

  // return remainderClipboard
  // }

  fillWithRests() {
    const { notes, emptyBeats } = this
    const lastElem = notes[notes.length - 2]

    const newElements = lastElem.getElementsAfterCreate({
      notes,
      emptyBeats,
      selectedElement: lastElem,
      index: notes.indexOf(lastElem),
    })

    this.notes.splice(-1, 0, ...newElements.rests)
  }

  setMeasureProps(selectedAbc) {
    this.selectedElements = getSelectedElements(selectedAbc, this.notes)
    this.setPrevElements()
    this.setNextElements()
    this.setFinalBar()
  }

  calculateRestsDuration(elements) {
    const list = elements || this.notes
    const rests = list.filter(element => element.type === 'rest')
    return getElementsDuration(rests)
  }

  hasAvailableTempo() {
    return this.calculateTotalTempo() <= MEASURE_DURATION
  }

  calculateTotalTempo() {
    const { newElementsDuration, prevElementsDuration, nextElementsDuration } = this
    return prevElementsDuration + newElementsDuration + nextElementsDuration
  }

  getRestsToFillEmptyBeats() {
    const totalTempo = this.calculateTotalTempo()

    /* if there's empty beats fill them with rests */
    if (this.hasAvailableTempo()) {
      const emptyBeats = MEASURE_DURATION - totalTempo
      return TempoManager.createRestsfromTempo(emptyBeats)
    }

    return []
  }

  rearrangeNextElements() {
    const { newElementsDuration, prevElementsDuration, nextElements } = this
    const rearrangedNextElements = nextElements.slice()
    let hasAvailableTempo = this.hasAvailableTempo()
    /*
      if elements following replaced element have
       mare tempo than available, remove them
    */
    while (!hasAvailableTempo && rearrangedNextElements.length) {
      rearrangedNextElements.splice(-1)
      hasAvailableTempo =
        prevElementsDuration + newElementsDuration + getElementsDuration(rearrangedNextElements)
    }

    this.nextElements = rearrangedNextElements
    return rearrangedNextElements
  }

  replace() {
    const elementReplacer = ElementReplacerOld.for({
      selectedElements: this.selectedElements,
      newElements: this.newElements,
    })

    return elementReplacer.replace(this)
  }
}
