import { ErrorOutline } from '@mui/icons-material'
import { TextField, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled as materialStyled } from '@mui/material/styles'
import styled from 'styled-components'

type StyledTextFieldProps = {
  error?: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const StyledTextField = materialStyled(TextField)<StyledTextFieldProps>(({ theme, error }) => ({
  '&.MuiFormControl-root': {
    width: '100%',
  },
  '&.MuiTextField-root': {
    background: theme.palette.grey[700],

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 2,
        ...(error && {
          borderColor: theme.palette.error.main,
        }),
      },
    },
  },
}))

export const Error = materialStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '0.5rem',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
}))

export const AlertIcon = materialStyled(ErrorOutline)({
  position: 'absolute',
  right: 14,
})
