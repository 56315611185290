import { HeadMeasure, Maestro, SoundManager, TailMeasure, TempoManager } from '..'
import { StructuralElementDecorator } from '../MusicalElement/MusicalElementsDecorator'

import {
  ElementInserter,
  LargerDurationElementReplacer,
  NoDurationElementReplacer,
  SameDurationElementReplacer,
  SmallerDurationElementReplacer,
} from '.'

export type ElementReplacerConstructor = { maestro: Maestro }

export abstract class ElementReplacer {
  maestro: Maestro
  _measures: (HeadMeasure | TailMeasure)[]

  constructor({ maestro }: ElementReplacerConstructor) {
    this.maestro = maestro
  }

  static for(maestro: Maestro) {
    const { newElements, selectedElements } = maestro
    const newElementsDuration = TempoManager.getElementsDuration(newElements)
    const selectedElementsDuration = TempoManager.getElementsDuration(selectedElements)

    if (newElements[0] instanceof StructuralElementDecorator) {
      return new ElementInserter({ maestro })
    }
    if (!newElementsDuration) {
      return new NoDurationElementReplacer({ maestro })
    }
    if (newElementsDuration < selectedElementsDuration) {
      return new SmallerDurationElementReplacer({ maestro })
    }
    if (newElementsDuration > selectedElementsDuration) {
      return new LargerDurationElementReplacer({ maestro })
    }

    return new SameDurationElementReplacer({ maestro })
  }

  static triggerError() {
    SoundManager.play('error')
    return null
  }

  protected get startChar() {
    return this.maestro.selectedElement.startChar
  }

  protected get endChar() {
    return this.maestro.selectedElement.endChar
  }

  get measures() {
    return this._measures
  }
}
