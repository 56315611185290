import { AbcNotation } from 'components/Composer/models/types'
import { asArray } from 'utils'

import { Bar, MusicalElement, Note, Rest } from '..'

import { BarDecorator } from './BarDecorator'
import { MusicalElementDecorator } from './MusicalElementDecorator'

export class GeneralMusicalElementDecorator extends MusicalElementDecorator {
  get abcNotation(): AbcNotation {
    return this.musicalElement.abcNotation
  }

  static for(...args: ConstructorParameters<typeof MusicalElement>) {
    const [settings, maestro] = args
    let element
    switch (settings.type) {
      case 'rest':
        element = new Rest(...args)
        break
      case 'bar': {
        const { selectedElements } = maestro
        if (selectedElements.length > 1) return []
        // @ts-ignore: fix bar element type
        const isBarSelected = selectedElements[0].el_type === 'bar'
        const isBarReplacement = isBarSelected && selectedElements.length === 1
        if (isBarReplacement) {
          element = new Bar(...args)
          break
        }
        return BarDecorator.decorateMusicalElements(BarDecorator, ...args)
      }
      case 'note':
      default:
        element = new Note(...args)
        break
    }

    return asArray(new GeneralMusicalElementDecorator({ settings: element.settings, maestro, element }))
  }
}
