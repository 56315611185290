import { useCallback, useEffect, useRef } from 'react'

import { ReactComponent as FingerboardSvg } from 'assets/images/fingerboard.svg'

import { useScorePlayerContext } from '../../providers'

import { fingerboardSettings } from './fingerboardSettings'
import {
  Container,
  FingerboardLineSvg,
  Note,
  NoteDuration,
  NoteName,
  // FingerNumber
} from './styles'

export const Fingerboard = () => {
  const noteRefs = useRef<HTMLElement[]>([])
  const containerRef = useRef<HTMLElement>(null)

  const { playerNotes, playerControls, abcTune } = useScorePlayerContext()
  const { isPlaying, isFingerboardPlaying, activePanels } = playerControls
  const { bpm, originalBpm } = abcTune

  const isVisible = activePanels.includes('fingerboard')

  const { getNoteAnimationDuration, getNoteAnimationDelay, getNoteHeight, getNoteXPos } = fingerboardSettings(
    {
      bpm,
      originalBpm,
    },
  )

  const SlidingNotes = useCallback(() => {
    const animationDuration = getNoteAnimationDuration()

    return playerNotes
      .map((note, index) => {
        if (note.name === 'rest') return

        return (
          <Note
            key={`${note.string}-${note.time}`}
            $string={note.string!}
            $animationDelay={getNoteAnimationDelay(note)}
            $animationDuration={animationDuration}
            $left={getNoteXPos(note)}
            ref={noteRef => {
              noteRefs.current[index] = noteRef!
            }}
          >
            <NoteName>{note.pitch}</NoteName>
            {/* <FingerNumber>{note.finger}</FingerNumber> */}

            <NoteDuration $height={getNoteHeight(note)} />
          </Note>
        )
      })
      .filter(Boolean)
  }, [playerNotes, containerRef.current])

  const handlePlay = () => {
    noteRefs.current.forEach(note => {
      note.style.animationPlayState = 'running'
    })
  }

  const handleStop = () => {
    if (!isVisible) return
    noteRefs.current.forEach(note => {
      const { animationName } = window.getComputedStyle(note)

      note.style.animationPlayState = 'paused'
      /** Animation reset */
      note.style.animationName = 'none'
      note.offsetHeight
      note.style.animationName = animationName
    })
  }

  useEffect(() => {
    if (!isFingerboardPlaying || !isVisible) return

    handlePlay()
  }, [isFingerboardPlaying])

  useEffect(() => {
    if (isPlaying) return

    handleStop()
  }, [isPlaying])

  if (!isVisible) return

  return (
    <Container ref={containerRef}>
      <FingerboardSvg />

      <SlidingNotes />

      <FingerboardLineSvg />
    </Container>
  )
}
