import { transparentize } from 'polished'

import { BREAKPOINTS } from './breakpoints'
import { APP_COLORS } from './colors'

export const COMMON_THEME = {
  ...BREAKPOINTS,
  palette: {
    divider: APP_COLORS.primary,
    primary: {
      main: APP_COLORS.primary,
      contrastText: APP_COLORS.offWhite,
      300: '#f8bdd5',
    },
    secondary: {
      main: '#f8bdd5',
    },
    info: { main: '#3172b7', contrastText: '#ebf8ff' },
    success: { main: '#2e656a', contrastText: '#e6fff1' },
    error: { main: '#c53030', contrastText: '#fddede' },
    transparent: {
      default: 'rgba(0, 0, 0, 0)',
      A300: 'rgba(0, 0, 0, 0.7)',
      A800: 'rgba(0, 0, 0, 0.2)',
      primary: {
        A50: transparentize(0.95, APP_COLORS.primary),
        A100: transparentize(0.9, APP_COLORS.primary),
        A500: transparentize(0.5, APP_COLORS.primary),
      },
    },
    grey: {
      200: APP_COLORS.grey[200],
      400: APP_COLORS.grey[400],
      500: APP_COLORS.grey[500],
      700: APP_COLORS.grey[700],
      800: APP_COLORS.grey[800],
    },
    colors: {
      white: APP_COLORS.offWhite,
      black: APP_COLORS.offBlack,
      pink: '#f50057',
    },
    levels: {
      easy: APP_COLORS.green,
      medium: APP_COLORS.yellow,
      hard: APP_COLORS.red,
    },
    components: {
      PaTuner: {
        high: '#ff033e',
        mediumHigh: '#FF5803',
        onPitch: '#81b622',
        mediumLow: '#9322B5',
        low: '#0a4def',
      },
      PaAlert: {
        success: {
          background: 'rgba(165, 220, 134, 1)',
          shadow: 'rgba(165, 220, 134, 0.2)',
        },
        error: {
          background: 'rgba(242, 116, 116, 1)',
          shadow: 'rgba(200, 0, 0, .2)',
        },
        warning: {
          background: 'rgba(248, 187, 134, 1)',
        },
      },
    },
  },
} as const

export const LIGHT_APP_THEME = {
  breakpoints: COMMON_THEME.breakpoints,
  palette: {
    ...COMMON_THEME.palette,
    mode: 'light',
    text: {
      primary: APP_COLORS.offBlack,
      inverted: APP_COLORS.offWhite,
    },
    background: {
      default: APP_COLORS.offWhite,
      paper: APP_COLORS.grey[200],
      inverted: APP_COLORS.offBlack,
    },
    shadows: {
      A900: '1px 2px 35px rgba(0, 0, 0, 0.2)',
      A700: '1px 2px 20px rgba(0, 0, 0, 0.2)',
    },
    components: {
      ...COMMON_THEME.palette.components,
      PaInput: {
        main: APP_COLORS.grey[200],
      },
      PaIcon: {
        hover: {
          main: APP_COLORS.offBlack,
        },
      },
      PaTopbar: {
        main: APP_COLORS.primary,
      },
    },
  },
} as const

export const DARK_APP_THEME = {
  breakpoints: COMMON_THEME.breakpoints,
  palette: {
    ...COMMON_THEME.palette,
    mode: 'dark',
    text: {
      primary: APP_COLORS.offWhite,
      inverted: APP_COLORS.offBlack,
    },
    background: {
      default: APP_COLORS.offBlack,
      paper: APP_COLORS.grey[800],
      inverted: APP_COLORS.offWhite,
    },
    shadows: {
      A900: '1px 2px 35px rgba(0, 0, 0, 0.7)',
      A700: '1px 2px 20px rgba(0, 0, 0, 0.7)',
    },
    components: {
      ...COMMON_THEME.palette.components,
      PaInput: {
        main: APP_COLORS.grey[700],
      },
      PaIcon: {
        hover: {
          main: APP_COLORS.primary,
        },
      },
      PaTopbar: {
        main: APP_COLORS.offBlack,
      },
    },
  },
} as const

export type AppTheme = typeof LIGHT_APP_THEME | typeof DARK_APP_THEME
