import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const SheetMusicContainer = styled.section`
  width: 1024px;
  padding: 0 50px 50px;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.palette.shadows.A900};
  position: relative;
`
export const SheetMusic = styled.div`
  g:focus {
    outline: none;
  }

  .abcjs-title,
  .abcjs-composer {
    visibility: hidden;
  }
`
export const TextareaContainer = styled.div`
  width: 30%;
  height: 250px;
  position: fixed;
  right: 24px;
  bottom: 24px;
  border-radius: 12px;
  z-index: 1000;
  overflow: auto;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.palette.shadows.A900};
  border: 1px solid ${({ theme }) => lighten(0.1, theme.palette.background.paper)};
`
export const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 30px 8px 30px 30px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: 'Source Code Pro', monospace;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.7px;
  border: none;
  resize: none;
  background: inherit;
  cursor: text;

  &:focus {
    border: none;
    outline: none;
  }
`
const inputStyles = css`
  height: 40px;
  position: absolute;
  background: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  z-index: 100;
  font-family: 'Times New Roman';
  text-align: center;
  outline: none;
`
export const TitleInput = styled.input`
  width: 100%;
  top: 50px;
  font-size: 32px;
  ${inputStyles}
`
export const ComposerInput = styled.input`
  top: 100px;
  right: 60px;
  font-size: 20px;
  ${inputStyles}
`
export const ComposerAudio = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-top: 60px;
`
