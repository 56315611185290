import {EMPTY_MEASURE, EMPTY_STAFF} from './initialSettings'

export const PITCHES = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
] as const

export type PitchControl = (typeof PITCHES)[number]

export const DURATIONS = [
  { label: 'whole-note',         duration: 1,         value: '8'  },
  { label: 'half-note',          duration: 0.5,       value: '4'  },
  { label: 'quarter-note',       duration: 0.25,      value: '2'  },
  { label: 'eighth-note',        duration: 0.125,     value: '1'  },
  { label: 'sixteenth-note',     duration: 0.0625,    value: '/2' },
  { label: 'thirty-second-note', duration: 0.03125,   value: '/4' },
  { label: 'sixty-fourth-note',  duration: 0.015625,  value: '/8' },
] as const

export type DurationControl = (typeof DURATIONS)[number]

export const DURATION_MODIFIERS = [
  { label: 'dot',                duration: 1.5,       value: '*'  },
  { label: 'double-dot',         duration: 1.75,      value: '*'  },
] as const

export type DurationModifierControl = (typeof DURATION_MODIFIERS)[number]

export const RESTS = [
  { label: 'whole-rest',         duration: 1,        value: 'z' },
  { label: 'half-rest',          duration: 0.5,      value: 'z' },
  { label: 'quarter-rest',       duration: 0.25,     value: 'z' },
  { label: 'eighth-rest',        duration: 0.125,    value: 'z' },
  { label: 'sixteenth-rest',     duration: 0.0625,   value: 'z' },
  { label: 'thirty-second-rest', duration: 0.03125,  value: 'z' },
  { label: 'sixty-fourth-rest',  duration: 0.015625, value: 'z' },
  // { label: 'one-hundred-twenty-eighth-rest', value: 'z', duration: 0.0078125, hidden: true }
] as const

export type RestControl = (typeof RESTS)[number]

export const ARTICULATIONS = [
  { label: 'slur',      value: '-' },
  { label: 'up-bow',    value: 'u' },
  { label: 'down-bow',  value: 'v' },
] as const

export type ArticulationControl = (typeof ARTICULATIONS)[number]

export const ACCIDENTALS = [
  { label: 'sharp', value: '^' },
  { label: 'double-sharp', value: '^^' },
  { label: 'flat', value: '_' },
  { label: 'double-flat', value: '__' },
  { label: 'natural', value: '=' },
] as const

export type AccidentalsControl = (typeof ACCIDENTALS)[number]

export const OCTAVES = [
  { label: 'C3', value: '3' },
  { label: 'C4', value: '4' },
  { label: 'C5', value: '5' },
  { label: 'C6', value: '6' },
]

export type OctavesControl = (typeof OCTAVES)[number]

export const KEY_SIGNATURES = [
  { label: 'C-Am',       value: 'C' },

  { label: 'G-Em',       value: 'G' },
  { label: 'D-Bm',       value: 'D' },
  { label: 'A-F♯m',      value: 'A' },
  { label: 'E-C♯m',      value: 'E' },
  { label: 'B-G♯m',      value: 'B' },
  { label: 'F♯-D♯m',     value: 'F#' },
  { label: 'C♯-A♯m',     value: 'C#' },

  { label: 'F-Dm',       value: 'F' },
  { label: 'Bb-Gm',      value: 'Bb' },
  { label: 'Eb-Cm',      value: 'Eb' },
  { label: 'Ab-Fm',      value: 'Ab' },
  { label: 'Db-Bbm',     value: 'Db' },
  { label: 'Gb-Ebm',     value: 'Gb' },
  { label: 'Cb-Abm',     value: 'Cb' },
] as const

export const TIME_SIGNATURES = [
  { label: '2/4',          value: '2/4' },
  { label: '3/4',          value: '3/4' },
  { label: '4/4',          value: '4/4' },
  { label: '3/8',          value: '3/8' },
  { label: '6/8',          value: '6/8' },
  { label: 'common-time',  value: 'C'   },
  { label: 'cut-time',     value: 'C|'  },
] as const

export type TimeSignaturesControl = (typeof TIME_SIGNATURES)[number]

export const BARS = [
  { label: '𝄀', value: '|' },
  { label: '𝄁', value: '||' },
  { label: '𝄆', value: '|:' },
  { label: '𝄇', value: ':|' },
  { label: '𝄇𝄆', value: '::' },
  { label: '𝄂', value: '|]' },
  { label: '𝄃', value: '[|' },
]

export type BarsControl = (typeof BARS)[number]

export const MISCELLANEOUS = [
  { label: 'measure', value: EMPTY_MEASURE },
  { label: 'staff', value: EMPTY_STAFF },
]
