import { useEffect } from 'react'

import { Loader } from 'components/ui'
import {
  FavoriteScoreProvider,
  ScoreProvider,
  useCurrentUserContext,
  useFavoriteScoreContext,
  useScoreContext,
} from 'providers'

import { Fingerboard, PlayerControls, SheetMusic, Violin } from './components'
import { ScorePlayerProvider, useScorePlayerContext } from './providers'
import { Container } from './styles'

const ScorePlayer = () => {
  const { fetchScore, fetchScoreLoading } = useScoreContext()
  const { audioRef, sheetMusicRef } = useScorePlayerContext()
  const { fetchFavoriteScore } = useFavoriteScoreContext()
  const { currentUser } = useCurrentUserContext()

  useEffect(() => {
    fetchScore()
    currentUser.id && fetchFavoriteScore()
  }, [])

  return (
    <Container>
      <PlayerControls />

      <Violin />
      <Fingerboard />

      <SheetMusic ref={sheetMusicRef} audioRef={audioRef} />

      {fetchScoreLoading && <Loader fullScreen />}
    </Container>
  )
}

export const ScorePlayerPage = () => (
  <ScoreProvider>
    <FavoriteScoreProvider>
      <ScorePlayerProvider>
        <ScorePlayer />
      </ScorePlayerProvider>
    </FavoriteScoreProvider>
  </ScoreProvider>
)
