import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

type ContainerProps = {
  $visible: boolean
}

export const Container = styled(Box)<ContainerProps>`
  min-width: 50%;
  height: 100vh;
  padding: 10px 0;
  display: flex;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  ${({ $visible }) =>
    !$visible &&
    css`
      width: 0;
      height: 0;
      min-width: 0;
      position: absolute;
    `}
`
export const EditorContainer = styled.section`
  flex: 1;
  padding: 0 12px;
  position: relative;
  overflow-y: scroll;
`
export const AbcDisplay = styled.div`
  .abcjs-highlight path {
    fill: #0a9ecc;
  }
`

export const Audio = styled.div`
  height: 0;
  opacity: 0;
  visibility: hidden;
  left: -9999999999px;
  top: -999999999px;
  position: absolute;
`
