import { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useCurrentUserContext } from 'providers'
import { deleteCustomScore, updateCustomScore, uploadMidi } from 'store/customScores/actions'
import { updateFavoriteScore } from 'store/favoriteScores/actions'
import {
  deleteFolder,
  fetchFolder,
  createFolder as reduxCreateFolder,
  setSelectedFolder,
  updateFolder,
} from 'store/folders/actions'
import { parameterize } from 'utils'

const useFileManager = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const folders = useSelector(state => state.folders)
  const {
    currentUser: { folders: rootFolders },
  } = useCurrentUserContext()

  const rootId = rootFolders[folders.type]
  const { root: rootFolder, selectedFolder } = folders

  const [isSelectMode, setIsSelectMode] = useState(false)
  const [selectedItems, setSelectedItems] = useState(initialSelected)
  const [expandedIds, setExpandedIds] = useState([])
  const [contextMenu, setContextMenu] = useState({})

  const findFolder = useCallback(id => folders.data.find(folder => folder.id === id), [folders])

  const findScore = useCallback(
    id => {
      const key = folders.type === 'customScores' ? 'id' : 'scoreId'
      return selectedFolder[folders.type].find(sheet => sheet[key] === id)
    },
    [folders.type, selectedFolder],
  )

  useEffect(() => {
    if (!folders.type) return

    dispatch(fetchFolder(rootId))

    return () => setExpandedIds([])
  }, [rootId, folders.type])

  useEffect(() => {
    if (!rootFolder) return

    !selectedFolder.id && dispatch(setSelectedFolder(rootFolder.id))
    !expandedIds.length && setExpandedIds([rootFolder.id])
  }, [rootFolder, selectedFolder])

  const toggleSelectMode = useCallback(() => {
    isSelectMode && setSelectedItems(initialSelected)
    setIsSelectMode(!isSelectMode)
  }, [isSelectMode])

  const selectItems = useCallback(
    (id, type) => {
      if (!isSelectMode) return

      const isSelected = selectedItems[type].includes(id)
      const selected = isSelected
        ? selectedItems[type].filter(itemId => itemId !== id)
        : [...selectedItems[type], id]

      setSelectedItems({ ...selectedItems, [type]: selected })
    },
    [isSelectMode, selectedItems],
  )

  const deleteItems = useCallback(async () => {
    const { folders: folderIds, scores } = selectedItems

    folderIds.length && (await dispatch(deleteFolder(folderIds, selectedFolder.id)))
    scores.length && (await dispatch(deleteCustomScore(scores)))

    await dispatch(fetchFolder(selectedFolder.id))
    dispatch(setSelectedFolder(selectedFolder.id))

    setSelectedItems(initialSelected)
  }, [selectedItems, selectedFolder, dispatch])

  const goToShow = useCallback(
    id => {
      const score = findScore(id)
      const title = parameterize(score.title)

      navigate(`/sheet-music/${title}/${id}`, { state: { custom: folders.type === 'customScores' } })
    },
    [navigate, findScore, folders.type],
  )

  const isFolderLoaded = useCallback(
    folderId => {
      const folder = findFolder(folderId)
      return folder.subfolders.some(subfolder => subfolder.name)
    },
    [findFolder],
  )

  const selectTreeNode = useCallback(
    async (e, selectedId) => {
      e.persist()

      const isExpand = e.target.closest('.MuiTreeItem-iconContainer')
      const isFile = e.target.closest('li').dataset.type === 'file'
      const isFavorite = folders.type === 'favoriteScores'

      if (isExpand) return
      if (isFile) {
        const scores = selectedFolder[folders.type]
        const id = isFavorite ? findFavoriteScoreId(scores, selectedId) : selectedId
        return goToShow(id)
      }

      if (!isFolderLoaded(selectedId)) await dispatch(fetchFolder(selectedId, { loadingSelect: true }))
      dispatch(setSelectedFolder(selectedId))

      !expandedIds.includes(selectedId) && setExpandedIds([...expandedIds, selectedId])
    },
    [goToShow, dispatch, isFolderLoaded, expandedIds, selectedFolder, folders.type],
  )

  const toggleTreeNode = useCallback(
    (e, folderIds) => {
      const isClose = expandedIds.length - folderIds.length === 1
      const isSelect = e.target.closest('.MuiTreeItem-label')
      const folderId = e.target.closest('.MuiTreeItem-root').id

      if (isSelect) return
      setExpandedIds(folderIds)

      !isClose && !isFolderLoaded(folderId) && dispatch(fetchFolder(folderId))
    },
    [expandedIds, dispatch, isFolderLoaded],
  )

  const createFolder = useCallback(async () => {
    await dispatch(reduxCreateFolder(selectedFolder.id))
    await dispatch(fetchFolder(selectedFolder.id))
    dispatch(setSelectedFolder(selectedFolder.id))
  }, [dispatch, selectedFolder])

  const updateItem = useCallback(
    async (id, params, options = {}) => {
      const { type, parentId } = options
      const isFile = type === 'file'
      const action = isFile ? updateCustomScore : updateFolder

      await dispatch(action(id, params, { loadingId: id }))
      dispatch(fetchFolder(parentId || id))
    },
    [dispatch],
  )

  const selectFolder = useCallback(
    async id => {
      if (!isFolderLoaded(id)) await dispatch(fetchFolder(id))
      dispatch(setSelectedFolder(id))
    },
    [isFolderLoaded, dispatch],
  )

  const validateDrop = useCallback(
    (dragged, droppableId) => {
      const isFile = dragged.type === 'file'
      if (dragged.id === droppableId) return false

      if (isFile) {
        const hasScore = findFolder(droppableId)?.[folders.type].find(score => score.id === dragged.id)

        if (hasScore) return false
      } else {
        const parentId = findFolder(dragged.id)?.parentId
        if (parentId === droppableId) return false
      }

      return true
    },
    [findFolder, folders.type],
  )

  const dropItem = useCallback(
    async e => {
      e.preventDefault()
      e.stopPropagation()

      const dragged = JSON.parse(e.dataTransfer.getData('text/plain'))
      const droppableId = e.currentTarget.id

      if (!validateDrop(dragged, droppableId)) return

      const isFile = dragged.type === 'file'
      const isFavorite = folders.type === 'favoriteScores'
      const fileAction = isFavorite ? updateFavoriteScore : updateCustomScore
      const action = isFile ? fileAction : updateFolder
      const key = isFile ? 'folder_id' : 'parent_id'

      const scores = selectedFolder[folders.type]
      const itemId = isFavorite ? findFavoriteScoreId(scores, dragged.id) : dragged.id

      await dispatch(action(itemId, { [key]: droppableId }, { loadingId: droppableId }))

      const parentId = dragged.parentId || selectedFolder.id
      dispatch(fetchFolder(parentId))
      await dispatch(fetchFolder(droppableId))
      dispatch(setSelectedFolder(droppableId))

      !expandedIds.includes(droppableId) && setExpandedIds([...expandedIds, droppableId])
    },
    [expandedIds, selectedFolder, dispatch, validateDrop, folders.type],
  )

  const handleContextMenu = useCallback(
    (e, id) => {
      e.preventDefault()
      const { title, readOnly } = findScore(id) || {}
      setContextMenu({ x: e.clientX - 2, y: e.clientY - 4, id, title: parameterize(title), readOnly })
    },
    [findScore],
  )

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu({})
  }, [])

  const handleMidiUpload = useCallback(
    async e => {
      await dispatch(uploadMidi(e.target.files[0]))
      await dispatch(fetchFolder(selectedFolder.id))
      dispatch(setSelectedFolder(selectedFolder.id))
    },
    [dispatch, selectedFolder.id],
  )

  return {
    isSelectMode,
    selectedItems,
    toggleSelectMode,
    selectTreeNode,
    toggleTreeNode,
    selectItems,
    deleteItems,
    createFolder,
    expandedIds,
    goToShow,
    dropItem,
    updateItem,
    selectFolder,
    handleContextMenu,
    handleCloseContextMenu,
    contextMenu,
    handleMidiUpload,
  }
}

export default useFileManager

const initialSelected = { folders: [], scores: [] }

const findFavoriteScoreId = (scores, id) => {
  return scores.find(score => score.id === id)?.scoreId
}
