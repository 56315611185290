import { useApi } from 'hooks'
import { Score } from 'models'
import { ScoreEndpoint } from 'routes/locations'

export const useScoreController = (scoreId: Id) => {
  const [fetchScore, score, { loading: fetchScoreLoading }] = useApi<Score>(ScoreEndpoint, 'GET', {
    scoreId,
  })

  return {
    fetchScore,
    score,
    fetchScoreLoading,
  }
}
