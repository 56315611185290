import { Box } from '@mui/material'
import { lighten, transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { REQUEST_ANIMATION_DELAY } from './useTuner'

type ContainerProps = {
  $angle: number
  $active: boolean
  $hidePointer: boolean
}

export const Container = styled(Box)<ContainerProps>`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24%;
  border-radius: 20px;
  user-select: none;
  transition: background 0.7s;

  ${({ $hidePointer }) =>
    !$hidePointer &&
    css`
      width: 100%;
      padding: 16px 0;
    `}

  ${({ $angle, $active }) =>
    $active &&
    css`
      background: ${({ theme: { palette } }) =>
        transparentize(0.9, palette.components.PaTuner[getTunerAccuracy($angle)])};
    `};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 60px 0;
    border-radius: 0;
  }
`
export const DisplayContainer = styled.div<Omit<ContainerProps, '$angle'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  gap: 12px;
  padding: 12px 28px;
  color: ${({ theme }) => theme.palette.grey[400]};

  ${({ $hidePointer }) =>
    !$hidePointer &&
    css`
      padding: 0;
    `}
`
export const NoteDisplay = styled.p<Omit<ContainerProps, '$hidePointer'>>`
  font-size: 1em;
  transition: color 0.7s;

  ${({ $angle, $active }) =>
    $active &&
    css`
      color: ${({ theme: { palette } }) =>
        lighten(0.3, palette.components.PaTuner[getTunerAccuracy($angle)])};
    `}
`
export const FrequencyDisplay = styled.span<Pick<ContainerProps, '$active'>>`
  font-size: 0.3333em;
  transition: color 0.7s;

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.palette.text.primary};
    `}
`
const pointerButtonSize = 30

export const Pointer = styled.div<Pick<ContainerProps, '$active' | '$angle'>>`
  flex: 4 0;
  width: 2px;
  margin-bottom: ${pointerButtonSize}px;
  transform: rotate(0);
  transform-origin: bottom;
  transition:
    transform ${REQUEST_ANIMATION_DELAY}ms,
    background 0.7s;
  background: ${({ theme }) => theme.palette.grey[400]};

  ${({ $angle }) =>
    $angle !== undefined &&
    css`
      transform: rotate(${$angle}deg);
    `}

  ${({ $angle, $active }) =>
    $active &&
    css`
      background: ${({ theme: { palette } }) => palette.components.PaTuner[getTunerAccuracy($angle)]};
    `}

  &::before,
  &::after {
    content: '';
    width: ${pointerButtonSize}px;
    height: ${pointerButtonSize}px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: -${pointerButtonSize / 2}px;
    transform: translateX(-50%);
    border: 2px solid ${({ theme }) => theme.palette.grey[400]};
    background: ${({ theme }) => theme.palette.background.default};
  }

  &::after {
    content: '';
    width: 5px;
    height: 5px;
    bottom: -2.5px;
    border: 2px solid transparent;
    background: inherit;
  }
`

const getTunerAccuracy = (angle: number) => {
  if (angle < 0 && angle > -45) return 'mediumLow'
  if (angle < -45) return 'low'

  if (angle > 0 && angle < 45) return 'mediumHigh'
  if (angle > 45) return 'high'
  return 'onPitch'
}
