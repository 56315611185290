import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const InputGroup = styled.div`
  display: flex;

  > div {
    margin: 0;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }

    input {
      width: 50%;
    }
  }
`
