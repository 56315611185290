import { ACCIDENTAL_REGEX } from 'components/Composer/constants'
import { AbcNotation } from 'components/Composer/models/types'

import { MusicalElementSettingDecorator } from '.'

export class AccidentalDecorator extends MusicalElementSettingDecorator {
  musicalElementAbc(): AbcNotation {
    return this.musicalElement.abcNotation.replace(ACCIDENTAL_REGEX, '') as AbcNotation
  }
}
