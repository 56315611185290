import { ReactNode, useCallback, useEffect, useState } from 'react'

import {
  ArrowBack as ArrowBackIcon,
  ClosedCaptionOutlined as ClosedCaptionIcon,
  FavoriteBorder as EmptyHeartIcon,
  Favorite as HeartIcon,
  MenuOutlined as MenuOutlinedIcon,
  PlayArrow as PlayIcon,
  Stop as StopIcon,
  SwapVertOutlined as TransposeIcon,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { createPortal } from 'react-dom'
import { GiMetronome as GiMetronomeIcon } from 'react-icons/gi'
import { useLocation, useNavigate } from 'react-router'

import { Range } from 'components/ui'
import { useCurrentUserContext, useFavoriteScoreContext } from 'providers'

import { Countdown, PlayerDrawer } from '..'
import { useScorePlayerContext } from '../../providers'

import { AudioControls, Container, IconButton, Section } from './styles'

export const PlayerControls = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isControlVisible, setIsControlVisible] = useState(true)

  const { playerControls, abcTune } = useScorePlayerContext()
  const { originalBpm, transposition } = abcTune
  const {
    handlePlay,
    handleStop,
    handleCaption,
    handleTranspose,
    handleFavorite,
    handleChangeBpm,
    isCountdownActive,
    countdown,
  } = playerControls

  const { favoriteScore } = useFavoriteScoreContext()
  const { currentUser } = useCurrentUserContext()

  const handleShowControls = useCallback(() => {
    setIsControlVisible(true)
    setTimeout(() => setIsControlVisible(false), 3000)
  }, [])

  useEffect(() => {
    handleShowControls()
    document.addEventListener('click', handleShowControls)

    return () => {
      document.removeEventListener('click', handleShowControls)
    }
  }, [handleShowControls])

  const handleToggleDrawer = () => {
    setIsDrawerOpen(open => !open)
  }

  const goBack = () => {
    const hasHistoryEntry = location.key !== 'default'
    hasHistoryEntry ? navigate(-1) : navigate('/')
  }

  const FavoriteIcon = favoriteScore ? HeartIcon : EmptyHeartIcon

  return (
    <Container $visible={isControlVisible}>
      <AudioControls>
        <Tooltip title="Play" placement="right">
          <IconButton size="medium" onClick={handlePlay}>
            <PlayIcon fontSize="large" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Stop" placement="right">
          <IconButton size="medium" onClick={handleStop}>
            <StopIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </AudioControls>

      <Section>
        <Range
          label="bpm"
          initialValue={originalBpm}
          onChange={handleChangeBpm}
          Icon={GiMetronomeIcon}
          disabled={false}
          tooltipProps={{ title: 'bpm', placement: 'top' }}
          step={10}
          min={40}
          max={originalBpm + 80}
        />

        <Range
          label="Transpose"
          initialValue={transposition}
          onChange={handleTranspose}
          Icon={TransposeIcon}
          disabled={false}
          tooltipProps={{ title: 'Transpose', placement: 'top' }}
        />

        <Tooltip title="Toggle Notes" placement="right">
          <IconButton size="medium" onClick={handleCaption}>
            <ClosedCaptionIcon fontSize="large" />
          </IconButton>
        </Tooltip>

        {!!currentUser.id && (
          <Tooltip title="Favorite" placement="right">
            <IconButton size="medium" onClick={handleFavorite}>
              <FavoriteIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Menu" placement="right">
          <IconButton size="medium" onClick={handleToggleDrawer}>
            <MenuOutlinedIcon />
          </IconButton>
        </Tooltip>

        <IconButton size="medium" onClick={goBack}>
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
      </Section>

      <PlayerDrawer open={isDrawerOpen} onClose={handleToggleDrawer} />

      {isCountdownActive &&
        (createPortal(<Countdown timeout={countdown * 1000} />, document.body) as ReactNode)}
    </Container>
  )
}
