import { Course } from 'models'
import { CourseCard } from 'pages/components'

import { List } from './styles'

type Props = {
  courses: Course[]
}

export const CourseList = ({ courses }: Props) => {
  if (!courses) return <>Loading...</>

  return (
    <List>
      {courses.map(course => (
        <CourseCard course={course} key={course.id} />
      ))}
    </List>
  )
}
