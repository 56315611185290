import { useApi } from 'hooks'
import { FavoriteScore } from 'models'
import { FavoriteScoresEndpoint } from 'routes/locations'

export const useFavoriteScoresController = () => {
  const [createFavoriteScore, favoriteScore] = useApi<FavoriteScore>(FavoriteScoresEndpoint, 'POST')

  return {
    createFavoriteScore,
    favoriteScore,
  }
}
