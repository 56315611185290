export const lectureCardSx = {
  '&.MuiCard-root': {
    boxShadow: 'none',
  },
  '.MuiCardContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 8px',
  },
}
export const deleteIconButtonSx = {
  '&.MuiIconButton-root': {
    top: -8,
    right: -4,
    position: 'absolute',
    zIndex: 10,
  },
}
export const attachmentContainerSx = {
  position: 'relative',
  width: 'calc(20% - 8px)',
  maxWidth: 120,
}
export const attachmentListSx = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
  position: 'relative',
  mt: 2,
}
