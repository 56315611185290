import { useEffect, useState } from 'react'

import {
  AccessTime as AccessTimeIcon,
  VisibilityOutlined as EyeIcon,
  Speed as SpeedIcon,
} from '@mui/icons-material'
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  ToggleButtonGroup,
} from '@mui/material'
import { BsFileEarmarkMusic } from 'react-icons/bs'
import { GiViolin } from 'react-icons/gi'
import { PiSlidersFill } from 'react-icons/pi'

import { Tuner } from 'components'
import { useBreakpoint } from 'hooks'

import { useScorePlayerContext } from '../../providers'

import { drawerPaperSx, listItemSx, listSx, switchSx, textFieldsSx, ToggleButton } from './styles'

type Props = {
  open: boolean
  onClose: () => void
}

export const PlayerDrawer = ({ open, onClose }: Props) => {
  const [isTunerActive, setIsTunerActive] = useState(false)
  const { upTo } = useBreakpoint()

  const { playerControls } = useScorePlayerContext()
  const { activePanels, handlePanelVisibility, handleCountdown, countdown } = playerControls

  const handleToggleTuner = () => {
    setIsTunerActive(tunerActive => !tunerActive)
  }

  useEffect(() => {
    return () => setIsTunerActive(false)
  }, [open])

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: drawerPaperSx }}>
      <List sx={listSx}>
        <ListItem key="Tuner" sx={listItemSx} disablePadding>
          <Box display="flex" alignItems="center">
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <SpeedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Tuner" primaryTypographyProps={{ variant: 'subtitle2' }} />
          </Box>

          <Switch size="small" value={isTunerActive} onChange={handleToggleTuner} sx={switchSx} />
          <Tuner active={isTunerActive} sx={{ alignSelf: 'center' }} hidePointer />
        </ListItem>
        <ListItem key="Panels Visibility" disablePadding sx={listItemSx}>
          <Box display="flex" alignItems="center">
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <EyeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Panels Visibility" primaryTypographyProps={{ variant: 'subtitle2' }} />
          </Box>

          <ToggleButtonGroup
            value={activePanels}
            onChange={handlePanelVisibility}
            sx={{ justifyContent: 'center', gap: '16px' }}
            exclusive={upTo('md')}
          >
            <ToggleButton value="violin">
              <GiViolin size={22} style={{ transform: 'rotate(-45deg)' }} />
            </ToggleButton>
            <ToggleButton value="fingerboard">
              <PiSlidersFill size={22} />
            </ToggleButton>
            <ToggleButton value="sheet-music">
              <BsFileEarmarkMusic size={22} />
            </ToggleButton>
          </ToggleButtonGroup>
        </ListItem>

        <ListItem key="Countdown" sx={listItemSx} disablePadding>
          <Box display="flex" alignItems="center">
            <ListItemIcon sx={{ minWidth: '36px' }}>
              <AccessTimeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Countdown" primaryTypographyProps={{ variant: 'subtitle2' }} />
          </Box>

          <TextField type="number" value={countdown} onChange={handleCountdown} sx={textFieldsSx} />
        </ListItem>
      </List>
    </Drawer>
  )
}
