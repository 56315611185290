export const toDecimalRound = (number = 0, decimalPlaces = 2) => {
  const factor = 10 ** decimalPlaces
  return Math.round(number * factor) / factor
}

export const modulo = (n, m) => {
  return ((n % m) + m) % m
}

export const clamp = (number, min, max) => {
  return Math.max(min, Math.min(number, max))
}
