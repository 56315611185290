import { INITIAL_COMPOSER_META, INITIAL_COMPOSER_SETTINGS } from 'components/Composer/constants'

const composerState = {
  composerV2: {
    id: null,
    settings: INITIAL_COMPOSER_SETTINGS,
    loading: false,
    abc: '',
    clipboard: '',
    meta: INITIAL_COMPOSER_META,
  },
}

export default composerState
