import { Navigate, Outlet } from 'react-router-dom'

import { useAuthToken, useUserRole } from 'hooks'
import { AppRole } from 'models'

type Props = {
  auth?: boolean
  isPublic?: boolean
  roles?: AppRole[]
}

export const RouteManager = ({ auth, isPublic, roles = [] }: Props) => {
  const { token } = useAuthToken()
  const { hasRoleAccess } = useUserRole()

  const hasTokenAccess = !!token === !auth
  const isRedirectFromAuthPage = !!token && auth

  if (isPublic) return <Outlet />
  if (hasTokenAccess && hasRoleAccess(roles)) return <Outlet />
  if (isRedirectFromAuthPage) return <Navigate to={{ pathname: '/' }} replace />

  return <Navigate to={{ pathname: '/sign-in' }} />
}
