export const BREAKPOINTS = {
  breakpoints: {
    values: {
      xs: 375,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },
}
