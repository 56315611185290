import { ReactNode, useMemo } from 'react'

import { createTheme, ThemeProvider as MaterialUiThemeProvider } from '@mui/material/styles'

import { useTheme } from 'hooks'

import { createCtx } from './utils'

type Props = {
  children: ReactNode
}

type ThemeContext = ReturnType<typeof useTheme>

export const [useThemeContext, ThemeContextProvider] = createCtx<ThemeContext>()

export const ThemeProvider = ({ children }: Props) => {
  const { theme, toggleTheme } = useTheme()

  const themeProviderValue = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme])
  const materialTheme = createTheme(theme)

  return (
    <ThemeContextProvider value={themeProviderValue}>
      <MaterialUiThemeProvider theme={materialTheme}>{children}</MaterialUiThemeProvider>
    </ThemeContextProvider>
  )
}
