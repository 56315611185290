import { TempoManager } from '..'
import { AbcElementDuration, AbcNotation } from '../types'

import { MusicalElement } from './MusicalElement'

export class Rest extends MusicalElement {
  abcDuration: AbcElementDuration

  constructor(...args: ConstructorParameters<typeof MusicalElement>) {
    super(...args)
    this.abcDuration = this.setAbcDuration()
  }

  get abcNotation(): AbcNotation {
    return `${this.settings.value}${this.abcDuration}` as AbcNotation
  }

  private setAbcDuration() {
    return TempoManager.durationNumberToAbcNotation(this.duration)
  }
}
