import Fraction from 'fraction.js'

export const stripInfoFromAbcString = (abc, title, composer) => {
  return abc.replace(`T: ${title}\nC: ${composer}`, `T:\nC:`)
}

export const sum = arr => {
  return arr.reduce((acc, curr) => acc + toDecimal(curr), 0)
}

export const subtract = arr => {
  return arr.reduce((acc, curr) => acc - toDecimal(curr))
}

export const filterRests = (arr, index) => {
  return arr.filter((item, i) => item.type === 'rest' && i !== index)
}

export const filterNonRests = (arr, index) => {
  return arr.filter((item, i) => item.type !== 'rest' || i === index)
}

export const toDecimal = n => {
  return parseFloat(new Fraction(n).toString())
}

export const getNoteIndex = (selectedAbc, notes) => {
  const { note: index } = selectedAbc.start

  if (index === null || index === undefined) return notes.length - 1
  return index
}

export const getSelectedElements = (selectedAbc, notes) => {
  if (!selectedAbc) return []

  const { start, end } = selectedAbc
  if (start.note === end.note) end.note += 1

  return notes.slice(start.note, end.note)
}
