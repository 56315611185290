import build from 'redux-object'

import initialState from 'store/folders/state'
import {
  CREATE_FOLDER_END,
  CREATE_FOLDER_ERROR,
  CREATE_FOLDER_START,
  CREATE_FOLDER_SUCCESS,
  DELETE_FOLDER_END,
  DELETE_FOLDER_ERROR,
  DELETE_FOLDER_START,
  DELETE_FOLDER_SUCCESS,
  FETCH_FOLDER_END,
  FETCH_FOLDER_ERROR,
  FETCH_FOLDER_START,
  FETCH_FOLDER_SUCCESS,
  RESET_FOLDERS,
  SET_FOLDER_TYPE,
  SET_SELECTED_FOLDER_END,
  SET_SELECTED_FOLDER_ERROR,
  SET_SELECTED_FOLDER_START,
  SET_SELECTED_FOLDER_SUCCESS,
  UPDATE_FOLDER_END,
  UPDATE_FOLDER_ERROR,
  UPDATE_FOLDER_START,
  UPDATE_FOLDER_SUCCESS,
} from 'store/folders/types'

const scoresReducer = (state = initialState.folders, action) => {
  switch (action.type) {
    case FETCH_FOLDER_START:
    case CREATE_FOLDER_START:
    case DELETE_FOLDER_START:
    case UPDATE_FOLDER_START:
    case SET_SELECTED_FOLDER_START:
      return {
        ...state,
        loadingId: action.payload.folderId,
        loadingAction: action.payload.action,
        loadingSelect: action.payload.loadingSelect,
      }

    case FETCH_FOLDER_SUCCESS: {
      const { normalized: normalizedData } = action.payload
      const folderType = getFolderType(state.type)
      const files = state.normalized[state.type]
      const folders = state.normalized[folderType]

      const normalized = {
        [folderType]: {
          ...folders,
          ...normalizedData[folderType],
        },
        [state.type]: { ...files, ...normalizedData[state.type] },
      }
      const data = build(normalized, folderType)
      const root = data.find(dir => !dir.parent_id)
      const selected = data.find(dir => dir.id === state.selectedFolder.id)

      return {
        ...state,
        data,
        root: { ...root, name: `Sheet Music` },
        normalized,
        selectedFolder: selected || {},
      }
    }
    case SET_SELECTED_FOLDER_SUCCESS:
      return {
        ...state,
        selectedFolder: action.payload.folder,
      }

    case CREATE_FOLDER_ERROR:
    case DELETE_FOLDER_ERROR:
    case UPDATE_FOLDER_ERROR:
    case FETCH_FOLDER_ERROR:
    case SET_SELECTED_FOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case FETCH_FOLDER_END:
    case CREATE_FOLDER_END:
    case DELETE_FOLDER_END:
    case UPDATE_FOLDER_END:
      return {
        ...state,
        error: null,
        loadingId: null,
        loadingAction: null,
      }

    case SET_SELECTED_FOLDER_END:
      return {
        ...state,
        error: null,
        loadingId: null,
        loadingAction: null,
        loadingSelect: false,
      }

    case CREATE_FOLDER_SUCCESS:
    case DELETE_FOLDER_SUCCESS:
    case UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
      }

    case SET_FOLDER_TYPE:
      return {
        ...state,
        type: action.payload,
      }

    case RESET_FOLDERS: {
      return {
        ...initialState.folders,
      }
    }

    default:
      return state
  }
}

export default scoresReducer

const getFolderType = type => `${type.slice(0, -1)}Folders`
