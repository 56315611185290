import { MutableRefObject } from 'react'

import abcjs, { AbsoluteElement, Editor, VoiceItem, VoiceItemNote } from 'abcjs'

import { asArray } from 'utils'

import { GeneralMusicalElementDecorator } from './MusicalElement/MusicalElementsDecorator'
import { TuneSettingsManager } from './TuneSettingsManager'
import {
  AbcComposition,
  ComposerPanelControlWithDuration,
  ComposerPanelType,
  ElementDuration,
  MusicalElementSettings,
  MusicalElementWithDuration,
  TextAreaSelection,
} from './types'

import { ElementReplacer, MusicalElement, TempoManager } from '.'

export type MaestroInstanceVariables = {
  newElements: InstanceType<typeof Maestro>['newElements']
  abcjsEditor: InstanceType<typeof Maestro>['abcjsEditor']
}

export class Maestro {
  abcjsEditor: MutableRefObject<Potential<Editor>>
  newElements: MusicalElementWithDuration[] | GeneralMusicalElementDecorator[]
  tuneSettings: TuneSettingsManager

  constructor() {
    this.abcjsEditor = { current: undefined }
    this.newElements = []
    this.tuneSettings = new TuneSettingsManager(this)
  }

  compose(elementsSettings: MusicalElementSettings | MusicalElementSettings[]) {
    const { selectedElement } = this
    if (!selectedElement || !elementsSettings) return

    this.newElements = asArray(elementsSettings)
      .map(setting => MusicalElement.for(setting, this))
      .flat()

    if (!this.newElements.length) return

    const elementReplacer = ElementReplacer.for(this)
    const composition = elementReplacer.buildFromDuration()

    return composition
  }

  write(composition: AbcComposition) {
    const [newElementsAbc, { startChar, endChar, lastElementEndChar }] = composition

    this.textarea.setRangeText(newElementsAbc, startChar, endChar, 'start')

    setTimeout(() => {
      const elementAfterComposition = this.elementToSelectAfterComposition(lastElementEndChar)
      this.selectElement(elementAfterComposition?.abselem)
    })
  }

  changeElementDuration(control: ComposerPanelControlWithDuration) {
    const { selectedElement } = this
    if (!selectedElement) return

    control.type = selectedElement.rest?.type || selectedElement.el_type
    const durationNumberWithDot = TempoManager.calculateDurationWithDot(control, selectedElement)
    const elementAbc = selectedElement.pitches?.[0].name || 'z'
    const newElement = {
      value: elementAbc,
      duration: durationNumberWithDot as ElementDuration,
      type: elementAbc === 'z' ? 'rest' : ('note' as ComposerPanelType),
    }

    return this.compose(newElement)
  }

  getElementFromChar(charPos: number): VoiceItemNote
  getElementFromChar(charPos: number): VoiceItem | null
  getElementFromChar(charPos: number) {
    return this.tune.getElementFromChar(charPos)
  }

  getTextareaSelection() {
    return this.editarea.getSelection()
  }

  setTextareaSelection(start: number, end: number) {
    this.editarea.setSelection(start, end)
    this.abcjsEditor.current!.updateSelection()
  }

  getFirstElementFromTextareaSelection() {
    const { start } = this.getTextareaSelection()
    return this.getElementFromChar(start)
  }

  getLastElementFromTextareaSelection() {
    const { end } = this.getTextareaSelection()
    return this.getElementFromChar(end - 1)
  }

  getLastElementIndexFromTextareaSelection() {
    const currentLastSelectedElement = this.getLastElementFromTextareaSelection()
    if (!currentLastSelectedElement) return

    return this.getElementIndex(currentLastSelectedElement)
  }

  getElementIndex(element: VoiceItemNote) {
    return this.selectableElements.findIndex(({ elem }) => {
      return elem.abcelem.startChar === element.startChar
    })
  }

  getElementsFromTextareaSelection(selection?: TextAreaSelection) {
    const { start: selectionStart, end: selectionEnd } = selection || this.getTextareaSelection()
    const elements: VoiceItemNote[] = []
    let isLastElement = elements.length && elements.at(-1)!.endChar === selectionEnd
    let currentSelectionStart = selectionStart

    do {
      const element = this.getElementFromChar(currentSelectionStart)
      if (!element) break
      elements.push(element)
      currentSelectionStart = element.endChar + 1
      isLastElement = elements.length && elements.at(-1)!.endChar === selectionEnd
    } while (!isLastElement)

    return elements
  }

  selectElement(element: AbsoluteElement = this.selectableElements[0].elem) {
    this.setTextareaSelection(element.abcelem.startChar, element.abcelem.endChar)
    this.abcjsEditor.current!.updateSelection()
  }

  selectElementsInRange(start: number, end: number) {
    this.setTextareaSelection(start, end)
    this.abcjsEditor.current!.updateSelection()
  }

  allElementsAfterSelection() {
    const { selectedElement } = this
    const selectedElementIndex = this.getElementIndex(selectedElement!)
    return this.selectableElements.slice(selectedElementIndex + 1)
  }

  get selectedElement() {
    return this.getFirstElementFromTextareaSelection()
  }

  get selectedElements() {
    return this.getElementsFromTextareaSelection()
  }

  get editarea() {
    return this.abcjsEditor.current!.editarea
  }

  get textarea() {
    return this.editarea.textarea
  }

  get tune() {
    return this.abcjsEditor.current!.tunes[0]
  }

  get currentAbc() {
    return this.abcjsEditor.current?.currentAbc
  }

  get allElements() {
    return this.tune.makeVoicesArray()[0]
  }

  get selectableElements() {
    return this.allElements.filter(element => element.elem.abcelem.el_type === 'note')
  }

  get measuresAbc() {
    const { currentAbc: tune } = this.abcjsEditor.current!
    return abcjs.extractMeasures(tune)[0].measures
  }

  get totalMeasureBeats() {
    const { tuneSettings } = this
    const timeSignature = tuneSettings.getTimeSignature()
    const { beatsPerMeasure, durationOfBeat } = tuneSettings.getTimeSignatureValues(timeSignature)
    return beatsPerMeasure / durationOfBeat
  }

  elementToSelectAfterComposition(char: number) {
    const element = this.getElementFromChar(char + 1)
    if (element) return element

    return this.getElementFromChar(char + 2)
  }
}
