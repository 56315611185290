import styled, { css } from 'styled-components'

import { buttonStyles } from 'styles/placeholders'

export const Container = styled.button`
  ${buttonStyles}
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.colors.white};
  position: relative;
  background: ${props => props.$color || props.theme.palette.primary.main};

  .dot-loader {
    top: 50%;
    left: 50%;
    margin-top: 2px;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  ${({ outline }) =>
    outline &&
    css`
      color: ${props => props.$color || props.theme.palette.primary.main};
      border: 1px solid ${props => props.$color || props.theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.colors.white};
    `}
`
