import FileManager from 'components/FileManager'
import { PageContainer } from 'components/ui'
import withFolderType from 'hocs/withFolderType'

const CustomScores = () => {
  return (
    <PageContainer title="My Sheet Music">
      <FileManager />
    </PageContainer>
  )
}

export default withFolderType(CustomScores, 'customScores')
