import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useTeacherCourseController } from 'controllers'
import { Course } from 'models'

import { TeacherCourseForm } from '..'
import { TeacherCourseFormValues, teacherCourseSchema } from '../teacherCourseSchema'
import { buildTeacherCourseParams } from '../utils'

type Props = {
  teacherCourse: Course
}

export const TeacherCourseEditForm = ({ teacherCourse }: Props) => {
  const { teacherCourseId } = useParams()
  const { updateTeacherCourse } = useTeacherCourseController(teacherCourseId!)

  const form = useForm<TeacherCourseFormValues>({
    resolver: yupResolver(teacherCourseSchema),
    defaultValues: teacherCourse as TeacherCourseFormValues,
  })

  const handleEditCourse = (data: TeacherCourseFormValues) => {
    const payload = buildTeacherCourseParams(data)
    updateTeacherCourse({ teacherCourse: payload })
  }

  return <TeacherCourseForm form={form} handleSaveCourse={handleEditCourse} />
}
