import { AbcRenderer, AudioPlayerState, ReadyState } from '.'

type Constructor = {
  renderer: AbcRenderer
}

export class AudioPlayer {
  state: AudioPlayerState
  renderer: AbcRenderer

  constructor({ renderer }: Constructor) {
    this.state = new ReadyState(this)
    this.renderer = renderer

    this.handlePlay = this.handlePlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
  }

  changeState(state: AudioPlayerState) {
    this.state = state
  }

  handlePlay() {
    this.state.handlePlay()
  }

  handleStop() {
    this.state.handleStop()
  }

  handleFinish() {
    this.state.handleFinish()
  }
}
