import {
  CREATE_CUSTOM_SCORE_END,
  CREATE_CUSTOM_SCORE_ERROR,
  CREATE_CUSTOM_SCORE_START,
  CREATE_CUSTOM_SCORE_SUCCESS,
  DELETE_CUSTOM_SCORE_END,
  DELETE_CUSTOM_SCORE_ERROR,
  DELETE_CUSTOM_SCORE_START,
  DELETE_CUSTOM_SCORE_SUCCESS,
  FETCH_CUSTOM_SCORE_END,
  FETCH_CUSTOM_SCORE_ERROR,
  FETCH_CUSTOM_SCORE_START,
  FETCH_CUSTOM_SCORE_SUCCESS,
  RESET_CUSTOM_SCORE,
  UPDATE_CUSTOM_SCORE_END,
  UPDATE_CUSTOM_SCORE_ERROR,
  UPDATE_CUSTOM_SCORE_START,
  UPDATE_CUSTOM_SCORE_SUCCESS,
  UPLOAD_MIDI_END,
  UPLOAD_MIDI_ERROR,
  UPLOAD_MIDI_START,
  UPLOAD_MIDI_SUCCESS,
} from './types'

export const fetchCustomScoreStart = () => ({
  type: FETCH_CUSTOM_SCORE_START,
  payload: { loading: 'fetch' },
})
export const fetchCustomScoreSuccess = customScore => ({
  type: FETCH_CUSTOM_SCORE_SUCCESS,
  payload: customScore,
})
export const fetchCustomScoreError = error => ({
  type: FETCH_CUSTOM_SCORE_ERROR,
  payload: error,
})
export const fetchCustomScoreEnd = () => ({
  type: FETCH_CUSTOM_SCORE_END,
})

export const deleteCustomScoreStart = () => ({
  type: DELETE_CUSTOM_SCORE_START,
  payload: { loading: 'delete' },
})
export const deleteCustomScoreSuccess = () => ({
  type: DELETE_CUSTOM_SCORE_SUCCESS,
})
export const deleteCustomScoreError = error => ({
  type: DELETE_CUSTOM_SCORE_ERROR,
  payload: error,
})
export const deleteCustomScoreEnd = () => ({
  type: DELETE_CUSTOM_SCORE_END,
})

export const updateCustomScoreStart = () => ({
  type: UPDATE_CUSTOM_SCORE_START,
  payload: { loading: 'update' },
})
export const updateCustomScoreSuccess = score => ({
  type: UPDATE_CUSTOM_SCORE_SUCCESS,
  payload: score,
})
export const updateCustomScoreError = payload => ({
  type: UPDATE_CUSTOM_SCORE_ERROR,
  payload,
})
export const updateCustomScoreEnd = () => ({
  type: UPDATE_CUSTOM_SCORE_END,
})

export const createCustomScoreStart = () => ({
  type: CREATE_CUSTOM_SCORE_START,
  payload: { loading: 'create' },
})
export const createCustomScoreSuccess = score => ({
  type: CREATE_CUSTOM_SCORE_SUCCESS,
  payload: score,
})
export const createCustomScoreError = payload => ({
  type: CREATE_CUSTOM_SCORE_ERROR,
  payload,
})
export const createCustomScoreEnd = status => ({
  type: CREATE_CUSTOM_SCORE_END,
  payload: status,
})
export const resetCustomScore = () => ({
  type: RESET_CUSTOM_SCORE,
})
export const uploadMidiStart = () => ({
  type: UPLOAD_MIDI_START,
  payload: { loading: 'uploadMidi' },
})
export const uploadMidiSuccess = payload => ({
  type: UPLOAD_MIDI_SUCCESS,
  payload,
})
export const uploadMidiEnd = () => ({
  type: UPLOAD_MIDI_END,
})
export const uploadMidiError = error => ({
  type: UPLOAD_MIDI_ERROR,
  payload: error,
})
