import undoable, { excludeAction } from 'redux-undo'

import initialState from 'store/state'

import {
  INIT_COMPOSER_END,
  INIT_COMPOSER_START,
  INIT_COMPOSER_SUCCESS,
  RESET_COMPOSER,
  SET_CLIPBOARD,
  SET_COMPOSER_META,
  SET_COMPOSER_SETTING,
  SET_COMPOSER_TUNE,
} from './types'

const composerReducer = (state = initialState.composerV2, action) => {
  switch (action.type) {
    case INIT_COMPOSER_START:
      return {
        ...state,
        loading: true,
      }

    case INIT_COMPOSER_END:
      return {
        ...state,
        loading: false,
      }

    case INIT_COMPOSER_SUCCESS: {
      const customScore = action.payload
      return {
        ...state,
        ...customScore,
      }
    }

    case SET_COMPOSER_TUNE: {
      const tune = action.payload
      return {
        ...state,
        ...tune,
      }
    }

    case SET_COMPOSER_SETTING: {
      const setting = action.payload
      return {
        ...state,
        settings: {
          ...state.settings,
          ...setting,
        },
      }
    }

    case SET_CLIPBOARD:
      return {
        ...state,
        clipboard: action.payload,
      }

    case SET_COMPOSER_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
      }

    case RESET_COMPOSER:
      return action.payload

    default:
      return state
  }
}

export default undoable(composerReducer, { limit: 20, filter: excludeAction([SET_COMPOSER_SETTING]) })
