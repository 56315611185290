import { AbcNotation } from 'components/Composer/models/types'

import { MusicalElement } from '..'

import { ArticulationDecorator } from './ArticulationDecorator'

import { DecoratorConstructor } from '.'

export class SlurDecorator extends ArticulationDecorator {
  get abcNotation(): AbcNotation {
    if (this.index === 0) return `(${this.musicalElement.abcNotation}` as AbcNotation
    if (this.index === -1) return `${this.musicalElement.abcNotation})` as AbcNotation

    return this.musicalElement.abcNotation
  }

  static decorateMusicalElements(
    Decorator: DecoratorConstructor,
    ...args: ConstructorParameters<typeof MusicalElement>
  ) {
    const [settings, maestro] = args
    const elements = this.buildMusicalElements(args[1])
    return elements.map((element, index) => {
      const isLast = elements.length - 1 === index
      return new Decorator({ settings, maestro, element, index: isLast ? -1 : index })
    })
  }
}
