import { Backdrop } from '@mui/material'
import { Controller } from 'react-hook-form'

import { Search, Select } from 'components/ui'

import { SearchContainer } from './styles'

import { useScoreSearchContext } from '.'

export const ScoreSearch = () => {
  const {
    isSearchActive,
    setIsSearchActive,
    scoreOptions,
    fetchScoreOptionsLoading,
    fetchScoresMeta,
    handleOptionClick,
    playableScoreTypeOptions,
    control,
  } = useScoreSearchContext()

  return (
    <Backdrop open={isSearchActive} onClick={() => setIsSearchActive(false)}>
      <SearchContainer>
        <Controller
          name="query"
          control={control}
          render={({ field }) => (
            <Search
              placeholder="Search Scores or Custom Scores"
              options={scoreOptions}
              loading={fetchScoreOptionsLoading}
              meta={fetchScoresMeta}
              onSelectOption={(_, option) => handleOptionClick(option)}
              sx={{ flex: 2 }}
              {...field}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              options={playableScoreTypeOptions}
              containerProps={{ sx: { flex: 1, background: theme => theme.palette.background.paper } }}
              {...field}
            />
          )}
        />
      </SearchContainer>
    </Backdrop>
  )
}
