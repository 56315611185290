import { useApi } from 'hooks'
import { FavoriteScore } from 'models'
import { FavoriteScoreEndpoint } from 'routes/locations'
import { isEmpty } from 'utils'

export const useFavoriteScoreController = (scoreId: Id) => {
  const [fetchFavoriteScore, favoriteScore] = useApi<FavoriteScore>(FavoriteScoreEndpoint, 'GET', {
    score_id: scoreId,
  })

  const [destroyFavoriteScore] = useApi<FavoriteScore>(FavoriteScoreEndpoint, 'DELETE', {
    score_id: scoreId,
  })

  return {
    fetchFavoriteScore,
    favoriteScore: isEmpty(favoriteScore) ? null : favoriteScore,
    destroyFavoriteScore,
  }
}
