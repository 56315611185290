import { maestro } from 'components/Composer/hooks'

import { MusicalElement } from './MusicalElement'
import { TextAreaSelection } from './types'

export class Clipboard {
  static copy() {
    return maestro.getTextareaSelection()
  }

  static paste(clipboard: TextAreaSelection) {
    const elements = maestro.getElementsFromTextareaSelection(clipboard)
    const elementsSettings = MusicalElement.convertAbcjsToElementsSettings(elements)

    const composition = maestro.compose(elementsSettings)
    if (!composition) return

    maestro.write(composition)
  }
}
