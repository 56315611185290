import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { fadeSlideAnimation } from 'styles/keyframes'

export const Container = styled(Link)`
  opacity: 0;
  animation: ${() => fadeSlideAnimation()} 0.3s ${props => (props.index || 0) * 0.04}s forwards;
`
export const Content = styled.div`
  width: 100%;
  height: 150px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  color: ${props => props.theme.palette.text.primary};
  background: ${props => props.theme.palette.background.paper};
  position: relative;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s;

  &:hover {
    transform: scale(1.1);
    box-shadow: ${({ theme }) => theme.palette.shadows.A900};
  }

  & > * {
    z-index: 10;
  }
`
