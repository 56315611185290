import { AbcVisualParams, Responsive, SynthOptions, SynthVisualOptions } from 'abcjs'

export const ABC_RENDERER_OPTIONS = {
  visual: {
    responsive: 'resize' as Responsive,
    add_classes: true,
    staffwidth: 500,
    paddingtop: 0,
    wrap: { minSpacing: 1.8, maxSpacing: 2.8, preferredMeasuresPerLine: 4 },
    visualTranspose: 0,
  } as AbcVisualParams,
  synth: {
    program: 40,
    chordsOff: true,
    soundFontUrl: 'https://gleitz.github.io/midi-js-soundfonts/MusyngKite/',
    midiTranspose: 0,
    displayLoop: true,
    displayRestart: true,
    displayPlay: true,
    displayProgress: true,
    displayWarp: true,
  } as SynthOptions & SynthVisualOptions,
}
