import { useCallback, useEffect, useState } from 'react'

import { IconButton, Tooltip } from '@mui/material'

import { RangeContainer, Slider } from './styles'

export const Range = ({
  label,
  initialValue = 0,
  onChange,
  Icon,
  step = 1,
  min = -10,
  max = 10,
  disabled = false,
  tooltipProps = {},
}) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initialValue?.toString())

  const handleToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleClose = useCallback(
    e => {
      const isToggle = e.relatedTarget === e.target.parentElement.previousElementSibling
      if (isToggle) return
      open && setOpen(false)
    },
    [open],
  )

  const handleDrag = useCallback((_, selectedValue) => {
    setValue(selectedValue)
  }, [])

  const handleChange = useCallback(
    (_, selectedValue) => {
      onChange(selectedValue)
    },
    [onChange],
  )

  useEffect(() => {
    if (!initialValue) return
    setValue(initialValue)
  }, [initialValue])

  return (
    <RangeContainer>
      <Tooltip {...tooltipProps}>
        <IconButton aria-label={label} onClick={handleToggle} disabled={disabled} size="large">
          <Icon />
        </IconButton>
      </Tooltip>

      {open && (
        <Slider
          value={value}
          step={step}
          shiftStep={30}
          min={min}
          max={max}
          onChange={handleDrag}
          onChangeCommitted={handleChange}
          onBlur={handleClose}
          valueLabelDisplay="on"
          color="secondary"
          marks
        />
      )}
    </RangeContainer>
  )
}

export default Range
