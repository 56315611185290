import { ChangeEvent, useCallback, useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { CameraAltOutlined, EmailOutlined, LockOutlined, PersonOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import EmptyAvatar from 'assets/images/avatar-placeholder.png'
import { Button, DotLoader, Input, PageContainer } from 'components/ui'
import { useUserAccountController } from 'controllers'

import {
  userAvatarSchema,
  UserFormValues,
  userSchema,
  userUpdatePasswordSchema,
  UserUpdatePasswordSchemaFormValues,
} from './schemas'
import { AvatarInput, Form, FormsContainer, InputGroup } from './styles'
import { resetAvatarAnimation } from './utils'

export const UserAccountPage = () => {
  const avatarImgRef = useRef<HTMLImageElement>(null)

  const {
    fetchCurrentUser,
    fetchCurrentUserLoading,
    currentUser,
    updateDeviseCurrentUser,
    updateDeviseCurrentUserLoading,
    updatePassword,
    updatePasswordLoading,
    updateCurrentUser,
    updateCurrentUserLoading,
    updateCurrentUserError,
  } = useUserAccountController()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserFormValues>({
    resolver: yupResolver(userSchema),
  })

  const {
    control: controlPassForm,
    handleSubmit: handleSubmitPassForm,
    formState: { errors: errorsPassForm },
  } = useForm<UserUpdatePasswordSchemaFormValues>({
    resolver: yupResolver(userUpdatePasswordSchema),
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  })

  const { avatarUrl } = currentUser || {}

  useEffect(() => {
    fetchCurrentUser()
  }, []) /* eslint-disable-line */

  useEffect(() => {
    if (!currentUser) return

    const { firstName, lastName, email } = currentUser
    const userData = { first_name: firstName, last_name: lastName, email }

    reset(userData)
  }, [currentUser, reset])

  useEffect(() => {
    if (updateCurrentUserError) {
      resetAvatarAnimation(avatarImgRef.current!)
      if (avatarImgRef.current) avatarImgRef.current.src = avatarUrl!
    }
  }, [updateCurrentUserError, avatarUrl])

  const onSubmit = useCallback(
    (data: UserFormValues) => {
      updateDeviseCurrentUser({ user: data })
    },
    [updateDeviseCurrentUser],
  )

  const onSubmitPassword = useCallback(
    (data: UserUpdatePasswordSchemaFormValues) => {
      updatePassword({ user: data })
    },
    [updatePassword],
  )

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.files?.length) return

      const file = e.target.files[0]
      await userAvatarSchema.validate({ file })

      if (!avatarImgRef.current) return

      resetAvatarAnimation(avatarImgRef.current)
      avatarImgRef.current.src = URL.createObjectURL(file)

      const formData = new FormData()
      formData.append('user[avatar]', file)

      updateCurrentUser(formData)
    },
    [updateCurrentUser],
  )

  if (fetchCurrentUserLoading || !currentUser) return <>Loading...</>

  return (
    <PageContainer title="Account">
      <AvatarInput>
        <figure>
          <img ref={avatarImgRef} src={avatarUrl || EmptyAvatar} alt={currentUser.firstName} />
          {!avatarUrl && (
            <Typography
              variant="caption"
              color="text.secondary"
              component="figcaption"
              sx={{ textAlign: 'center' }}
            >
              <a href="https://www.flaticon.com/free-icons/flower" target="_blank" rel="noreferrer">
                Freepik - Flaticon
              </a>
            </Typography>
          )}
        </figure>

        <label htmlFor="avatar">
          {updateCurrentUserLoading ? <DotLoader small /> : <CameraAltOutlined />}
          <input type="file" id="avatar" accept="image/png, image/jpeg" onChange={handleAvatarChange} />
        </label>
      </AvatarInput>

      <FormsContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            <Input<UserFormValues>
              control={control}
              type="text"
              name="first_name"
              label="First Name"
              placeholder="First Name"
              icon={PersonOutlined}
              errorMessage={errors.first_name?.message as string}
            />
            <Input<UserFormValues>
              control={control}
              type="text"
              name="last_name"
              label="Last Name"
              placeholder="Last Name"
              icon={PersonOutlined}
              errorMessage={errors.last_name?.message as string}
            />
          </InputGroup>
          <Input<UserFormValues>
            control={control}
            type="email"
            name="email"
            label="Email"
            placeholder="Email"
            icon={EmailOutlined}
            errorMessage={errors.email?.message as string}
          />
          <Input<UserFormValues>
            control={control}
            type="password"
            name="current_password"
            label="Password"
            placeholder="Password"
            icon={LockOutlined}
            errorMessage={errors.current_password?.message as string}
            autoComplete="on"
          />

          <Button type="submit" loading={updateDeviseCurrentUserLoading}>
            Update
          </Button>
        </Form>

        <Form onSubmit={handleSubmitPassForm(onSubmitPassword)}>
          <Input<UserUpdatePasswordSchemaFormValues>
            control={controlPassForm}
            type="password"
            name="current_password"
            label="Current Password"
            placeholder="Current Password"
            icon={LockOutlined}
            errorMessage={errorsPassForm.current_password?.message as string}
            autoComplete="on"
          />
          <Input<UserUpdatePasswordSchemaFormValues>
            control={controlPassForm}
            type="password"
            name="password"
            label="New Password"
            placeholder="New Password"
            icon={LockOutlined}
            errorMessage={errorsPassForm.password?.message as string}
            autoComplete="on"
          />
          <Input<UserUpdatePasswordSchemaFormValues>
            control={controlPassForm}
            type="password"
            name="password_confirmation"
            label="Confirm Password"
            placeholder="Confirm Password"
            icon={LockOutlined}
            errorMessage={errorsPassForm.password_confirmation?.message as string}
            autoComplete="on"
          />

          <Button type="submit" loading={updatePasswordLoading}>
            Update Password
          </Button>
        </Form>
      </FormsContainer>
    </PageContainer>
  )
}
