import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useEffect, useState } from 'react'

import { BoxProps, TextField, TextFieldProps } from '@mui/material'

import { Container, Text } from './styles'

type Props = {
  value?: string
  error?: boolean
  readOnly?: boolean
  inputProps?: TextFieldProps
  onSubmit?: (value: string) => void
} & BoxProps

export const EditableTextField = ({
  value = '',
  readOnly = false,
  error = false,
  inputProps = {},
  onSubmit = () => {},
  ...rest
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [fieldValue, setFieldValue] = useState<string>((inputProps.value as string) || value)

  useEffect(() => {
    if (!error) return

    setFieldValue(value)
  }, [error])

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const handleDoubleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (isEditMode || readOnly) return
    setIsEditMode(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange?.(e)
    setFieldValue(e.target.value)
  }

  const handleBlur = () => {
    setIsEditMode(false)

    if (fieldValue === value) return
    onSubmit(fieldValue)
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }

  const handleKeySubmit = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') handleBlur()
    if (e.key !== 'Escape') return

    setIsEditMode(false)
    setFieldValue(value)
  }

  return (
    <Container onDoubleClick={handleDoubleClick} onClick={handleClick} {...rest}>
      <Text $isEditMode={isEditMode}>{fieldValue}</Text>

      {isEditMode && (
        <TextField
          autoFocus
          multiline
          value={fieldValue}
          variant="outlined"
          {...inputProps}
          onKeyDown={handleKeySubmit}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </Container>
  )
}
