import { useCallback, useState } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

import { DARK_APP_THEME, LIGHT_APP_THEME } from 'styles/themes'

export const useTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const initialTheme = prefersDarkMode ? DARK_APP_THEME : LIGHT_APP_THEME

  const [theme, setTheme] = useState(initialTheme)

  const toggleTheme = useCallback(() => {
    setTheme(({ palette }) => {
      return palette.mode === 'light' ? DARK_APP_THEME : LIGHT_APP_THEME
    })
  }, [])

  return { theme, toggleTheme }
}
