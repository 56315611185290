import { keyframes } from 'styled-components'

import { QUARTER_NOTE_HEIGHT } from 'utils/settings'

const strings = {
  angleG: '2deg',
  angleD: '1deg',
  angleA: '-1deg',
  angleE: '-2deg',
}

const sliderHeight = window.innerHeight + QUARTER_NOTE_HEIGHT * 8 // 4t note heigh

export const G = keyframes`
  0% {
    transform: rotate(${strings.angleG}) translateY(0);
  }

  100% {
    transform: rotate(${strings.angleG}) translateY(${sliderHeight}px);
  }
`

export const D = keyframes`
  0% {
    transform: rotate(${strings.angleD}) translateY(0);
  }

  100% {
    transform: rotate(${strings.angleD}) translateY(${sliderHeight}px);
  }
`

export const A = keyframes`

  0% {
    transform: rotate(${strings.angleA}) translateY(0);
  }

  100% {
    transform: rotate(${strings.angleA}) translateY(${sliderHeight}px);
  }
`

export const E = keyframes`
  0% {
    transform: rotate(${strings.angleE}) translateY(0);
  }

  100% {
    transform: rotate(${strings.angleE}) translateY(${sliderHeight}px);
  }
`
