export class CursorControl {
  container: HTMLElement
  containerHeight: number
  containerSelector: string
  rootSelector: string

  constructor() {
    this.rootSelector = '#sheet-music'
    this.containerSelector = '#sheet-music-container'
  }

  setContainer() {
    this.container = this.container || document.querySelector(this.containerSelector)

    this.containerHeight = this.container.offsetHeight
  }

  onStart() {
    this.setContainer()
  }

  removeSelection() {
    const lastSelection = document.querySelectorAll(`${this.rootSelector} .abcjs-highlight`)
    for (let k = 0; k < lastSelection.length; k++) {
      lastSelection[k].classList.remove('abcjs-highlight')
    }
  }

  // @ts-ignore: library
  onEvent(e) {
    if (!e) return this.onFinished()
    if (e.measureStart && e.left === null) return

    this.removeSelection()

    for (let i = 0; i < e.elements.length; i++) {
      const note = e.elements[i]
      this.scroll(e, note)

      for (let j = 0; j < note.length; j++) {
        note[j].classList.add('abcjs-highlight')
      }
    }
  }

  onFinished() {
    this.removeSelection()

    setTimeout(() => this.container.scrollTo({ top: 0, behavior: 'smooth' }), 1000)
  }

  // private

  // @ts-ignore: library
  scroll(e: Event, note) {
    const noteOffset = note[0].getClientRects()[0]
    const { container, containerHeight } = this
    if (!container) return

    const noteOffsetTop = noteOffset?.top || 0
    const isBellow = containerHeight - noteOffsetTop < 120
    const isAbove = container.scrollTop > container.scrollTop + noteOffsetTop

    if (isBellow || isAbove) {
      container.scrollTo({
        top: container.scrollTop + noteOffset.top - 100,
        behavior: 'smooth',
      })
    }
  }
}
