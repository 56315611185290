import styled from 'styled-components'

export const Container = styled.div`
  width: 100px;
  position: relative;

  &:focus {
    z-index: 10;
  }

  > p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .MuiTextField-root {
    top: 0;
    min-height: 20px;
    position: absolute;
    background: ${({ theme }) => theme.palette.background.default};
  }

  .MuiInputBase-root {
    padding: 4px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiInputBase-input {
    text-align: center;
    background: ${({ theme }) => theme.palette.background.default};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 0.5px;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`
