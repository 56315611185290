/* eslint-disable no-loop-func */
import { VoiceItemNote } from 'abcjs'
import Fraction from 'fraction.js'

import { RESTS } from '../constants'

import {
  AbcElementDuration,
  AbcNotation,
  ComposerPanelControlWithDuration,
  ElementDuration,
  ElementWithDuration,
} from './types'

/**
 * Handles calculating and converting musical elements tempo
 */
export class TempoManager {
  static getRestsFromBeats(beats: number): AbcNotation[] {
    const rests: AbcNotation[] = []

    while (beats > 0) {
      /* eslint-disable @typescript-eslint/no-loop-func */
      const beatRest = RESTS.find(rest => rest.duration <= beats)! // FIXME: dotted rests
      const newRestDuration = TempoManager.durationNumberToAbcNotation(beatRest.duration)
      const newRest = `${beatRest.value}${newRestDuration}` as AbcNotation
      rests.push(newRest)
      beats -= beatRest.duration
    }

    return rests.reverse()
  }

  /**
   * @static
   * @name durationNumberToAbcNotation
   * Convert duration number to abc notation
   * @param {Number} durationNumber an integer or float point number that
   *   represents the duration of a musical element, eg: note or rest
   * @return {String} the duration abc natation
   * @example <caption>Example: abc notation is larger than 1</caption>
   * TempoManager.durationNumberToAbcNotation(0.75);
   * => '6'
   * TempoManager.durationNumberToAbcNotation(0.5);
   * => '4'
   * @example <caption>Example: abc notation is smaller than 1</caption>
   * TempoManager.durationNumberToAbcNotation(0.0625);
   * => '/2'
   * TempoManager.durationNumberToAbcNotation(0.046875);
   * => '/3' // dotted note
   */

  static durationNumberToAbcNotation(durationNumber: ElementDuration): AbcElementDuration {
    const durationAbcNotation = durationNumber * 8
    const isFractionLargerThanOne = durationAbcNotation > 1 && !Number.isInteger(durationAbcNotation)

    if (durationAbcNotation < 1) {
      const fraction = new Fraction(durationAbcNotation)
      const isMultipleOfEight = fraction.d % 8 === 0
      const shouldSimplify = fraction.n === 3 && isMultipleOfEight
      /* Simplifies fractions 3/8 to /3, 3/16 to /6 and so on */
      const durationNumeratorLargerThanOne = shouldSimplify
        ? `/${(fraction.n * fraction.d) / 8}`
        : fraction.toFraction().toString()
      return (fraction.n === 1 ? `/${fraction.d}` : durationNumeratorLargerThanOne) as AbcElementDuration
    }
    if (isFractionLargerThanOne) {
      return new Fraction(durationAbcNotation).toFraction().toString() as AbcElementDuration
    }

    return durationAbcNotation.toString() as AbcElementDuration
  }

  static calculateDurationWithDot(control: ComposerPanelControlWithDuration, element: VoiceItemNote): number {
    return element.duration * control.duration
  }

  static getElementsDuration(elements: ElementWithDuration[]): number {
    return elements.reduce((acc, curr) => acc + ('elem' in curr ? curr.elem.duration : curr.duration), 0)
  }

  static getElementsForDuration(
    elements: ElementWithDuration[],
    expectedDuration: ElementDuration | number,
  ): ElementWithDuration[] {
    let elementDurationSum = 0
    return elements.reduce((acc: ElementWithDuration[], element) => {
      const elementDuration = 'elem' in element ? element.elem.duration : element.duration
      const hasFilledExpectedDuration = elementDurationSum >= expectedDuration
      elementDurationSum += elementDuration

      if (!hasFilledExpectedDuration) acc.push(element)
      return acc
    }, [])
  }
}
