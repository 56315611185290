import { KeyboardEvent, MouseEvent, useCallback } from 'react'

import { CircularProgress, MenuItem, SxProps, TextField, Zoom } from '@mui/material'
import parse from 'html-react-parser'

import { Meta, useThrottle } from 'hooks'

import { Autocomplete, FilterIcon, IconButton, Listbox, TotalCount } from './styles'

const THROTTLE_DELAY = 1000

export type SearchOption = {
  id: string
  title: string
  highlight: string
}

type Props = {
  loading: boolean
  name: string
  value: string
  meta: Meta
  placeholder?: string
  options: SearchOption[]
  onChange: (value: string) => void
  onEnter?: () => void
  onSelectOption?: (e: MouseEvent<HTMLElement>, option: SearchOption) => void
  onFilterToggle?: () => void
  sx?: SxProps
}

export const Search = ({
  loading,
  value,
  meta,
  placeholder,
  options = [],
  onSelectOption = () => {},
  onEnter = () => {},
  onFilterToggle,
  ...rest
}: Props) => {
  const throttledOnChange = useThrottle(rest.onChange, THROTTLE_DELAY)

  const handleOnEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key !== 'Enter') return

      e.preventDefault()
      e.stopPropagation()
      setTimeout(() => onEnter(), THROTTLE_DELAY)
    },
    [onEnter],
  )

  const handleOptionClick = (e: MouseEvent<HTMLElement>, option: SearchOption) => {
    rest.onChange(option.title)
    onSelectOption(e, option)
  }

  const getOptionLabel = (option: SearchOption) => {
    if (typeof option === 'object' && 'target' in option) return ''
    if (typeof option === 'string') return option || ''
    return option.title || ''
  }

  const handleFilterToggle = useCallback(() => onFilterToggle?.(), [onFilterToggle])

  return (
    // @ts-ignore library bug: https://github.com/mui/material-ui/issues/21727
    <Autocomplete
      value={value || ''}
      options={options}
      loading={loading}
      getOptionLabel={getOptionLabel}
      filterOptions={(filterOptions: SearchOption[]) => filterOptions} // remove this when options update correctly
      onInputChange={(_, inputValue) => throttledOnChange(inputValue)}
      ListboxComponent={Listbox}
      {...rest}
      renderOption={(_, option: SearchOption, ..._rest) =>
        option && (
          <MenuItem
            sx={{ whiteSpace: 'pre' }}
            onClick={e => handleOptionClick(e, option)}
            key={option.id}
            {...option}
          >
            {parse(option.highlight || option.title)}
          </MenuItem>
        )
      }
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          onKeyDown={handleOnEnter}
          InputProps={{
            ...params.InputProps,
            onClick: e => e.stopPropagation(),
            endAdornment: (
              <>
                {value && (
                  <Zoom in={!!meta?.total}>
                    <TotalCount label={meta?.total} size="small" />
                  </Zoom>
                )}
                {!!onFilterToggle && (
                  <IconButton size="small" onClick={handleFilterToggle}>
                    <FilterIcon />
                  </IconButton>
                )}
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
