import { useEffect } from 'react'

import { List, Typography } from '@mui/material'

import { PageContainer } from 'components/ui'
import { CourseProvider, useCourseContext } from 'providers'
import { formatDate } from 'utils'

import { CourseModuleAccordion } from '../components'

export const CoursePageFC = () => {
  const { fetchCourse, course } = useCourseContext()

  useEffect(() => {
    fetchCourse()
  }, [])

  if (!course) return <>Loading...</>

  return (
    <PageContainer title={course.title}>
      <Typography variant="body1">{course.description}</Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Author: {course.teacher.fullName}
      </Typography>
      <Typography variant="body2" sx={{ mb: 4 }}>
        Published at: {formatDate(course.publishedAt)}
      </Typography>

      <List>
        {course.courseModules.map((courseModule, index) => (
          <CourseModuleAccordion
            key={courseModule.id}
            courseModule={courseModule}
            defaultExpanded={index < 2}
          />
        ))}
      </List>
    </PageContainer>
  )
}

export const CoursePage = () => (
  <CourseProvider>
    <CoursePageFC />
  </CourseProvider>
)
