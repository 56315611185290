import initialState from 'store/state'

import {
  FETCH_CURRENT_USER_END,
  FETCH_CURRENT_USER_ERROR,
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_SUCCESS,
  FORGOT_PASSWORD_END,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_END,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN_END,
  SIGN_IN_ERROR,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_OUT_END,
  SIGN_OUT_SUCCESS,
  SIGN_UP_END,
  SIGN_UP_ERROR,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  UPDATE_CURRENT_USER_END,
  UPDATE_CURRENT_USER_ERROR,
  UPDATE_CURRENT_USER_START,
  UPDATE_CURRENT_USER_SUCCESS,
} from './types'

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case SIGN_UP_START:
    case SIGN_IN_START:
    case FORGOT_PASSWORD_START:
    case RESET_PASSWORD_START:
    case FETCH_CURRENT_USER_START:
      return {
        ...state,
        loading: true,
      }
    case SIGN_UP_SUCCESS:
    case SIGN_IN_SUCCESS:
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    case SIGN_UP_ERROR:
    case SIGN_IN_ERROR:
    case FORGOT_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
    case FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case SIGN_UP_END:
    case SIGN_IN_END:
    case SIGN_OUT_END:
    case FORGOT_PASSWORD_END:
    case RESET_PASSWORD_END:
    case FETCH_CURRENT_USER_END:
      return {
        ...state,
        loading: false,
        error: null,
        status: null,
      }

    case UPDATE_CURRENT_USER_START:
      return {
        ...state,
        update: {
          loading: action.payload.request,
        },
      }

    case UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        data: action.payload.currentUser,
        update: {
          ...state.update,
          request: action.payload.request,
        },
      }

    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        data: {},
      }

    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: action.payload,
      }

    case UPDATE_CURRENT_USER_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          error: action.payload.error,
          request: action.payload.request,
        },
      }

    case UPDATE_CURRENT_USER_END:
      return {
        ...state,
        update: {},
      }

    default:
      return state
  }
}

export default authReducer
