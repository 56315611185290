import { useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'

import { SearchOption } from 'components/ui'
import { useCustomScoreOptionsController, useScoreOptionsController } from 'controllers'

export const PLAYABLE_SCORE_TYPE_OPTIONS = [
  { label: 'Score', value: 'Score' },
  { label: 'CustomScore', value: 'CustomScore' },
]

type ScoreSearchFormValues = {
  query: string
  type: string
}
type SearchParams = {
  [key: string]: string | number
}

export const useScoreSearch = () => {
  const { fetchScoreOptions, scoreOptions, fetchScoreOptionsLoading, fetchScoresMeta } =
    useScoreOptionsController()
  const {
    fetchCustomScoreOptions,
    customScoreOptions,
    fetchCustomScoreOptionsLoading,
    fetchCustomScoresMeta,
  } = useCustomScoreOptionsController()

  const { control, watch } = useForm<ScoreSearchFormValues>({
    defaultValues: { query: '', type: 'Score' },
  })

  const [isSearchActive, setIsSearchActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Nullable<SearchOption>>()
  const searchParams = useRef<SearchParams>({})
  const formValues = watch()
  const isScoreType = formValues.type === 'Score'

  useEffect(() => {
    if (!formValues.query) return

    const fetchOptions = isScoreType ? fetchScoreOptions : fetchCustomScoreOptions
    const fetchOptionsKey = isScoreType ? 'query' : 'title'
    fetchOptions({}, { [fetchOptionsKey]: formValues.query })
  }, [formValues.query])

  const handleToggleSearch = (params: SearchParams = {}) => {
    setIsSearchActive(prevIsSearchActive => !prevIsSearchActive)
    searchParams.current = params
  }

  const handleOptionClick = (option: SearchOption) => {
    setSelectedOption(option)
    setIsSearchActive(false)
  }

  return {
    scoreOptions: isScoreType ? scoreOptions : customScoreOptions,
    fetchScoreOptionsLoading: isScoreType ? fetchScoreOptionsLoading : fetchCustomScoreOptionsLoading,
    fetchScoresMeta: isScoreType ? fetchScoresMeta : fetchCustomScoresMeta,
    control,
    formValues,
    selectedOption,
    isSearchActive,
    setIsSearchActive,
    handleOptionClick,
    handleToggleSearch,
    searchParams: searchParams.current,
    playableScoreTypeOptions: PLAYABLE_SCORE_TYPE_OPTIONS,
  }
}
