import { ReactNode } from 'react'

import { Card, CardProps } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'

import { cardStyles } from './styles'

type Props = {
  children: ReactNode
} & CardProps &
  LinkProps

export const CardLink = ({ to, children, sx, ...rest }: Props) => {
  return (
    <Card to={to} component={Link} sx={{ ...cardStyles, ...sx }} {...rest}>
      {children}
    </Card>
  )
}
