import { useAutoSave } from 'hooks'
import { CustomScore } from 'models'

type CustomScoreParams = Partial<CustomScore>

export const useComposerAutoSave = (
  initialData: CustomScoreParams,
  callback?: (data: CustomScoreParams) => void,
) => {
  const [_, setData] = useAutoSave<CustomScoreParams>(initialData, { callback, interval: 300_000 })

  const handleAutoSave = (newData: CustomScoreParams) => {
    setData(newData)
  }

  return { handleAutoSave }
}
