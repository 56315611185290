import { useCallback, useRef, useState } from 'react'

import { Avatar, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { BiBookReader } from 'react-icons/bi'
import { FiEdit, FiHeart, FiLogIn, FiLogOut, FiMusic, FiUser } from 'react-icons/fi'
import { IoSchoolOutline, IoSpeedometerOutline } from 'react-icons/io5'
import { Link, useLocation } from 'react-router-dom'

import logoText from 'assets/images/logo-text.png'
import logo from 'assets/images/logo.png'
import { Menu } from 'components/ui'
import { useAuth, useBreakpoint, useUserRole } from 'hooks'
import { useCurrentUserContext, useThemeContext } from 'providers'
import { SignOutEndpoint } from 'routes/locations'

import {
  Container,
  LinkToLogo,
  LinkWithLabel,
  Logo,
  LogoText,
  MoonIcon,
  Nav,
  UnconfirmedEmailBanner,
} from './styles'

const MAIN_MENU_ITEMS = [
  {
    label: 'Account',
    pathname: '/me',
    Icon: FiUser,
  },
  {
    label: 'My Sheet Music',
    pathname: '/me/sheet-music',
    Icon: FiMusic,
  },
  {
    label: 'Favorites',
    pathname: '/me/favorites',
    Icon: FiHeart,
  },
  {
    label: 'Tuner',
    pathname: '/tuner',
    Icon: IoSpeedometerOutline,
  },
  {
    label: 'Learning Center',
    pathname: '/courses/violin',
    Icon: BiBookReader,
    roles: ['admin'],
  },
  {
    label: 'Teacher Area',
    pathname: '/teacher/courses',
    Icon: IoSchoolOutline,
    roles: ['teacher'],
  },
  {
    label: 'Composer',
    pathname: '/composer',
    Icon: FiEdit,
    roles: ['admin'],
  },
]

export const Topbar = () => {
  const { currentUser } = useCurrentUserContext()
  const { hasRoleAccess } = useUserRole()
  const [signOut] = useAuth(SignOutEndpoint, 'DELETE')

  const userAvatarRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { toggleTheme } = useThemeContext()
  const location = useLocation()
  const { downTo } = useBreakpoint()

  const { firstName, avatarThumbUrl } = currentUser
  const isPlayerRoute = location.pathname.startsWith('/sheet-music')

  const handleMenu = useCallback(() => {
    setIsMenuOpen(prevState => !prevState)
  }, [])

  if (isPlayerRoute) return null

  return (
    <Container>
      <LinkToLogo to="/">
        <Logo src={logo} alt="Icon logo" />
        <LogoText src={logoText} alt="Icon logo" />
      </LinkToLogo>

      <Nav>
        <ul>
          <li>
            <Link to="/tuner" aria-label="Tuner">
              <IoSpeedometerOutline size={20} />
            </Link>
          </li>
          {downTo('sm') && (
            <li>
              <button type="button" onClick={toggleTheme} aria-label="Dark Mode">
                <MoonIcon size={20} />
              </button>
            </li>
          )}
          {currentUser.id ? (
            <>
              <IconButton onClick={handleMenu} ref={userAvatarRef} sx={{ p: 0, ml: 3 }}>
                <Avatar
                  alt={firstName}
                  src={avatarThumbUrl}
                  size="large"
                  imgProps={{ onError: () => signOut() }}
                />
              </IconButton>
              <Menu
                open={isMenuOpen}
                onClose={handleMenu}
                anchorEl={userAvatarRef.current}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ height: '500px', mt: '50px', ml: '-12px' }}
                keepMounted
              >
                {MAIN_MENU_ITEMS.map(
                  ({ label, pathname, Icon, roles }) =>
                    hasRoleAccess(roles) && (
                      <MenuItem
                        component={Link}
                        onClick={handleMenu}
                        to={{ pathname }}
                        key={label}
                        sx={{ pl: 3, pr: 7 }}
                      >
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                        <ListItemText primary={label} />
                      </MenuItem>
                    ),
                )}

                <MenuItem
                  component={Link}
                  sx={{ pl: 3, pr: 7 }}
                  onClick={() => {
                    handleMenu()
                    signOut()
                  }}
                >
                  <ListItemIcon>
                    <FiLogOut />
                  </ListItemIcon>
                  <ListItemText primary="Log out" />
                </MenuItem>
              </Menu>
            </>
          ) : (
            <li>
              <LinkWithLabel to="/sign-up">
                <FiLogIn size={20} />
                Sign Up
              </LinkWithLabel>
            </li>
          )}
        </ul>

        {currentUser?.id && currentUser.pendingConfirmation && (
          <UnconfirmedEmailBanner>
            Your account has not been activated. Please, check your email.
          </UnconfirmedEmailBanner>
        )}
      </Nav>
    </Container>
  )
}
