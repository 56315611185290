import { isEmpty } from 'utils'

export const getOldestObject = <T extends object>(obj: T, dateKey: keyof T) => {
  if (!obj || isEmpty(obj) || !dateKey) return
  const keys = Object.keys(obj) as [keyof T]
  const newestKey = keys.reduce((curr: keyof T, key: keyof T) => {
    // @ts-ignore: fix
    return obj[curr][dateKey] < obj[key][dateKey] ? curr : key
  })
  return obj[newestKey]
}
