import styled from 'styled-components'

import { ReactComponent as FingerboardTriggerLine } from 'assets/images/fingerboard-trigger-line.svg'
import { SLIDER_STRINGS } from 'utils/settings'

import { FINGERBOARD_NOTE_WIDTH } from './constants'
import { A, D, E, G } from './keyframes'

const STRING_ANIMATION_NAMES = { G, D, A, E }

type SliderStringKey = keyof typeof SLIDER_STRINGS

type SliderNote = {
  $string: string
  $animationDelay: number
  $animationDuration: number
  $left: number
}

export const Container = styled.section`
  height: 100vh;
  position: relative;

  svg {
    height: 100%;
  }
`
export const Note = styled.div<SliderNote>`
  width: ${FINGERBOARD_NOTE_WIDTH}px;
  height: 0;
  top: 0;
  text-align: center;
  font-size: 30px;
  color: #14e7f8;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  border-radius: 10px;
  background: #1f73f0;
  animation: ${props => props.$animationDuration}s linear paused;
  animation-name: ${({ $string }) => STRING_ANIMATION_NAMES[$string as SliderStringKey]};
  animation-delay: ${props => props.$animationDelay}s;
  will-change: transform;
  left: ${({ $left }) => `${$left}px`};

  @media (max-height: 700px) {
    transform: scaleX(0.8);
  }
`
export const NoteDuration = styled.div<{ $height: number }>`
  width: ${FINGERBOARD_NOTE_WIDTH}px;
  height: ${({ $height }) => $height}px;
  bottom: 50%;
  display: inline-block;
  position: absolute;
  border-radius: 10px;
  background: #1f73f0;
  z-index: -1;

  @media (max-height: 700px) {
    transform: scaleX(0.8);
  }
`
export const NoteName = styled.span`
  margin-bottom: 10px;
`
// export const FingerNumber = styled.sub`
//   color: rgba(255, 255, 255, 0.5);
//   font-size: 20px;
//   right: 6px;
//   bottom: 2px;
//   position: absolute;
// `

export const FingerboardLineSvg = styled(FingerboardTriggerLine)`
  top: 0;
  left: 0;
  position: absolute;
`
