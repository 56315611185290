import { useLayoutEffect, useState } from 'react'

import { useWindowSize } from 'hooks'
import { BREAKPOINTS } from 'styles'

const { values: breakpointDictionary } = BREAKPOINTS.breakpoints
const { xs, sm, md, lg, xl } = breakpointDictionary

type Breakpoint = keyof typeof breakpointDictionary

export const useBreakpoint = () => {
  const { width, height } = useWindowSize()

  const getBreakpoint = () => {
    let bk: Breakpoint

    if (!width) return

    switch (true) {
      case width < xs:
        bk = 'xs'
        break
      case width < sm:
        bk = 'sm'
        break
      case width < md:
        bk = 'md'
        break
      case width < lg:
        bk = 'lg'
        break
      case width < xl:
        bk = 'xl'
        break
      default:
        bk = 'xl'
    }

    return bk
  }

  const [breakpoint, setBreakpoint] = useState<Potential<Breakpoint>>()

  useLayoutEffect(() => {
    if (!width) return

    setBreakpoint(getBreakpoint())
  }, [width])

  const upTo = (breakpointName: Breakpoint) => {
    return breakpointDictionary[breakpoint!] < breakpointDictionary[breakpointName]
  }

  const downTo = (breakpointName: Breakpoint) => {
    return breakpointDictionary[breakpoint!] > breakpointDictionary[breakpointName]
  }

  return {
    breakpoint,
    upTo,
    downTo,
    width,
    height,
  }
}
