import { Rest, TempoManager } from '..'
import { getElementsDuration } from '../../helpers'

import { ElementReplacerOld } from '.'

export class SmallerDurationReplacer extends ElementReplacerOld {
  static replace(measure) {
    const { finalBar, nextElements, newElements } = measure
    const newElementDuration = getElementsDuration(newElements)
    const selectedDuration = getElementsDuration(measure.selectedElements)
    const emptyBeats = selectedDuration - newElementDuration
    const emptyTempos = TempoManager.findAvailableTempos(emptyBeats)
    const rests = emptyTempos.map(emptyTempo => Rest.createfromDuration(emptyTempo))

    return [...newElements, ...rests, ...nextElements, finalBar]
  }
}
