import { Measure, TempoManager } from '..'
import { AbcComposition, AbcNotation } from '../types'

import { ElementReplacer, ElementReplacerConstructor } from '.'

export class LargerDurationElementReplacer extends ElementReplacer {
  constructor(args: ElementReplacerConstructor) {
    super(args)
    this._measures = Measure.for(Measure.measures)
  }

  buildFromDuration(): AbcComposition {
    const { measures } = this
    const newElementsAbc = measures.map(measure => measure.abcNotation()).join('') as AbcNotation
    const composition = `${newElementsAbc}${this.rests().join('')}` as AbcNotation

    return [
      composition,
      {
        startChar: this.startChar,
        endChar: this.lastMeasure.endChar(),
        lastElementEndChar: this.lastMeasure.lastElementEndChar(),
      },
    ]
  }

  // private

  rests() {
    const restBeats = this.restBeats()
    return TempoManager.getRestsFromBeats(restBeats)
  }

  restBeats() {
    return this.lastMeasure.restBeats()
  }

  get lastMeasure() {
    return this.measures.at(-1)!
  }
}
