import styled, { css } from 'styled-components'

type StyledTextFieldProps = {
  $small?: boolean
}

export const Container = styled.div<StyledTextFieldProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.palette.colors.white};
  }

  ${({ $small }) =>
    $small &&
    css`
      & > div {
        width: 5px;
        height: 5px;
      }
    `}
`
