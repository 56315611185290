import { AddCircleOutlined as AddCircleIcon, SaveOutlined as SaveIcon } from '@mui/icons-material'
import { Box, IconButton, List, Tooltip, Typography } from '@mui/material'
import { createPortal } from 'react-dom'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'

import { EditableTextField } from 'components/ui'

import { ScoreSearch } from '..'
import { ScoreSearchProvider, useScoreSearchContext } from '../ScoreSearch'
import { TeacherCourseModuleFields } from '../TeacherCourseModuleFields/TeacherCourseModuleFields'
import { TEACHER_COURSE_INITIAL_VALUES, TeacherCourseFormValues } from '../teacherCourseSchema'

// import { SearchContainer } from './styles'

type Props = {
  form: UseFormReturn<TeacherCourseFormValues>
  handleSaveCourse: (data: TeacherCourseFormValues) => void
}

const TeacherCourseFormFC = ({ form, handleSaveCourse }: Props) => {
  const { control, handleSubmit } = form
  const { isSearchActive } = useScoreSearchContext()

  const {
    fields: moduleFields,
    append,
    update,
  } = useFieldArray({
    control,
    name: 'courseModules',
    keyName: 'rhfId',
  })

  const handleAddModule = () => {
    append(TEACHER_COURSE_INITIAL_VALUES.courseModules)
  }

  const handleDeleteModule = (moduleIndex: number) => {
    update(moduleIndex, {
      ...moduleFields[moduleIndex],
      _destroy: true,
    })
  }

  return (
    <Box component="form" display="flex" flexDirection="column" onSubmit={handleSubmit(handleSaveCourse)}>
      <Typography variant="h5" component="div" align="center" gutterBottom>
        <Controller
          name="title"
          control={control}
          render={({ field }) => <EditableTextField inputProps={field} />}
        />
      </Typography>

      <Typography variant="body1" component="div" gutterBottom>
        <Controller
          name="description"
          control={control}
          render={({ field }) => <EditableTextField inputProps={field} />}
        />
      </Typography>

      <Box display="flex" gap="8px" alignSelf="flex-end">
        <Tooltip title="Add Module" placement="top">
          <IconButton size="small" color="primary" sx={{ alignSelf: 'flex-end' }} onClick={handleAddModule}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Save Course" placement="top">
          <IconButton size="small" color="primary" type="submit">
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <List>
        {moduleFields.map((courseModule, moduleIndex) =>
          !courseModule._destroy ? (
            <TeacherCourseModuleFields
              key={courseModule.rhfId}
              courseModule={courseModule}
              form={form}
              moduleIndex={moduleIndex}
              handleDeleteModule={handleDeleteModule}
              defaultExpanded
            />
          ) : (
            <span key={courseModule.id} />
          ),
        )}
      </List>

      {isSearchActive && createPortal(<ScoreSearch />, document.body)}
    </Box>
  )
}

export const TeacherCourseForm = (props: Props) => {
  return (
    <ScoreSearchProvider>
      <TeacherCourseFormFC {...props} />
    </ScoreSearchProvider>
  )
}
