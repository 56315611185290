import { ResetPasswordFormValues } from '..'

export const validateToken = (search: string, data: ResetPasswordFormValues) => {
  const tokenRegex = /(?<query>^\?token=)(?<token>.*)/
  const { query, token } = search.match(tokenRegex)?.groups || {}
  const isValid = query && token

  if (isValid) data.reset_password_token = token

  return isValid
}
