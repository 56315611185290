import errorBipWav from 'assets/sounds/error-bip.wav'

const audio = new Audio()
audio.volume = 0.05

export class SoundManager {
  static play(sound: AppSound) {
    if (!audio.src.includes(SOUNDS[sound])) audio.src = SOUNDS[sound]
    audio.play()
    return null
  }
}

const SOUNDS = {
  error: errorBipWav,
} as const

type AppSound = keyof typeof SOUNDS
