import { AUTHORIZATION_KEY, CURRENT_USER_KEY } from 'hooks'
import { isEmpty } from 'utils'

const useAuthToken = () => {
  const token = localStorage.getItem(AUTHORIZATION_KEY)
  const loggedInUser = JSON.parse(localStorage.getItem(CURRENT_USER_KEY))

  if (!token || isEmpty(loggedInUser)) return {}

  const currentUser = JSON.parse(window.atob(token.split('.')[1]))

  return { token, currentUser }
}

export default useAuthToken
