import { combineReducers } from 'redux'

import authReducer from 'store/auth/reducer'
import composerScoresReducer from 'store/composer/reducer'
import composerV2ScoresReducer from 'store/composerV2/reducer'
import customScoresReducer from 'store/customScores/reducer'
import favoriteScoresReducer from 'store/favoriteScores/reducer'
import foldersReducer from 'store/folders/reducer'
import globalReducer from 'store/global/reducer'

const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  customScores: customScoresReducer,
  folders: foldersReducer,
  favoriteScores: favoriteScoresReducer,
  composer: composerScoresReducer,
  composerV2: composerV2ScoresReducer,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
