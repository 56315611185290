import { useEffect, useRef } from 'react'

import { BoxProps } from '@mui/material'
import bodymovin from 'bodymovin'

import { Container } from './styles'

type Props = {
  fullScreen?: boolean
  fullWidth?: boolean
  color?: string
} & BoxProps

export const Loader = ({ fullScreen, fullWidth, color, ...rest }: Props) => {
  const containerRef = useRef<Element>(null)

  useEffect(() => {
    if (!containerRef.current || animationSettings.container === containerRef?.current) return

    animationSettings.container = containerRef.current
    const animation = bodymovin.loadAnimation(animationSettings)
    animation.setSpeed(1)

    return animation.addEventListener('complete', () => {})
  }, [])

  return (
    <Container
      ref={containerRef}
      $fullScreen={fullScreen}
      $fullWidth={fullWidth}
      $color={color}
      className="play-loader"
      {...rest}
    />
  )
}

const animationSettings = {
  animType: 'svg',
  loop: true,
  prerender: true,
  autoplay: true,
  path: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/35984/play_fill_loader.json',
  container: {} as Element,
}
