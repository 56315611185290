import { EMPTY_STAFF } from 'utils'

import { Measure } from './Measure'
import { MusicalElement } from './MusicalElement'
import { Staff } from './Staff'

export class Composer {
  constructor() {
    this.init()
  }

  addElement({ value, type }) {
    const { settings, tune, selectedAbc } = this
    const params = { tune, value, type, selectedAbc, settings }
    const element = this.currentMeasure.addElement(params)

    if (!element) return

    this.rebuildAbc()
  }

  deleteElement() {
    const { selectedAbc, header } = this
    this.currentMeasure.deleteElement(selectedAbc)
    this.tune = header + this.toAbc()
    this.selectedAbc = { ...selectedAbc }
  }

  updateHeader({ tune, name, value }) {
    const key = this.dynamicSettings[name]
    if (!key) return tune

    const regex = new RegExp(`${key}:(.*)`)
    return tune.replace(regex, `${key}: ${value}`)
  }

  dragNote({ step }) {
    const { line, measure, note: noteIndex } = this.selectedAbc.start
    const note = this.staffs[line].measures[measure].notes[noteIndex]

    const newNote = step === 1 ? note.next() : note.previous()
    if (!newNote) return

    this.staffs[line].measures[measure].notes[noteIndex] = newNote
    this.tune = this.header + this.toAbc()
  }

  setSelected(selectedAbc) {
    this.selectedAbc = selectedAbc
  }

  setSettings(settings) {
    this.settings = settings
  }

  addStaff() {
    const { header } = this

    const newStaff = new Staff()
    this.staffs.push(newStaff)
    this.tune = header + this.toAbc()

    return newStaff
  }

  setTune(tune) {
    this.tune = tune
  }

  toAbc() {
    return this.staffs.map(staff => staff.toAbc()).join('\n')
  }

  toJson() {
    return JSON.stringify(this.staffs)
  }

  initFromTune(json) {
    this.staffs = Composer.buildFromJson(json)
  }

  rebuildAbc() {
    const { tune } = this
    const header = tune.substr(0, headerEndChar(tune))
    this.tune = header + this.toAbc()
  }

  init() {
    this.dynamicSettings = { key: 'K', time: 'M' }
    this.tune = INITIAL_TUNE
    this.settings = INITIAL_SETTINGS
    this.selectedAbc = INITIAL_SELECTED_ABC
    this.staffs = [new Staff(), new Staff(), new Staff(), new Staff()]
  }

  reset() {
    this.init()
  }

  static buildFromJson(json) {
    return JSON.parse(json).map(staff => {
      const newStaff = new Staff()

      newStaff.measures = staff.measures.map(measure => {
        const newMeasure = new Measure()
        newMeasure.notes = measure.notes.map(element => MusicalElement.for(element))

        return newMeasure
      })
      return newStaff
    })
  }

  get currentMeasure() {
    const { line, measure } = this.selectedAbc.start
    const staff = this.staffs[line]
    return staff.measures[measure]
  }

  get header() {
    return this.tune.substr(0, headerEndChar(this.tune))
  }

  get measures() {
    return this.staffs.map(staff => staff.measures).flat()
  }
}

export const LAST_HEADER_LINE = '%%stretchlast\n'
const headerEndChar = abc => abc.split(LAST_HEADER_LINE)[0].length + LAST_HEADER_LINE.length

export const INITIAL_SETTINGS = {
  key: 'C',
  time: '4/4',
  octave: '4',
  duration: '2',
  accidental: '',
  bpm: 120,
  dot: null,
  annotation: '',
}

export const INITIAL_TUNE = `X: 1
  T:
  C:
  L: 1/8
  M: ${INITIAL_SETTINGS.time}
  K: ${INITIAL_SETTINGS.key}
  Q: 120
  %%partsspace 30
  %%composerfont "Times New Roman" 12 italic
  %%rightmargin 0
  %%MIDI program 40
  %%stretchlast
  ${EMPTY_STAFF}
  ${EMPTY_STAFF}
  ${EMPTY_STAFF}
  ${EMPTY_STAFF}
  `.replace(/  +/g, '')

const REST_ABC = {
  startChar: headerEndChar(INITIAL_TUNE),
  endChar: headerEndChar(INITIAL_TUNE) + 2,
  type: 'rest',
  line: 0,
  measure: 0,
  note: 0,
}

export const INITIAL_SELECTED_ABC = { start: REST_ABC, end: REST_ABC }
