import initialState from './state'

const favoriteScoresReducer = (state = initialState.favoriteScores, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default favoriteScoresReducer
