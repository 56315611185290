import { AbcNotation, MusicalElementSettings } from 'components/Composer/models/types'

import { MusicalElement } from '..'
import { Maestro } from '../..'

import {
  AccidentalDecorator,
  ArticulationDecorator,
  MusicalElementSettingDecorator,
  OctaveDecorator,
  SlurDecorator,
  TimeSignatureDecorator,
} from '.'

export type MusicalElementDecoratorConstructor = {
  settings: MusicalElementSettings
  maestro: Maestro
  element: MusicalElement
  index?: Potential<number>
}

export type DecoratorConstructor = {
  new ({ settings, maestro, element, index }: MusicalElementDecoratorConstructor): MusicalElementDecorator
}

export abstract class MusicalElementDecorator {
  musicalElement: MusicalElement
  maestro: Maestro
  settings: MusicalElementSettings
  protected index?: Potential<number>

  constructor({ settings, maestro, element, index }: MusicalElementDecoratorConstructor) {
    this.settings = settings
    this.maestro = maestro
    this.musicalElement = element
    this.index = index
  }

  abstract get abcNotation(): AbcNotation

  get duration() {
    return this.musicalElement.duration
  }

  protected static selectedElementsSettings(maestro: Maestro) {
    const selectedElements = maestro.getElementsFromTextareaSelection()
    return MusicalElement.convertAbcjsToElementsSettings(selectedElements)
  }

  protected static buildMusicalElements(maestro: Maestro): MusicalElement[] {
    return MusicalElementDecorator.selectedElementsSettings(maestro)
      .map(settings => MusicalElement.for(settings, maestro))
      .flat()
  }

  static for(...args: ConstructorParameters<typeof MusicalElement>): MusicalElementDecorator[] {
    const [settings] = args
    let decorator
    switch (settings.type) {
      case 'articulation':
        switch (settings.label) {
          case 'slur':
            return SlurDecorator.decorateMusicalElements(SlurDecorator, ...args)

          default:
            decorator = ArticulationDecorator
        }
        break

      case 'accidental':
        decorator = AccidentalDecorator
        break
      case 'octave':
        decorator = OctaveDecorator
        break
      case 'timeSignature':
        return TimeSignatureDecorator.decorateMusicalElements(TimeSignatureDecorator, ...args)

      default:
        decorator = MusicalElementSettingDecorator
    }

    return MusicalElementDecorator.decorateMusicalElements(decorator, ...args)
  }

  static decorateMusicalElements(
    Decorator: DecoratorConstructor,
    ...args: ConstructorParameters<typeof MusicalElement>
  ): MusicalElementDecorator[] {
    const [settings, maestro] = args
    return this.buildMusicalElements(maestro).map(element => new Decorator({ settings, maestro, element }))
  }
}
