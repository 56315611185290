import { AbcNotation } from 'components/Composer/models/types'

import { MusicalElement } from '..'
import { Maestro } from '../..'

import { MusicalElementSettingDecorator } from './MusicalElementSettingDecorator'

import { DecoratorConstructor } from '.'

export class TimeSignatureDecorator extends MusicalElementSettingDecorator {
  get abcNotation(): AbcNotation {
    return this.musicalElement.abcNotation
  }

  protected static selectedElementsSettings(maestro: Maestro) {
    const { startChar } = maestro.selectableElements[0].elem.abcelem
    const { endChar } = maestro.selectableElements.at(-1)!.elem.abcelem
    maestro.selectElementsInRange(startChar, endChar)

    const selectedElements = maestro.getElementsFromTextareaSelection()
    return MusicalElement.convertAbcjsToElementsSettings(selectedElements)
  }

  static buildMusicalElements(maestro: Maestro): MusicalElement[] {
    return TimeSignatureDecorator.selectedElementsSettings(maestro)
      .map(settings => MusicalElement.for(settings, maestro))
      .flat()
  }

  static decorateMusicalElements(
    Decorator: DecoratorConstructor,
    ...args: ConstructorParameters<typeof MusicalElement>
  ) {
    const [, maestro] = args
    return this.buildMusicalElements(maestro).map(
      element => new Decorator({ settings: element.settings, maestro, element }),
    )
  }
}
