import { useEffect } from 'react'

import { BoxProps } from '@mui/material'

import { useBreakpoint } from 'hooks'
import { clamp, toDecimalRound } from 'utils'

import { Container, DisplayContainer, FrequencyDisplay, NoteDisplay, Pointer } from './styles'
import { useTuner } from './useTuner'
import { getPointerAngle } from './utils'

const DEFAULT_TUNER_DATA = {
  note: 'A',
  frequency: '440.0',
}

type Props = {
  active: boolean
  hidePointer?: boolean
  fontSize?: number
} & BoxProps

export const Tuner = ({ active, hidePointer, fontSize = 48, ...rest }: Props) => {
  const { activate, deactivate, tuner } = useTuner()
  const { upTo } = useBreakpoint()

  const note = tuner.note || DEFAULT_TUNER_DATA.note
  const formattedFrequency = toDecimalRound(tuner.frequency, 1) || DEFAULT_TUNER_DATA.frequency

  const mobileFactor = upTo('lg') ? 0.3 : 1
  const pointerAngle = clamp(getPointerAngle(tuner), -90, 90) * mobileFactor

  useEffect(() => {
    if (!active) return

    activate()
    return () => deactivate()
  }, [active, activate, deactivate])

  return (
    <Container
      $angle={pointerAngle}
      $active={!!tuner.on}
      $hidePointer={!!hidePointer}
      fontSize={fontSize}
      {...rest}
    >
      <DisplayContainer $active={!!tuner.on} $hidePointer={!!hidePointer}>
        <NoteDisplay $angle={pointerAngle} $active={!!tuner.on}>
          {note}
        </NoteDisplay>
        <FrequencyDisplay $active={!!tuner.on}>{formattedFrequency} Hz</FrequencyDisplay>
      </DisplayContainer>

      {!hidePointer && <Pointer $angle={pointerAngle} $active={!!tuner.on} />}
    </Container>
  )
}
