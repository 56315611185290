import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  overflow-y: hidden;
  position: relative;
  justify-content: space-around;

  &:focus {
    outline: none;
  }
`
