import { Measure } from './Measure'

export class Staff {
  constructor() {
    this.measures = [new Measure(), new Measure(), new Measure(), new Measure()]
  }

  toAbc() {
    return this.measures.map(measure => measure.toAbc()).join('')
  }

  static isEmpty() {}
}
