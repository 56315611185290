import { Box, BoxProps } from '@mui/material'
import styled, { css } from 'styled-components'

import { variables } from 'styles/variables'

type Props = {
  $fullScreen?: boolean
  $fullWidth?: boolean
  $color?: string
} & BoxProps

export const Container = styled(Box)<Props>`
  top: 0;
  left: 0;
  flex: 1;
  margin: auto;
  display: flex;
  position: absolute;
  background: ${({ theme }) => theme.palette.background.default};

  svg {
    height: 50% !important;
    margin: auto;
  }

  *[fill='rgb(247,70,62)'] {
    fill: ${({ $color, theme }) => $color || theme.palette.primary.main};
  }

  *[stroke='rgb(246,70,62)'] {
    stroke: ${({ $color, theme }) => $color || theme.palette.primary.main};
  }

  *[opacity='0.06'] {
    opacity: 0.15;
  }

  ${({ $fullScreen }) =>
    $fullScreen &&
    css`
      width: 100vw;
      height: 100vh;
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100vw;
      height: calc(100vh - ${variables.topbarHeight}px);
      top: ${variables.topbarHeight}px;
    `}
`
