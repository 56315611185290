import { useEffect } from 'react'

import { Capacitor } from '@capacitor/core'
import { useLocation } from 'react-router-dom'

import { useKeepAwake } from 'hooks'

const KEEP_AWAKE_ROUTES = ['/sheet-music', '/tuner']

export const useMobileSettings = () => {
  const location = useLocation()
  const currentPath = location.pathname

  const { keepAwake, allowSleep } = useKeepAwake()

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    const isKeepAwake = KEEP_AWAKE_ROUTES.some(route => currentPath.startsWith(route))

    if (!isKeepAwake) return

    keepAwake()
    return allowSleep
  }, [currentPath])

  return {}
}
