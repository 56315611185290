/* eslint-disable */
import { Component } from 'react'

import { connect } from 'react-redux'

import { AppError } from 'pages/errors'

class ErrorBoundaryComponent extends Component {
  constructor() {
    super()
    this.state = { ...this.state, componentError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ componentError: true })
  }

  render() {
    const { componentError } = this.state
    const { children, appError } = this.props

    if (appError || componentError) return <AppError />

    return children
  }
}

function mapStateToProps(state) {
  return { appError: state.global.error }
}

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent)
