import { useApi } from 'hooks'
import { CourseLecture } from 'models'
import { CourseLectureEndpoint } from 'routes/locations'

export const useCourseLectureController = (courseLectureId: Id) => {
  const [fetchCourseLecture, courseLecture] = useApi<CourseLecture>(
    CourseLectureEndpoint,
    'GET',
    { courseLectureId },
    CourseLecture,
  )

  return { fetchCourseLecture, courseLecture }
}
