import { useEffect } from 'react'

import { Box, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { useApi } from 'hooks'
import { User } from 'models'
import { useCurrentUserContext, useToastContext } from 'providers'
import { ConfirmEmailEndpoint } from 'routes/locations'

export const ConfirmEmail = () => {
  const [confirmEmail, userWithConfirmedEmail, { error, status }] = useApi<User>(ConfirmEmailEndpoint)
  const { showToast } = useToastContext()
  const { setCurrentUser } = useCurrentUserContext()
  const [searchParams] = useSearchParams()
  const confirmationToken = searchParams.get('confirmation_token')

  useEffect(() => {
    confirmEmail({}, { confirmation_token: confirmationToken })
  }, [])

  useEffect(() => {
    if (status !== 200 || !userWithConfirmedEmail) return

    setCurrentUser(userWithConfirmedEmail)

    showToast({
      type: 'success',
      title: 'Your email has been successfully confirmed.',
      description: 'Your account is now fully active.',
    })
  }, [status, userWithConfirmedEmail, showToast, setCurrentUser])

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: 'Email confirmation failed!',
      description: error.message,
    })
  }, [error, showToast])

  return (
    <Box>
      <Typography variant="body1" color="text.secondary" textAlign="center" mt={8}>
        {!status && 'Your email will be confirmed in a moment...'}
        {status === 200 && 'Your email has been successfully confirmed.'}
        {status >= 400 && 'Email confirmation failed!'}
      </Typography>
    </Box>
  )
}
