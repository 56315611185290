import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

import { fadeSlideAnimation } from 'styles/keyframes'
import { variables } from 'styles/variables'

type ContainerProps = {
  $gutterTop?: boolean
  $fullWidth?: boolean
}
type HeaderProps = {
  $gutterBottom?: boolean
}

export const Container = styled(Box)<ContainerProps>`
  display: flex;
  min-height: calc(100vh - ${variables.topbarHeight}px);

  ${({ $gutterTop }) =>
    $gutterTop &&
    css`
      padding-top: 32px;
    `}

  > header {
    h2 {
      padding-bottom: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};
      animation: ${() => fadeSlideAnimation()} 0.5s;
    }
  }

  ${({ $fullWidth }) =>
    !$fullWidth &&
    css`
      width: 80%;
      max-width: ${variables.containerWidth}px;
      margin: 0 auto;

      @media (max-width: 960px) {
        width: 90%;
      }
    `}
`
export const HeaderBox = styled(Box)<HeaderProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  ${({ $gutterBottom }) =>
    $gutterBottom &&
    css`
      margin-bottom: 32px;
    `}
`
