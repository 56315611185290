import { maestro } from 'components/Composer/hooks'
import { ElementDuration } from 'components/Composer/models/types'

import { Bar } from '..'
import { GeneralMusicalElementDecorator } from '../MusicalElement/MusicalElementsDecorator'
import { MusicalElementWithDuration } from '../types'

import { HeadMeasure, TailMeasure } from '.'

type MusicalElementsByMeasure = MusicalElementWithDuration[][]

export type ElementsByMeasure = (typeof Measure)['measures']

export class Measure {
  measureSettings: ElementsByMeasure

  constructor(args: Partial<Measure>) {
    this.measureSettings = args.measureSettings!
  }

  static for(measureSettings: ElementsByMeasure) {
    if (measureSettings.length === 1) {
      return [new HeadMeasure({ measureSettings })]
    }
    const headMeasure = new HeadMeasure({ measureSettings })
    return [headMeasure, new TailMeasure({ measureSettings, headMeasure })]
  }

  static get measures() {
    return this.getElementsByMeasure(maestro.newElements)
  }

  // private

  static getElementsByMeasure(elements: MusicalElementWithDuration[]) {
    let elementsDurationCounter = this.headMeasureElementsDuration
    let measureElements: MusicalElementWithDuration[] = []
    const { totalMeasureBeats } = maestro
    const { singleBar } = Measure

    return elements.reduce((acc: MusicalElementsByMeasure, newElement, index) => {
      const isLastElement = elements.length - 1 === index
      const availableBeatsInMeasure = totalMeasureBeats - elementsDurationCounter
      const hasAvailableBeatsInMeasure = elementsDurationCounter < totalMeasureBeats
      const hasAvailableBeatsInMeasureForNewElement = availableBeatsInMeasure >= newElement.duration
      const shouldSplitElement = hasAvailableBeatsInMeasure && !hasAvailableBeatsInMeasureForNewElement
      const canReplace = hasAvailableBeatsInMeasure && hasAvailableBeatsInMeasureForNewElement

      if (canReplace) {
        measureElements.push(newElement)
        elementsDurationCounter += newElement.duration
      } else if (shouldSplitElement) {
        const [newElementForAvailableBeats, newElementForNextMeasure] =
          this.splitElementToFitMultipleMeasures(newElement, availableBeatsInMeasure)
        measureElements.push(newElementForAvailableBeats, singleBar)
        acc.push(measureElements)
        measureElements = [newElementForNextMeasure]
        elementsDurationCounter = newElementForNextMeasure.duration
      } else {
        measureElements.push(singleBar)
        acc.push(measureElements)
        measureElements = [newElement]
        elementsDurationCounter = 0
        elementsDurationCounter += newElement.duration
      }

      if (isLastElement) acc.push(measureElements)
      return acc
    }, [])
  }

  static splitElementToFitMultipleMeasures(
    newElement: MusicalElementWithDuration,
    availableBeatsInMeasure: ElementDuration,
  ) {
    const missingNewElementsBeats = newElement.duration - availableBeatsInMeasure
    const newElementForAvailableBeats = GeneralMusicalElementDecorator.for(
      { ...newElement.settings, duration: availableBeatsInMeasure },
      maestro,
    )[0]
    const newElementForNextMeasure = GeneralMusicalElementDecorator.for(
      { ...newElement.settings, duration: missingNewElementsBeats },
      maestro,
    )[0]

    return [newElementForAvailableBeats, newElementForNextMeasure]
  }

  static get headMeasureElementsDuration() {
    return HeadMeasure.measureElementsBeforeAndAfterSelectionDuration(maestro)
  }

  private static get singleBar() {
    return new Bar({ value: '|', duration: 0 }, maestro)
  }
}
