import { IconButton as MaterialIconButton } from '@mui/material'
import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.aside<{ $visible: boolean }>`
  left: 0;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
  margin: 2px;
  transition: transform 0.3s ease;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: relative;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: absolute;
    transform: translateX(${({ $visible }) => ($visible ? 0 : '-100%')});
    background: ${({ theme }) => transparentize(0.2, theme.palette.background.default)};
  }
`
export const IconButton = styled(MaterialIconButton)`
  width: 50px;
  height: 50px;
`

export const AudioControls = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
