import { Button as MaterialButton, Chip as MaterialChip } from '@mui/material'
import { FiMoon } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { variables } from 'styles/variables'

export const Container = styled.header`
  top: 0;
  height: 70px;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  box-shadow: ${({ theme }) => theme.palette.shadows.A700};
  background: ${({ theme: { palette } }) =>
    palette.mode === 'light' ? palette.primary.main : palette.background.default};
  background: ${({ theme: { palette } }) => palette.components.PaTopbar.main};
  z-index: 1000;

  ul a {
    color: ${({ theme }) => theme.palette.colors.white};

    &:hover {
      color: ${({ theme }) => theme.palette.components.PaIcon.hover.main};
    }

    h1 {
      font-family: Montserrat, sans-serif;
      font-weight: 300;
    }
  }

  @media (max-width: 450px) {
    padding: 0 24px;

    a h1 {
      font-size: 22px;
    }
  }
`
export const Nav = styled.nav`
  display: flex;

  ul {
    display: flex;
    align-items: center;
  }

  li {
    & + li {
      margin-left: 24px;
    }

    button {
      padding: 5px;
      color: ${({ theme }) => theme.palette.colors.white};
      background: transparent;
    }
  }
`
export const MoonIcon = styled(FiMoon)`
  transition:
    fill 0.1s,
    stroke 0.1s;

  &:hover {
    stroke: ${({ theme }) => theme.palette.components.PaIcon.hover.main};
    fill: ${({ theme }) =>
      theme.palette.mode === 'light' ? theme.palette.transparent.main : theme.palette.primary.default};
  }
`
export const LinkWithLabel = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 4px;
    margin-left: -8px;
  }
`
export const Chip = styled(MaterialChip)`
  &.MuiChip-root {
    max-width: unset;
    color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
    font-size: 9px;
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);

    &.MuiChip-sizeSmall {
      height: 14px;
    }
  }
`
export const Logo = styled.img`
  height: ${variables.topbarHeight - 28}px;
  margin-left: -16px;
  margin-right: 16px;
`
export const LogoText = styled.img`
  height: ${variables.topbarHeight + 32}px;
`
export const LinkToLogo = styled(Link)`
  display: flex;
  align-items: center;
`
export const UnconfirmedEmailBanner = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background: ${({ theme }) => theme.palette.error.main};
`
export const Button = styled(MaterialButton)`
  width: 120px;
  margin-right: 40px;
  text-align: center;
  background-color: rgba(164, 18, 77, 0.08);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(164, 18, 77, 0.16);
  }
`
