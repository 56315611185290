import { useCallback, useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, InputLabel, MenuItem, Popover, Select, TextField, Theme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { FiSend, FiX } from 'react-icons/fi'
import { IoMegaphoneOutline } from 'react-icons/io5'
import * as Yup from 'yup'

import { useApi } from 'hooks'
import { Feedback } from 'models'
import { useToastContext } from 'providers'
import { FeedbacksEndpoint } from 'routes/locations/feedbacks'

import { CloseButton, Container, Form, IconButton, popoverStyles, Rating, SubmitButton } from './styles'

const schema = Yup.object().shape({
  rating: Yup.number().required().min(1).max(5),
  kind: Yup.string().required(),
  name: Yup.string().required(),
  message: Yup.string().required(),
  email: Yup.string().required().email().label('Email'),
})

type FeedbackerFormValues = Yup.InferType<typeof schema>

export const Feedbacker = () => {
  const trigger = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const { showToast } = useToastContext()
  const [postFeedback, , { status: statusPostFeedback }] = useApi<Feedback>(FeedbacksEndpoint, 'POST')

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FeedbackerFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      rating: 0,
      kind: '',
      name: '',
      email: '',
      message: '',
    },
  })

  useEffect(() => {
    if (!statusPostFeedback) return

    if (statusPostFeedback === 201) {
      showToast({
        type: 'success',
        title: 'Feedback sent successfully!',
        description: 'Thank you for your feedback!',
      })
      setOpen(false)
      reset()
    } else {
      showToast({
        type: 'error',
        title: 'Feedback could not be sent!',
        description: 'An error occurred. Please, try again.',
      })
    }
  }, [statusPostFeedback, showToast, reset])

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onSubmit = useCallback(
    (data: FeedbackerFormValues) => {
      postFeedback({ ...data })
    },
    [postFeedback],
  )

  return (
    <Container>
      <IconButton ref={trigger} onClick={handleClick}>
        <IoMegaphoneOutline />
      </IconButton>

      <Popover
        open={open}
        anchorEl={trigger.current}
        onClose={handleClose}
        transformOrigin={{ vertical: 375, horizontal: 360 }}
        slotProps={{
          paper: {
            style: {
              ...popoverStyles,
              color: `${({ theme }: { theme: Theme }) => theme.palette.text.primary}`,
              background: `${({ theme }: { theme: Theme }) => theme.palette.background.paper}`,
              backgroundImage: 'unset',
            },
          },
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h3>Share your thoughts!</h3>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            flex={1}
          >
            <Controller
              name="rating"
              control={control}
              render={({ field }) => <Rating error={!!errors.rating} {...field} />}
            />

            <FormControl fullWidth>
              <InputLabel size="small" error={!!errors.kind}>
                Feedback
              </InputLabel>
              <Controller
                name="kind"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Feedback"
                    placeholder="Feedback"
                    error={!!errors.kind}
                    size="small"
                    inputProps={{ size: 'small' }}
                    {...field}
                  >
                    <MenuItem value="suggestion">Suggestion</MenuItem>
                    <MenuItem value="compliment">Compliment</MenuItem>
                    <MenuItem value="critique">Critique</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <Box display="flex" gap={1}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    error={!!errors.name}
                    size="small"
                    {...field}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    variant="outlined"
                    error={!!errors.email}
                    size="small"
                    {...field}
                  />
                )}
              />
            </Box>

            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Message"
                  variant="outlined"
                  error={!!errors.message}
                  rows={4}
                  multiline
                  fullWidth
                  {...field}
                />
              )}
            />

            <SubmitButton type="submit">
              <FiSend />
            </SubmitButton>

            <CloseButton size="small" onClick={handleClose}>
              <FiX />
            </CloseButton>
          </Box>
        </Form>
      </Popover>
    </Container>
  )
}
