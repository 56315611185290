import { ReactNode } from 'react'

import { useParams } from 'react-router-dom'

import { useFavoriteScoreController } from 'controllers'

import { createCtx } from '../utils'

type Props = {
  children: ReactNode
}

type FavoriteScoreContext = ReturnType<typeof useFavoriteScoreController>

export const [useFavoriteScoreContext, FavoriteScoreContextProvider] = createCtx<FavoriteScoreContext>()

export const FavoriteScoreProvider = ({ children }: Props) => {
  const { scoreId } = useParams()

  return (
    <FavoriteScoreContextProvider value={useFavoriteScoreController(scoreId!)}>
      {children}
    </FavoriteScoreContextProvider>
  )
}
