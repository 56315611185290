import { AudioPlayer } from '../AudioPlayer'

export abstract class AudioPlayerState {
  protected player: AudioPlayer

  constructor(player: AudioPlayer) {
    this.player = player

    this.handlePlay = this.handlePlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
  }

  abstract handlePlay(): void
  abstract handleStop(): void
  abstract handleFinish(): void
}
