export const FETCH_FOLDER_START = 'FETCH_FOLDER_START'
export const FETCH_FOLDER_SUCCESS = 'FETCH_FOLDER_SUCCESS'
export const FETCH_FOLDER_ERROR = 'FETCH_FOLDER_ERROR'
export const FETCH_FOLDER_END = 'FETCH_FOLDER_END'

export const CREATE_FOLDER_START = 'CREATE_FOLDER_START'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR'
export const CREATE_FOLDER_END = 'CREATE_FOLDER_END'

export const DELETE_FOLDER_START = 'DELETE_FOLDER_START'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
export const DELETE_FOLDER_ERROR = 'DELETE_FOLDER_ERROR'
export const DELETE_FOLDER_END = 'DELETE_FOLDER_END'

export const UPDATE_FOLDER_START = 'UPDATE_FOLDER_START'
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS'
export const UPDATE_FOLDER_ERROR = 'UPDATE_FOLDER_ERROR'
export const UPDATE_FOLDER_END = 'UPDATE_FOLDER_END'

export const SET_SELECTED_FOLDER_START = 'SET_SELECTED_FOLDER_START'
export const SET_SELECTED_FOLDER_SUCCESS = 'SET_SELECTED_FOLDER_SUCCESS'
export const SET_SELECTED_FOLDER_ERROR = 'SET_SELECTED_FOLDER_ERROR'
export const SET_SELECTED_FOLDER_END = 'SET_SELECTED_FOLDER_END'

export const SET_FOLDER_TYPE = 'SET_FOLDER_TYPE'
export const RESET_FOLDERS = 'RESET_FOLDERS'
