import { useEffect } from 'react'

import { CancelOutlined, CheckCircleOutlined, ErrorOutline, InfoOutlined } from '@mui/icons-material'
import { SpringValue } from 'react-spring'

import { ToastMessage } from 'hooks'
import { useToastContext } from 'providers'

import { Content } from './styles'

const icons = {
  info: <InfoOutlined />,
  success: <CheckCircleOutlined />,
  error: <ErrorOutline />,
}

export type ToastMessageTransition = {
  right: SpringValue<string>
  opacity: SpringValue<number>
}

export type ToastContentProps = {
  style: ToastMessageTransition
  message: ToastMessage
}

export const ToastContent = ({
  message: { id, type = 'info', title, description },
  style,
}: ToastContentProps) => {
  const { hideToast } = useToastContext()

  useEffect(() => {
    const timer = setTimeout(() => hideToast(id), 5000)

    return () => clearTimeout(timer)
  }, [id, hideToast])

  return (
    <Content type={type} $hasDescription={Number(!!description)} style={style}>
      {icons[type]}

      <div>
        <strong>{title}</strong>
        <p>{description}</p>
      </div>

      <button type="button" onClick={() => hideToast(id)} aria-label="Close toast">
        <CancelOutlined fontSize="small" />
      </button>
    </Content>
  )
}
