import { AbcComposition, AbcNotation } from '../types'

import { ElementReplacer } from '.'

export class NoDurationElementReplacer extends ElementReplacer {
  buildFromDuration(): AbcComposition {
    const { newElements } = this.maestro
    const newElementsAbc = newElements.map(newElement => newElement.abcNotation).join('') as AbcNotation

    return [
      newElementsAbc,
      {
        startChar: this.startChar,
        endChar: this.endChar,
        lastElementEndChar: this.startChar + newElementsAbc.length,
      },
    ]
  }

  get endChar() {
    return this.maestro.selectedElements.at(-1)!.endChar
  }

  get startChar() {
    return this.maestro.selectedElements.at(0)!.startChar
  }
}
