import { ReactNode } from 'react'

import { useParams } from 'react-router-dom'

import { useCourseController } from 'controllers'

import { createCtx } from '../utils'

type Props = {
  children: ReactNode
}

type CourseContext = ReturnType<typeof useCourseController>

export const [useCourseContext, CourseContextProvider] = createCtx<CourseContext>()

export const CourseProvider = ({ children }: Props) => {
  const { courseId } = useParams()

  return <CourseContextProvider value={useCourseController(courseId!)}>{children}</CourseContextProvider>
}
