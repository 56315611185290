import { AudioPlayerState } from './AudioPlayerState'
import { PlayingState } from './PlayingState'

export class ReadyState extends AudioPlayerState {
  handlePlay() {
    this.player.changeState(new PlayingState(this.player))
    this.player.renderer.playButton.click()
  }

  handleStop() {
    return null
  }

  handleFinish() {
    return null
  }
}
