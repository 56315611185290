import { BrowserRouter } from 'react-router-dom'

import { Topbar } from 'components'
import { ErrorBoundary } from 'pages/errors'
import { AppProvider } from 'providers'
import { GlobalStyles } from 'styles/global'

import { Container } from './Container'

export const App = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <ErrorBoundary>
          <Topbar />
          <Container />
        </ErrorBoundary>

        <GlobalStyles />
      </AppProvider>
    </BrowserRouter>
  )
}
