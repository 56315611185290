import styled from 'styled-components'

import { animateErrorIcon, animateXLeft, animateXRight } from './keyframes'
import { containerStyles, Fix, iconStyles, placeholderStyles } from './sharedStyles'

const ErrorIcon = () => {
  return (
    <Container>
      <XMark className="fd-alert-icon-x-mark">
        <XMarkLeft className="fd-alert-icon__line fd-alert-icon__line--left animateXLeft" />
        <XMarkRight className="fd-alert-icon__line fd-alert-icon__line--right animateXRight" />
      </XMark>

      <Placeholder className="fd-alert-icon__placeholder" />
      <Fix className="fd-alert-icon__fix" />
    </Container>
  )
}

export default ErrorIcon

const Container = styled.div`
  ${containerStyles}

  border-color: ${({ theme }) => theme.palette.components.PaAlert.error.background};

  &::after {
    animation: ${animateErrorIcon} 0.5s;
  }
`
const XMark = styled.span`
  display: block;
  position: relative;
  z-index: 2;
`
const XMarkLeft = styled.span`
  ${iconStyles}

  top: 37px;
  left: 17px;
  width: 47px;
  background: ${({ theme }) => theme.palette.components.PaAlert.error.background};
  transform: rotate(45deg);
  animation: ${animateXLeft} 0.75s;
`
const XMarkRight = styled.span`
  ${iconStyles}

  top: 37px;
  right: 16px;
  width: 47px;
  transform: rotate(-45deg);
  background: ${({ theme }) => theme.palette.components.PaAlert.error.background};
  animation: ${animateXRight} 0.75s;
`
const Placeholder = styled.div`
  ${placeholderStyles}

  border: 4px solid ${({ theme }) => theme.palette.components.PaAlert.error.shadow};
`
