import { Divider as MaterialDivider, Tooltip as MaterialTooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { ScoreLevel } from 'models'
import { fadeSlideAnimation } from 'styles/keyframes'

export const Form = styled.form`
  max-width: 960px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;

  > div {
    width: 100%;
    opacity: 0;
    animation: ${() => fadeSlideAnimation()} 0.5s 0.25s forwards;
  }
`
export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  margin: 16px 0;
  position: relative;

  .dot-loader {
    width: 100%;
    margin: 100px auto;
  }

  .card-title,
  .card-composer {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-title {
    -webkit-line-clamp: 3;

    @media (max-width: 980px) {
      -webkit-line-clamp: 2;
    }
  }

  .card-composer {
    -webkit-line-clamp: 1;
    align-self: flex-end;
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`
export const LevelMarker = styled.span<{ $level: ScoreLevel }>`
  width: 8px;
  height: 8px;
  right: 30px;
  position: absolute;
  border-radius: 50%;
  background: ${({ theme, $level }) => theme.palette.levels[$level] || theme.palette.levels.hard};
`
export const Divider = styled(MaterialDivider)`
  &.MuiDivider-root {
    background: ${({ theme }) => theme.palette.primary.main};
  }
`
export const loaderStyles = {
  '&.MuiBox-root': {
    width: '100%',
    height: '600px',
  },
}
const tooltipStyles = {
  tooltip: {
    width: 140,
  },
  popper: {
    position: 'absolute' as const,
    transform: 'translate3d(60px, 72px, 0px) !important',
  },
}
export const Tooltip = withStyles(tooltipStyles)(MaterialTooltip)
