import { CourseLecture } from 'models'

import { LectureVideo } from './styles'

type Props = {
  courseLecture: CourseLecture
}

export const CourseLectureVideo = ({ courseLecture }: Props) => {
  if (!courseLecture.videoUrl) return

  return (
    <LectureVideo controls autoPlay>
      <source src={courseLecture.videoUrl} type="video/mp4" />
    </LectureVideo>
  )
}
