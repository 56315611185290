import { LoadingButtonProps } from '@mui/lab'

import { LoadingButton } from './styles'

export const Button = ({ children, type = 'button', variant = 'contained', ...rest }: LoadingButtonProps) => {
  return (
    <LoadingButton type={type} variant={variant} {...rest}>
      {children}
    </LoadingButton>
  )
}
