import { TimeSignaturesControl } from 'components/Composer/constants/controls'

export const TUNE_TITLE_REGEX = /T:(.+?)(?=\n)/
export const TUNE_COMPOSER_REGEX = /C:(.+?)(?=\n)/
export const TUNE_KEY_SIGNATURE_REGEX = /K:(.+?)(?=\n)/
export const TUNE_TIME_SIGNATURE_REGEX = /M:(.+?)(?=\n)/

export const TUNE_SETTINGS_SETTER_MAPPING = {
  keySignature: { setter: 'setKeySignature', stateKey: 'settings' },
  title: { setter: 'setTitle', stateKey: 'meta' },
  composer: { setter: 'setComposer', stateKey: 'meta' },
} as const

export const TIME_SIGNATURE_MAPPING = {
  C: '4/4',
  'C|': '2/2',
}

export type TimeSignatureMapping = Extract<TimeSignaturesControl['value'], 'C' | 'C|'>
