import { useEffect } from 'react'

import {
  AudioFileOutlined as AudioFileIcon,
  CloseOutlined as CloseIcon,
  DescriptionOutlined as FileIcon,
  DeleteOutlineOutlined as TrashIcon,
  PlayCircleOutline as VideoIcon,
} from '@mui/icons-material'
import { Box, Card, CardActions, CardContent, IconButton, Tooltip } from '@mui/material'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'

import { Attachment, EditableTextField, SearchOption } from 'components/ui'
import { useScoreSearchContext } from 'pages/teachers/TeacherCoursePage/components/'

import { TeacherCourseFormValues } from '../teacherCourseSchema'

import { attachmentContainerSx, attachmentListSx, deleteIconButtonSx, lectureCardSx } from './styles'

type Props = {
  form: UseFormReturn<TeacherCourseFormValues>
  moduleIndex: number
  lectureIndex: number
  handleDeleteLecture: (lectureIndex: number) => void
}

export const TeacherCourseLectureCard = ({
  form,
  moduleIndex,
  lectureIndex,
  handleDeleteLecture,
  ...rest
}: Props) => {
  const { handleToggleSearch, selectedOption, searchParams, formValues } = useScoreSearchContext()
  const { control } = form

  const fieldPrefix = `courseModules.${moduleIndex}.courseLectures.${lectureIndex}` as const
  const isCurrentModule = searchParams.moduleIndex === moduleIndex
  const isCurrentLecture = isCurrentModule && searchParams.lectureIndex === lectureIndex

  const {
    fields: courseLecturePlayableScores,
    append,
    update,
  } = useFieldArray({
    control,
    name: `${fieldPrefix}.courseLecturePlayableScores`,
    keyName: 'rhfId',
  })

  const handleAddScore = (option: SearchOption) => {
    const playableScore = {
      title: option.title,
      playableScoreId: option.id,
      playableScoreType: formValues.type,
    }
    append(playableScore)
  }

  const handleDeletePlayableScore = (playableScoreIndex: number) => {
    update(playableScoreIndex, {
      ...courseLecturePlayableScores[playableScoreIndex],
      _destroy: true,
    })
  }

  useEffect(() => {
    if (!selectedOption || !isCurrentLecture) return

    handleAddScore(selectedOption)
  }, [selectedOption])

  return (
    <Card sx={lectureCardSx} {...rest}>
      <CardContent>
        <Controller
          name={`${fieldPrefix}.title`}
          control={control}
          render={({ field }) => <EditableTextField inputProps={field} />}
        />
        <Controller
          name={`${fieldPrefix}.description`}
          control={control}
          render={({ field }) => <EditableTextField inputProps={field} />}
        />

        {!!courseLecturePlayableScores?.length && (
          <Box sx={attachmentListSx}>
            {courseLecturePlayableScores.map((playableScore, index) =>
              !playableScore._destroy ? (
                <Box key={playableScore.rhfId} sx={attachmentContainerSx}>
                  <IconButton
                    size="small"
                    onClick={() => handleDeletePlayableScore(index)}
                    sx={deleteIconButtonSx}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <Attachment fileName={playableScore.title} key={playableScore.id} index={index} />
                </Box>
              ) : (
                <span key={playableScore.id} />
              ),
            )}
          </Box>
        )}
      </CardContent>

      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box display="flex" gap="8px">
          <Tooltip title="Add Video" placement="top">
            <IconButton size="small" color="primary" onClick={() => {}}>
              <VideoIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Add Score" placement="top">
            <IconButton
              size="small"
              color="primary"
              onClick={_ => handleToggleSearch({ moduleIndex, lectureIndex })}
            >
              <AudioFileIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Add File" placement="top">
            <IconButton size="small" color="primary" onClick={() => {}}>
              <FileIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Lecture" placement="top">
            <IconButton size="small" color="primary" onClick={() => handleDeleteLecture(lectureIndex)}>
              <TrashIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  )
}
