import { useEffect, useState } from 'react'

import { useApi } from 'hooks'
import { Score } from 'models'
import { ScoresEndpoint } from 'routes/locations'

export const useScoresController = () => {
  const [scoreList, setScoreList] = useState<Score[]>([])
  const [fetchScores, scores, { loading: fetchScoresLoading, meta: fetchScoresMeta }] = useApi<Score[]>(
    ScoresEndpoint,
    'GET',
  )

  useEffect(() => {
    if (!scores) return

    setScoreList(prevScoreList => {
      if (fetchScoresMeta.page === 1) return scores
      return [...prevScoreList, ...scores]
    })
  }, [scores])

  return {
    fetchScores,
    scores: scoreList,
    fetchScoresLoading,
    fetchScoresMeta,
  }
}
