import { transparentize } from 'polished'
import styled, { keyframes } from 'styled-components'

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  position: relative;
  animation: ${spin} 1.4s infinite linear;
  transform: translateZ(0);
  background: ${({ theme, $color }) => {
    const shadow = transparentize(1, $color || theme.palette.text.primary)
    return `linear-gradient(to right, ${$color || theme.palette.text.primary} 10%, ${shadow} 42%)`
  }};

  &::before {
    width: 50%;
    height: 50%;
    background: ${({ theme, $color }) => $color || theme.palette.text.primary};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &::after {
    background: ${({ theme, $bg }) => $bg || theme.palette.background.default};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`
