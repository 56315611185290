import { Composer } from 'components'
import { PageContainer } from 'components/ui'

const ComposerPage = () => {
  return (
    <PageContainer fullWidth>
      <Composer />
    </PageContainer>
  )
}

export default ComposerPage
