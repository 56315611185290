import { css } from 'styled-components'

export const buttonStyles = css`
  height: 48px;
  padding: 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.palette.text.primary};
  letter-spacing: 0.2px;
  opacity: 1;
  border-radius: 5px;
  transition: background 0.1s;
  cursor: pointer;
  background: ${props => props.theme.palette.primary.main};

  &:hover {
  }
`
export const outlineButtonStyles = css`
  ${buttonStyles}
  color: ${props => props.theme.palette.primary.main};
  border: 1px solid ${props => props.theme.palette.primary.main};
  background: transparent;
`
export const inputStyles = css`
  height: 48px;
  border-radius: 5px;
  margin: 5px 0;
  border: none;
  color: ${props => props.theme.palette.text.primary};
  padding: 0 20px;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: Montserrat, sans-serif;
  font-weight: 200px;
  background: ${({ theme }) => theme.palette.components.PaInput.main};

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 100px;
  }
`
export const outlineInputStyles = css`
  ${inputStyles}
  border: 1px solid #333336;
  background: transparent;
`
