import { shade } from 'polished'
import styled from 'styled-components'

import { fadeSlideAnimation } from 'styles/keyframes'

export const FormsContainer = styled.div`
  width: 100%;
  margin: 72px auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    form {
      margin-bottom: 32px;
      width: 90%;
    }
  }
`
export const Form = styled.form`
  width: calc(50% - 24px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 1rem;

  @media (max-width: 960px) {
    width: calc(50% - 12px);
  }
`
export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    margin: 0;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }

    input {
      width: 50%;
    }
  }
`
export const AvatarInput = styled.div`
  margin-top: 24px;
  position: relative;
  align-self: center;

  img {
    width: 185px;
    height: 185px;
    border-radius: 50%;
    object-fit: cover;
    animation: ${fadeSlideAnimation({ dirX: 'left', dirY: 'bottom' })} 0.5s ease-in;

    &.reset-animation {
      animation: unset;
    }
  }

  label {
    width: 48px;
    height: 48px;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    transition: background-color 0.1s;

    &:hover {
      background: ${({ theme }) => shade(0.1, theme.palette.primary.main)};
    }

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: auto;
      color: ${({ theme }) => theme.palette.colors.white};
    }
  }

  @media (max-width: 670px) {
    margin-bottom: 24px;
  }
`
