import { useState } from 'react'

import {
  ArrowBack as ArrowBackIcon,
  NoteAddOutlined as NewFileIcon,
  Redo as RedoIcon,
  SaveOutlined as SaveIcon,
  Undo as UndoIcon,
} from '@mui/icons-material'
import { Badge, Box, IconButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useComposer } from 'components/Composer/hooks'
import {
  ComposerPanelControl,
  ComposerPanelControlLabel,
  ComposerPanelType,
} from 'components/Composer/models/types'
import { Icon, IconMapping } from 'components/ui/Icon'

import { COMPOSER_PANEL_MAPPING } from './constants'
import { Container, Controls, Tab, Tabs, ToggleButton } from './styles'

import { TabPanel } from '.'

type Props = {
  composer: ReturnType<typeof useComposer>
}

export const ComposerTopbar = ({ composer }: Props) => {
  const navigate = useNavigate()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [activeControls, setActiveControls] = useState<ActiveControlState>(INITIAL_ACTIVE_CONTROL_STATES)
  const { handlers, settings } = composer
  const { isComposerDirty, canUndo, canRedo } = settings

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index)
  }

  const highlightDurationPanel = (control: ComposerPanelControl, panelName: ComposerPanelType) => {
    if (!ACTIVE_CONTROL_STATES_KEYS.includes(panelName)) return

    setActiveControls({
      ...activeControls,
      [panelName]: control.label,
    })
  }
  return (
    <Container>
      <Box>
        <Tabs value={activeTabIndex} variant="scrollable" onChange={(_, index) => handleTabChange(index)}>
          {Object.entries(COMPOSER_PANEL_MAPPING).map(([_, panelSettings]) => (
            <Tab label={panelSettings.label} key={panelSettings.label} />
          ))}
        </Tabs>
      </Box>
      <>
        {Object.entries(COMPOSER_PANEL_MAPPING).map(([panelName, panelSettings], index) => (
          <TabPanel value={activeTabIndex} index={index} key={panelName}>
            <ToggleButtonGroup
              value={activeControls[panelName as keyof ActiveControlState]}
              aria-label={panelName}
            >
              {panelSettings.controls.map(
                control =>
                  // @ts-ignore-strict
                  !control.hidden && (
                    <ToggleButton
                      title={control.label}
                      value={control.label}
                      key={control.label}
                      onClick={() => {
                        // @ts-ignore-strict
                        handlers[panelSettings.handler](control, panelName)
                        highlightDurationPanel(control, panelName as keyof ActiveControlState)
                      }}
                    >
                      {'icon' in panelSettings && panelSettings.icon ? (
                        <Icon name={control.label as keyof IconMapping} width={32} />
                      ) : (
                        control.label
                      )}
                    </ToggleButton>
                  ),
              )}
            </ToggleButtonGroup>
          </TabPanel>
        ))}
      </>

      <Controls>
        <IconButton onClick={handlers.handleUndo} disabled={!canUndo} aria-label="undo">
          <Tooltip title="Undo">
            <UndoIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={handlers.handleRedo} disabled={!canRedo} aria-label="redo">
          <Tooltip title="Redo">
            <RedoIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={handlers.handleComposerSaveClick}>
          <Tooltip title="Save">
            <Badge variant="dot" color="primary" invisible={!isComposerDirty}>
              <SaveIcon />
            </Badge>
          </Tooltip>
        </IconButton>
        <IconButton onClick={handlers.handleResetComposer}>
          <Tooltip title="New file">
            <NewFileIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => navigate(-1)}>
          <Tooltip title="Go back">
            <ArrowBackIcon />
          </Tooltip>
        </IconButton>
      </Controls>
    </Container>
  )
}

type ComposerPanelTypes = {
  [k in Exclude<ComposerPanelType, 'modifiers'>]: ComposerPanelControlLabel
}
type ActiveControlState = Pick<ComposerPanelTypes, 'duration' | 'timeSignature' | 'keySignature' | 'octave'>

const INITIAL_ACTIVE_CONTROL_STATES: ActiveControlState = {
  duration: 'quarter-note',
  octave: 'C4',
  timeSignature: '4|4',
  keySignature: 'C-Am',
}
const ACTIVE_CONTROL_STATES_KEYS = Object.keys(INITIAL_ACTIVE_CONTROL_STATES)
