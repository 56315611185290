import { maestro } from 'components/Composer/hooks'

import { TempoManager } from '..'
import { AbcjsElementWithDuration } from '../types'

import { ElementsByMeasure, HeadMeasure, Measure } from '.'

type Args = {
  headMeasure: HeadMeasure
} & ConstructorParameters<typeof Measure>[0]

export class TailMeasure extends Measure {
  headMeasure: HeadMeasure
  _measures: ElementsByMeasure

  constructor(args: Args) {
    super(args)

    this.headMeasure = args.headMeasure
    this._measures = this.getMeasures()
  }

  abcNotation() {
    return this.measures.map(measure => measure.map(newElement => newElement.abcNotation).join('')).join('')
  }

  restBeats() {
    const lastMeasureElementsDuration = TempoManager.getElementsDuration(this.lastMeasure)
    return maestro.totalMeasureBeats - lastMeasureElementsDuration
  }

  endChar(): number {
    return this.measureElementsAfterSelectionToBeReplacedByNewElements().at(-1)?.elem.abcelem.endChar
  }

  lastElementEndChar() {
    const { startChar } = maestro.selectedElement
    return startChar + this.headMeasure.abcNotation().length + this.abcNotation().length
  }

  // private

  /* eslint-disable class-methods-use-this */
  measureElementsAfterSelectionToBeReplacedByNewElements(): AbcjsElementWithDuration[] {
    const nextElements = maestro.allElementsAfterSelection()
    const newElementsDuration = TempoManager.getElementsDuration(maestro.newElements)
    return TempoManager.getElementsForDuration(
      nextElements,
      newElementsDuration - maestro.selectedElement.duration,
    ) as AbcjsElementWithDuration[]
  }

  private getMeasures() {
    return this.measureSettings.slice(1)
  }

  get measures() {
    return this._measures
  }

  get lastMeasure() {
    return this.measures.at(-1)!
  }

  get newElements() {
    return this.measures.flat()
  }
}
