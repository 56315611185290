import { Button } from '@mui/material'
import { LinkProps } from 'react-router-dom'
import styled from 'styled-components'

import { fadeSlideAnimation } from 'styles/keyframes'

type FileBox = {
  $index: number
}

export const ButtonLink = styled(Button)<LinkProps & FileBox>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  opacity: 0;
  animation: ${() => fadeSlideAnimation()} 0.3s ${({ $index }) => $index * 0.04}s forwards;

  svg {
    width: auto;
    flex: 1;
    stroke-width: 0.5px;
    fill: rgba(164, 18, 77, 0.1);
  }
`
