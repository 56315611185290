/* Auth */
export const SIGN_UP_START = 'SIGN_UP_START'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'
export const SIGN_UP_END = 'SIGN_UP_END'

export const SIGN_IN_START = 'SIGN_IN_START'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
export const SIGN_IN_END = 'SIGN_IN_END'

export const SIGN_OUT_START = 'SIGN_OUT_START'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR'
export const SIGN_OUT_END = 'SIGN_OUT_END'

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_END = 'FORGOT_PASSWORD_END'

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_END = 'RESET_PASSWORD_END'

/* Current User */
export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR'
export const FETCH_CURRENT_USER_END = 'FETCH_CURRENT_USER_END'

export const UPDATE_CURRENT_USER_START = 'UPDATE_CURRENT_USER_START'
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS'
export const UPDATE_CURRENT_USER_ERROR = 'UPDATE_CURRENT_USER_ERROR'
export const UPDATE_CURRENT_USER_END = 'UPDATE_CURRENT_USER_END'
