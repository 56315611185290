import { maestro } from 'components/Composer/hooks'

import { TempoManager } from '..'
import {
  AbcjsElementWithDuration,
  AbcNotation,
  ElementWithDuration,
  ModelWithNewElements,
  MusicalElementWithDuration,
} from '../types'

import { Measure } from '.'

type Args = ConstructorParameters<typeof Measure>[0]

export class HeadMeasure extends Measure {
  _newElements: MusicalElementWithDuration[]
  _measure: MusicalElementWithDuration[]
  _newElementsDuration: number
  _measureElementsBeforeSelection: AbcjsElementWithDuration[]
  _measureElementsAfterSelection: AbcjsElementWithDuration[]
  _measureElementsAfterSelectionToBeReplacedByNewElements: AbcjsElementWithDuration[]

  constructor(args: Args) {
    super(args)

    this._measureElementsBeforeSelection = HeadMeasure.getMeasureElementsBeforeSelection()
    this._measureElementsAfterSelection = HeadMeasure.getMeasureElementsAfterSelection()

    this._newElements = this.getMeasure()
    this._newElementsDuration = HeadMeasure.getNewElementsDuration(this)
    this._measureElementsAfterSelectionToBeReplacedByNewElements =
      HeadMeasure.getMeasureElementsAfterSelectionToBeReplacedByNewElements(this)
  }

  abcNotation(): AbcNotation {
    return this.newElements.map(newElement => newElement.abcNotation).join('') as AbcNotation
  }

  restBeats() {
    return (
      maestro.totalMeasureBeats -
      this.newElementsDuration -
      HeadMeasure.measureElementsBeforeAndAfterSelectionDuration(this)
    )
  }

  static measureElementsBeforeAndAfterSelectionDuration(model: ModelWithNewElements) {
    const measureElementsBeforeSelection = TempoManager.getElementsDuration(
      this.getMeasureElementsBeforeSelection(),
    )
    const measureElementsAfterNewElements = TempoManager.getElementsDuration(
      this.measureElementsAfterNewElements(model),
    )
    return measureElementsBeforeSelection + measureElementsAfterNewElements
  }

  endChar(): number {
    return this.measureElementsAfterSelectionToBeReplacedByNewElements.at(-1)?.elem.abcelem.endChar
  }

  lastElementEndChar() {
    const { startChar } = maestro.selectedElement
    return startChar + this.abcNotation().length
  }

  // private

  static getMeasureElementsAfterSelection() {
    const { selectedElement } = maestro
    const { measureTotal: selectedElementMeasure } = selectedElement.abselem.counters
    return maestro
      .allElementsAfterSelection()
      .filter(element => element.measureNumber === selectedElementMeasure)
  }

  static getMeasureElementsBeforeSelection() {
    const { selectedElement } = maestro
    const { measureTotal: selectedElementMeasureIndex, note: selectedElementNoteIndex } =
      selectedElement.abselem.counters
    return maestro.selectableElements.filter(
      element =>
        element.measureNumber === selectedElementMeasureIndex &&
        element.elem.counters.note < selectedElementNoteIndex,
    )
  }

  static measureElementsAfterNewElements(model: ModelWithNewElements) {
    const elementsToBeReplaced = this.getMeasureElementsAfterSelectionToBeReplacedByNewElements(model)
    return this.getMeasureElementsAfterSelection().filter(measureElement => {
      return elementsToBeReplaced.every(
        elementToBeReplaced => elementToBeReplaced.elem.counters !== measureElement.elem.counters,
      )
    })
  }

  static getMeasureElementsAfterSelectionToBeReplacedByNewElements(
    model: ModelWithNewElements,
  ): AbcjsElementWithDuration[]
  static getMeasureElementsAfterSelectionToBeReplacedByNewElements(
    model: ModelWithNewElements,
  ): ElementWithDuration[]
  static getMeasureElementsAfterSelectionToBeReplacedByNewElements(model: ModelWithNewElements) {
    const { duration: selectedElementDuration } = maestro.selectedElement
    const nextElements = this.getMeasureElementsAfterSelection()
    const missingNewElementsDuration = Math.abs(this.getNewElementsDuration(model) - selectedElementDuration)
    return TempoManager.getElementsForDuration(nextElements, missingNewElementsDuration)
  }

  static measureBeatsFromSelectionDuration() {
    const { selectedElement } = maestro
    const measureElementsAfterSelectionDuration = TempoManager.getElementsDuration(
      this.getMeasureElementsAfterSelection(),
    )
    return selectedElement.duration + measureElementsAfterSelectionDuration
  }

  static getMeasureNewElements(model: ModelWithNewElements) {
    return TempoManager.getElementsForDuration(model.newElements, this.measureBeatsFromSelectionDuration())
  }

  static getNewElementsDuration(model: ModelWithNewElements) {
    return TempoManager.getElementsDuration(this.getMeasureNewElements(model))
  }

  get newElements() {
    return this._newElements
  }

  get newElementsDuration() {
    return this._newElementsDuration
  }

  get measureElementsAfterSelection() {
    return this._measureElementsAfterSelection
  }

  get measureElementsBeforeSelection() {
    return this._measureElementsBeforeSelection
  }

  get measureElementsAfterSelectionToBeReplacedByNewElements() {
    return this._measureElementsAfterSelectionToBeReplacedByNewElements
  }

  getMeasure() {
    return this.measureSettings[0]
  }

  get measure() {
    return this._measure
  }
}
