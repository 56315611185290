import undoable, { excludeAction } from 'redux-undo'

import { composer } from 'store/composer/actions'
import initialState from 'store/state'

import {
  ADD_ELEMENT,
  ADD_STAFF,
  DELETE_ELEMENT,
  DRAG_NOTE,
  PASTE,
  RESET_ABCJS,
  SET_ANNOTATION,
  SET_CLIPBOARD,
  SET_MODIFIER,
  SET_SELECTED_CUSTOM_SCORE,
  SET_SETTINGS,
  SET_TUNE,
} from './types'

const editorReducer = (state = initialState.composer, action) => {
  switch (action.type) {
    case ADD_ELEMENT:
    case DELETE_ELEMENT:
    case ADD_STAFF:
    case SET_MODIFIER:
    case SET_ANNOTATION:
    case SET_TUNE:
    case DRAG_NOTE:
    case PASTE: {
      const { staffs: _, ...rest } = action.payload
      return {
        ...state,
        ...rest,
        staffs: composer.toJson(),
      }
    }

    case SET_SETTINGS: {
      const { tune, settings } = action.payload
      return {
        ...state,
        settings,
        tune,
      }
    }

    case SET_SELECTED_CUSTOM_SCORE:
      return {
        ...state,
        selectedAbc: action.payload,
      }

    case SET_CLIPBOARD:
      return {
        ...state,
        clipboard: action.payload,
      }

    case RESET_ABCJS:
      return {
        ...initialState.composer,
        clipboard: state.clipboard,
      }

    default:
      return state
  }
}

export default undoable(editorReducer, {
  filter: excludeAction([SET_SELECTED_CUSTOM_SCORE]),
})

export const initialGeneral = { title: '', composer: '' }
