import { ReactNode } from 'react'

import { Toast } from 'components/ui'
import { useToast } from 'hooks'

import { createCtx } from './utils'

type Props = {
  children: ReactNode
}

type ToastContext = ReturnType<typeof useToast>

export const [useToastContext, ToastContextProvider] = createCtx<ToastContext>()

export const ToastProvider = ({ children }: Props) => {
  const toast = useToast()

  return (
    <ToastContextProvider value={toast}>
      {children}
      <Toast messages={toast.messages} />
    </ToastContextProvider>
  )
}
