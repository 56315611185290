export const DELETE_ELEMENT = 'DELETE_ELEMENT'
export const ADD_ELEMENT = 'ADD_ELEMENT'
export const ADD_STAFF = 'ADD_STAFF'
export const DRAG_NOTE = 'DRAG_NOTE'

export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_MODIFIER = 'SET_MODIFIER'
export const SET_ANNOTATION = 'SET_ANNOTATION'
export const SET_TUNE = 'SET_TUNE'
export const SET_SELECTED_CUSTOM_SCORE = 'SET_SELECTED_CUSTOM_SCORE'
export const SET_CLIPBOARD = 'SET_CLIPBOARD'
export const PASTE = 'PASTE'
export const RESET_ABCJS = 'RESET_ABCJS'
