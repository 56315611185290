import { VoiceItemNote } from 'abcjs'

import { Maestro } from '..'
import { AbcNotation, MusicalElementSettings } from '../types'

import {
  GeneralMusicalElementDecorator,
  MusicalElementDecorator,
  StructuralElementDecorator,
} from './MusicalElementsDecorator'

export abstract class MusicalElement {
  settings: MusicalElementSettings
  maestro: Maestro

  constructor(settings: MusicalElementSettings, maestro: Maestro) {
    this.settings = settings
    this.maestro = maestro
  }

  abstract get abcNotation(): AbcNotation

  static for(...args: ConstructorParameters<typeof MusicalElement>) {
    const [settings] = args
    switch (settings.type) {
      case 'rest':
      case 'note':
      case 'bar':
        return GeneralMusicalElementDecorator.for(...args)

      case 'misc':
        return StructuralElementDecorator.for(...args)

      case 'articulation':
      case 'accidental':
      case 'octave':
      case 'timeSignature':
      default:
        return MusicalElementDecorator.for(...args)
    }
  }

  static convertAbcjsToElementsSettings(elements: VoiceItemNote[]) {
    return elements.map(element => {
      const mapping: { [key: string]: MusicalElementSettings } = {
        note: { value: element.pitches?.[0].name, duration: element.duration, type: element.el_type },
        rest: { value: 'z', duration: element.duration, type: 'rest' },
        bar: { value: '|', duration: NaN, type: element.el_type },
      }

      const type = element.rest ? 'rest' : element.el_type
      return type ? mapping[type] : mapping.rest
    })
  }

  get duration() {
    return this.settings.duration
  }
}
