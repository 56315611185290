import { MutableRefObject } from 'react'

import abcjs, { SynthOptions } from 'abcjs'

import { DEFAULT_BPM } from '../AbcTune/constants'
import { CursorControl } from '../CursorControl'

import { ABC_RENDERER_OPTIONS } from './constants'

const cursorControl = new CursorControl()

export class AbcRenderer {
  containerRef: MutableRefObject<Nullable<HTMLElement>>
  audioRef: Potential<MutableRefObject<Nullable<HTMLElement>>>
  display: abcjs.TuneObject
  synth: abcjs.MidiBuffer
  synthControl: abcjs.SynthObjectController
  playButton: HTMLElement
  resetButton: HTMLElement

  constructor() {
    this.synth = new abcjs.synth.CreateSynth()
    this.synthControl = new abcjs.synth.SynthController()
  }

  init(
    containerRef: MutableRefObject<Nullable<HTMLElement>>,
    audioRef?: MutableRefObject<Nullable<HTMLElement>>,
  ) {
    this.containerRef = containerRef
    this.audioRef = audioRef

    if (!this.audioRef?.current) return

    this.synthControl.load(this.audioRef.current, cursorControl, ABC_RENDERER_OPTIONS.synth)

    /**
     * Workaround for synthControl play/pause bug
     * TODO: Replace with `this.player.renderer.synthControl.play()` when fixed
     * https://github.com/paulrosen/abcjs/issues/749
     */
    this.playButton = document.querySelector('.abcjs-midi-start.abcjs-btn')!
    this.resetButton = document.querySelector('.abcjs-midi-reset.abcjs-btn')!
  }

  render(tune: string) {
    if (!this.containerRef.current) return

    const [visualObj] = abcjs.renderAbc(this.containerRef.current, tune, ABC_RENDERER_OPTIONS.visual)
    this.display = visualObj
  }

  renderWithAudio(tune: string, synthOptions: SynthOptions = {}) {
    this.render(tune)

    if (!this.display) return

    const options = { ...ABC_RENDERER_OPTIONS.synth, ...synthOptions }

    this.synth
      .init({
        visualObj: this.display,
        millisecondsPerMeasure: 500,
        options,
      })
      .catch(reason => {
        /* eslint-disable-next-line */
        console.log('Abcjs Synth error:', reason)
      })

    this.synthControl.setTune(this.display, true, options)
    this.synth.prime()
  }

  getMidiFromAbc(bpm: number = DEFAULT_BPM) {
    return abcjs.synth.getMidiFile(this.display, {
      midiOutputType: 'binary',
      // @ts-ignore: wait library fix
      bpm,
    })
  }

  transpose(tune: string, step: number) {
    // @ts-ignore: wait library fix
    return abcjs.strTranspose(tune, [this.display], step)
  }
}
