import {
  CustomScoreFolderEndpoint,
  CustomScoreFoldersEndpoint,
  FavoriteScoreFolderEndpoint,
  FavoriteScoreFoldersEndpoint,
} from 'routes/locations/folders'
import api from 'services/api'
import { appError } from 'store/global/actions'

import {
  createFolderEnd,
  createFolderError,
  createFolderStart,
  createFolderSuccess,
  deleteFolderEnd,
  deleteFolderError,
  deleteFolderStart,
  deleteFolderSuccess,
  fetchFolderEnd,
  fetchFolderStart,
  fetchFolderSuccess,
  reset,
  setSelectedFolderEnd,
  setSelectedFolderStart,
  setSelectedFolderSuccess,
  setType,
  updateFolderEnd,
  updateFolderError,
  updateFolderStart,
  updateFolderSuccess,
} from './creators'

export const createFolder = parentId => {
  return async (dispatch, getState) => {
    dispatch(createFolderStart(parentId))
    const { type } = getState().folders
    const selectedFolder = getState().folders.data.find(folder => folder.id === parentId)
    const response = await api(endpoint[type].index, 'POST', {
      name: newFolderName(selectedFolder),
      parent_id: parentId,
    })

    const { status } = response

    if (status !== 201) {
      dispatch(createFolderError(errors.create))
      dispatch(createFolderEnd())
      return
    }

    dispatch(createFolderSuccess())
    dispatch(createFolderEnd())
    dispatch(fetchFolder(parentId))
  }
}

export const deleteFolder = (ids, parentId) => {
  return async (dispatch, getState) => {
    dispatch(deleteFolderStart(parentId))
    const { type } = getState().folders

    const response = await api(
      endpoint[type].show,
      'DELETE',
      {},
      {
        id: ids.join(','),
      },
    )
    const { status } = response

    if (status !== 204) {
      dispatch(deleteFolderError(errors.delete))
      dispatch(deleteFolderEnd())
      return
    }

    dispatch(deleteFolderSuccess())
    dispatch(deleteFolderEnd())
  }
}

export const updateFolder = (folderId, params, options = {}) => {
  return async (dispatch, getState) => {
    const { loadingId } = options
    const { type } = getState().folders
    dispatch(updateFolderStart(loadingId))

    const response = await api(endpoint[type].show, 'PUT', params, {
      id: folderId,
    })

    const { status, error } = response
    if (status !== 204) {
      const message = { ...errors.update, description: error }
      dispatch(updateFolderError(message))
      dispatch(updateFolderEnd())
      return
    }

    dispatch(updateFolderSuccess())
    dispatch(updateFolderEnd())
  }
}

export const fetchFolder = (id, options = {}) => {
  return async (dispatch, getState) => {
    const { loadingSelect } = options
    const { type } = getState().folders
    if (!type) return

    dispatch(fetchFolderStart(id, loadingSelect))

    const response = await api(endpoint[type].show, 'GET', {}, { id })
    const { status, data, error } = response

    if (status !== 200) {
      dispatch(appError(error))
      dispatch(fetchFolderEnd())
      return
    }

    dispatch(fetchFolderSuccess(data))
    dispatch(fetchFolderEnd())
  }
}

export const setSelectedFolder = id => {
  return async (dispatch, getState) => {
    dispatch(setSelectedFolderStart(id))

    const folder = getState().folders.data.find(dir => dir.id === id)

    if (!folder) {
      dispatch(appError())
      return dispatch(setSelectedFolderEnd())
    }

    dispatch(setSelectedFolderSuccess(folder))
    dispatch(setSelectedFolderEnd())
  }
}

export const setFolderType = type => {
  return async dispatch => {
    dispatch(setType({ type }))
  }
}

export const resetFolders = () => {
  return async dispatch => {
    dispatch(reset())
  }
}

const endpoint = {
  customScores: {
    show: CustomScoreFolderEndpoint,
    index: CustomScoreFoldersEndpoint,
  },
  favoriteScores: {
    show: FavoriteScoreFolderEndpoint,
    index: FavoriteScoreFoldersEndpoint,
  },
}

const newFolderName = folder => {
  const newFolderRegex = /New Folder (\d+)/
  const newFolders = folder.subfolders
    .map(dir => parseInt(dir.name.match(newFolderRegex)?.[1], 10))
    .sort((a, b) => a - b)
  const missingIndex = newFolders.find((x, i, list) => list[i + 1] - x > 1)
  const index = missingIndex || newFolders.slice(-1)[0] || 0

  return `New Folder ${index + 1}`
}

const errors = {
  create: {
    title: 'Create Folder Error!',
    description: 'Folder could not be created. Try again.',
  },
  delete: {
    title: 'Delete Folder Error!',
    description: 'Folder could not be deleted. Try again.',
  },
  update: { title: 'Update Folder Error!' },
}
