import { AbcNotation } from 'components/Composer/models/types'
import { asArray } from 'utils'

import { MusicalElement, Staff } from '..'
import { Measure } from '../Measure'

import { MusicalElementDecorator } from './MusicalElementDecorator'

export class StructuralElementDecorator extends MusicalElementDecorator {
  get abcNotation(): AbcNotation {
    return this.musicalElement.abcNotation
  }

  static for(...args: ConstructorParameters<typeof MusicalElement>) {
    const [settings, maestro] = args
    let element
    switch (settings.label) {
      case 'staff':
        element = new Staff(...args)
        break
      case 'measure':
      default:
        element = new Measure(...args)
        break
    }

    return asArray(new StructuralElementDecorator({ settings: element.settings, maestro, element }))
  }
}
