import { useEffect } from 'react'

import { Tuner } from 'components'
import { PageContainer } from 'components/ui'

import { TunerContainer } from './styles'

const TunerPage = () => {
  useEffect(() => {
    document.title = 'Tuner - Playalong.io'
    return () => {
      document.title = 'Playalong.io'
    }
  }, [])

  return (
    <PageContainer>
      <TunerContainer>
        <Tuner fontSize={72} active />
      </TunerContainer>
    </PageContainer>
  )
}

export default TunerPage
