import { CURRENT_USER_KEY } from 'hooks/constants'

const authState = {
  auth: {
    data: JSON.parse(localStorage.getItem(CURRENT_USER_KEY)) || {},
    loading: false,
    error: null,
    update: {},
  },
}

export default authState
