import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined, LockOutlined, PersonOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { Button, Input } from 'components/ui'
import { useAuth } from 'hooks'
import { useToastContext } from 'providers'
import { UsersEndpoint } from 'routes/locations'

import { Container, Form, FormContainer, Link, Title } from '../styles'

import { Footer, InputGroup } from './styles'

const schema = Yup.object().shape({
  first_name: Yup.string().required().label('First Name'),
  last_name: Yup.string().required().label('Last Name'),
  email: Yup.string().required().email().label('Email'),
  password: Yup.string().required().label('password'),
})

type SignUpFormValues = Yup.InferType<typeof schema>

export const SignUp = () => {
  const [signUp, error, loading] = useAuth(UsersEndpoint, 'POST')
  const { showToast } = useToastContext()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    },
  })

  const onSubmit = useCallback(
    (data: SignUpFormValues) => {
      signUp({ user: data })
    },
    [signUp],
  )

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: 'Sign up failed!',
      description: error.message,
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Sign Up</Title>
          <Box display="flex" gap="0.8rem" flexDirection="column">
            <InputGroup>
              <Input<SignUpFormValues>
                type="text"
                name="first_name"
                label="First Name"
                placeholder="First Name"
                control={control}
                icon={PersonOutlined}
                errorMessage={errors.first_name?.message as string}
              />
              <Input
                type="text"
                name="last_name"
                label="Last Name"
                placeholder="Last Name"
                control={control}
                icon={PersonOutlined}
                errorMessage={errors.last_name?.message as string}
              />
            </InputGroup>

            <Input
              type="email"
              name="email"
              label="Email"
              placeholder="Email"
              icon={EmailOutlined}
              control={control}
              autoComplete="username"
              errorMessage={errors.email?.message as string}
            />

            <Input
              type="password"
              name="password"
              label="Password"
              placeholder="Password"
              icon={LockOutlined}
              control={control}
              autoComplete="current-password"
              errorMessage={errors.password?.message as string}
            />
          </Box>

          <Button type="submit" sx={{ mt: '1rem' }} loading={loading}>
            Submit
          </Button>

          <Footer>
            <Link to="/sign-in">Sign in to your account</Link>
          </Footer>
        </Form>
      </FormContainer>
    </Container>
  )
}
