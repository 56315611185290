import build from 'redux-object'

import { CustomScoreEndpoint, CustomScoresEndpoint, UploadMidiEndpoint } from 'routes/locations/custom-scores'
import api from 'services/api'
import { appError } from 'store/global/actions'

import {
  createCustomScoreEnd,
  createCustomScoreError,
  createCustomScoreStart,
  createCustomScoreSuccess,
  deleteCustomScoreEnd,
  deleteCustomScoreError,
  deleteCustomScoreStart,
  deleteCustomScoreSuccess,
  fetchCustomScoreEnd,
  fetchCustomScoreStart,
  fetchCustomScoreSuccess,
  resetCustomScore as resetCustomScoreEnd,
  updateCustomScoreEnd,
  updateCustomScoreError,
  updateCustomScoreStart,
  updateCustomScoreSuccess,
  uploadMidiEnd,
  uploadMidiError,
  uploadMidiStart,
  uploadMidiSuccess,
} from './creators'

export const fetchCustomScore = id => {
  return async dispatch => {
    dispatch(fetchCustomScoreStart())
    const response = await api(CustomScoreEndpoint, 'GET', {}, { id })
    const { status, data, error } = response

    if (status !== 200) {
      dispatch(appError(error))
      return dispatch(fetchCustomScoreEnd())
    }

    const customScore = build(data, 'customScores')[0]
    dispatch(fetchCustomScoreSuccess(customScore))
    dispatch(fetchCustomScoreEnd())
  }
}

export const deleteCustomScore = ids => {
  return async dispatch => {
    dispatch(deleteCustomScoreStart())

    const response = await api(CustomScoreEndpoint, 'DELETE', {}, { id: ids.join(',') })
    const { status, error } = response

    if (status !== 204) {
      dispatch(deleteCustomScoreError(error))
      return dispatch(deleteCustomScoreEnd())
    }

    dispatch(deleteCustomScoreSuccess())
    dispatch(deleteCustomScoreEnd())
  }
}

export const updateCustomScore = (scoreId, params) => {
  return async dispatch => {
    dispatch(updateCustomScoreStart())

    const response = await api(CustomScoreEndpoint, 'PUT', params, {
      id: scoreId,
    })
    const { status, data, error } = response

    if (status !== 200) {
      dispatch(updateCustomScoreError({ error: { message: error }, status }))
      setTimeout(() => dispatch(updateCustomScoreEnd()))
      return
    }

    const score = build(data, 'customScores')[0]

    dispatch(updateCustomScoreSuccess(score))
    setTimeout(() => dispatch(updateCustomScoreEnd()))
  }
}

export const createCustomScore = params => {
  return async dispatch => {
    dispatch(createCustomScoreStart())

    const response = await api(CustomScoresEndpoint, 'POST', params)
    const { status, data, error } = response

    if (status !== 201) {
      dispatch(createCustomScoreError({ error: { message: error }, status }))
      setTimeout(() => dispatch(createCustomScoreEnd()))
      return
    }

    const score = build(data, 'customScores')[0]

    dispatch(createCustomScoreSuccess(score))
    setTimeout(() => dispatch(createCustomScoreEnd(status)))
  }
}

export const resetCustomScore = () => {
  return async dispatch => {
    dispatch(resetCustomScoreEnd())
  }
}

export const uploadMidi = blob => {
  return async dispatch => {
    dispatch(uploadMidiStart())

    const formData = new FormData()
    formData.append('custom_score[midi]', blob)

    const response = await api(UploadMidiEndpoint, 'POST', formData, {}, {}, { file: true })
    const { status, data, error } = response

    if (status !== 201) {
      dispatch(uploadMidiError({ ...errors.uploadMidi, description: error }))
      return dispatch(uploadMidiEnd(status))
    }

    const score = build(data, 'customScores')[0]

    dispatch(uploadMidiSuccess(score))
    dispatch(uploadMidiEnd(status))
  }
}

const errors = {
  update: { title: 'Update Score Error!' },
  uploadMidi: { title: 'Upload Midi Error!' },
}
