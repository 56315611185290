import { Maestro } from '..'
import { AbcElementDuration, AbcNotation, MusicalElementSettings } from '../types'

import { MusicalElement } from './MusicalElement'

export class Bar extends MusicalElement {
  abcDuration: AbcElementDuration

  constructor(settings: MusicalElementSettings, maestro: Maestro) {
    super(settings, maestro)
    this.settings = { ...this.settings, duration: 0 }
  }

  get abcNotation(): AbcNotation {
    return this.settings.value as AbcNotation
  }
}
