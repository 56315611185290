import styled from 'styled-components'

const Wave = () => {
  return (
    <StyledWave>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 828.75 320">
        <defs>
          <clipPath id="a">
            <path d="M0 0h828.75v320H0z" />
          </clipPath>
        </defs>
        <g clipPath="url(#a)" data-name="Layer 2">
          <path d="M1440 320H0V192l48 5.3c48 5.7 144 15.7 240 0 96-16.3 192-58.3 288-90.6C672 75 768 53 864 80s192 101 288 144 192 53 240 58.7l48 5.3z" />
        </g>
      </svg>
    </StyledWave>
  )
}

export default Wave

export const StyledWave = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;

  svg {
    right: 0;
    bottom: 0;
    position: absolute;

    path {
      fill: ${({ theme: { palette } }) =>
        palette.mode === 'light' ? palette.transparent.primary.A50 : palette.transparent.A800};
    }
  }
`
