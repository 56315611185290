import styled from 'styled-components'

import { pulseWarning, pulseWarningIns, scaleWarning } from './keyframes'

const WarningIcon = () => {
  return (
    <Container>
      <Line className="fd-alert-icon__body pulseWarningIns" />
      <Dot className="fd-alert-icon__dot pulseWarningIns" />
    </Container>
  )
}

export default WarningIcon

const Container = styled.div`
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.components.PaAlert.warning.background};
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  width: 80px;
  animation: ${scaleWarning} 0.75s infinite alternate;

  &::after,
  &::before {
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.colors.white};
  }

  &::before {
    display: inline-block;
    opacity: 0;
    animation: ${pulseWarning} 2s linear infinite;
  }

  &::after {
    display: block;
    z-index: 1;
  }
`
const Line = styled.span`
  width: 5px;
  height: 47px;
  top: 10px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.components.PaAlert.warning.background};
  z-index: 2;
  animation: ${pulseWarningIns} 0.75s infinite alternate;
`
const Dot = styled.span`
  width: 7px;
  height: 7px;
  bottom: 10px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.components.PaAlert.warning.background};
  z-index: 2;
  animation: ${pulseWarningIns} 0.75s infinite alternate;
`
