import { ReactNode } from 'react'

import { useStorageCurrentUser } from 'hooks'

import { createCtx } from './utils'

type Props = {
  children: ReactNode
}

type CurrentUserContext = ReturnType<typeof useStorageCurrentUser>

export const [useCurrentUserContext, CurrentUserContextProvider] = createCtx<CurrentUserContext>()

export const CurrentUserProvider = ({ children }: Props) => {
  return <CurrentUserContextProvider value={useStorageCurrentUser()}>{children}</CurrentUserContextProvider>
}
