import * as Yup from 'yup'

export const teacherCourseModuleSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().required(),
  description: Yup.string(),
  _destroy: Yup.boolean(),
  courseLectures: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      title: Yup.string().required(),
      description: Yup.string(),
      _destroy: Yup.boolean(),
      courseLecturePlayableScores: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          title: Yup.string().required(),
          playableScoreId: Yup.string().required(),
          playableScoreType: Yup.string().required(),
          rhfId: Yup.string(),
          _destroy: Yup.boolean(),
        }),
      ),
    }),
  ),
})

export const teacherCourseSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string(),
  courseModules: Yup.array().of(teacherCourseModuleSchema),
})

export type TeacherCourseFormValues = Yup.InferType<typeof teacherCourseSchema>
export type TeacherCourseModuleFormValues = Yup.InferType<typeof teacherCourseModuleSchema>

export const TEACHER_COURSE_INITIAL_VALUES = {
  title: 'New Course',
  description: 'Enter the course description.',
  courseModules: [
    {
      title: 'New Module',
      description: 'Enter the module description.',
      courseLectures: [
        {
          title: 'New Lecture',
          description: 'Enter the lecture description.',
        },
      ],
    },
  ],
}
