// @ts-nocheck params types
import { useState } from 'react'

import Location from 'app-location'
import axios, { AxiosResponse, Method } from 'axios'
import { deserialize } from 'deserialize-json-api'
import { useLocation, useNavigate } from 'react-router-dom'

import { AUTHORIZATION_KEY } from 'hooks'
import { ApiCall, ApiError } from 'hooks/useApi'
import { User } from 'models'
import { buildErrorMessage } from 'pages/auth/utils/buildErrorMessage'
import { useCurrentUserContext } from 'providers'

import { getHeaders } from './useApi/utils'

export const useAuth = (
  endpoint: Location,
  method: Method = 'POST',
): [ApiCall<User>, ApiError, boolean, number] => {
  const navigate = useNavigate()
  const location = useLocation()

  const { setCurrentUser } = useCurrentUserContext()

  const [error, setError] = useState<ApiError>()
  const [loading, setLoading] = useState<boolean>()
  const [status, setStatus] = useState<number>()

  const call = async body => {
    const apiUrl = process.env.REACT_APP_API_URL
    const url = `${apiUrl}${endpoint.toUrl()}`
    setLoading(true)

    try {
      const response: AxiosResponse<User> = await axios({ method, url, data: body, headers: getHeaders() })
      const token = response?.headers?.authorization || ''
      localStorage.setItem(AUTHORIZATION_KEY, token)

      const user = deserialize(response.data, { transformKeys: 'camelCase' }) as AxiosResponse<User>

      setCurrentUser(user?.data)
      setStatus(response.status)
      setLoading(false)

      navigate(location.state?.from || '/')

      return await Promise.resolve(response.data)
    } catch (e) {
      setError({ message: buildErrorMessage(e.response.data) })
      setStatus(e.response.status)
      setLoading(false)
    }
  }

  return [call, error, loading, status]
}
