import authState from 'store/auth/state'
// import composerState from 'store/composer/state'
import composerV2State from 'store/composerV2/state'
import customScoresState from 'store/customScores/state'
import favoriteScoresState from 'store/favoriteScores/state'
import foldersState from 'store/folders/state'
import globalState from 'store/global/state'

const appState = {
  ...globalState,
  ...authState,
  ...foldersState,
  ...customScoresState,
  ...favoriteScoresState,
  // ...composerState, TODO: remove
  ...composerV2State,
}

export default appState
