import {
  IconButton as MaterialIconButton,
  Rating as MaterialRating,
  RatingProps as MaterialRatingProps,
} from '@mui/material'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

type RatingProps = MaterialRatingProps & {
  error: boolean
}

export const Container = styled.div`
  right: 20px;
  bottom: 20px;
  position: fixed;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.background.paper};
`
export const IconButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    width: 48px;
    height: 48px;
    transform: scale(1);
    transition: transform ease-out 200ms;
    background: ${({ theme }) => theme.palette.secondary.main};

    &:hover {
      transform: scale(1.1);
      background: ${({ theme }) => theme.palette.secondary.main};
    }

    svg {
      stroke: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
`
export const CloseButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    top: 12px;
    right: 12px;
    position: absolute;

    svg {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`
export const Rating = styled(MaterialRating)<RatingProps>`
  &.MuiRating-root {
    margin: 16px 0;

    .MuiRating-iconEmpty {
      color: ${({ theme }) => lighten(0.2, theme.palette.background.paper)};
    }

    ${({ error }) =>
      error &&
      css`
      svg {
          stroke: ${({ theme }) => theme.palette.error.main};
        }
      }
    `}
  }
`
export const SubmitButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    width: 36px;
    height: 36px;
    right: 40px;
    bottom: 51px;
    position: absolute;
    background: ${({ theme }) => lighten(0.2, theme.palette.background.paper)};

    &:hover {
      background: ${({ theme }) => lighten(0.2, theme.palette.background.paper)};
    }

    svg {
      margin: 2px 2px 0px 0;
      stroke: ${({ theme }) => theme.palette.background.paper};
    }
  }
`
export const popoverStyles = {
  width: 350,
  height: 400,
}
