import { ReactNode } from 'react'

import { BoxProps, Button } from '@mui/material'

import { Container, HeaderBox } from './styles'

export type PageContainerProps = {
  title?: ReactNode
  primaryAction?: () => void
  fullWidth?: boolean
  gutterTop?: boolean
} & Omit<BoxProps, 'title'>

export const PageContainer = ({
  title,
  primaryAction,
  children,
  fullWidth,
  gutterTop,
  ...rest
}: PageContainerProps) => {
  return (
    <Container
      component="main"
      $gutterTop={gutterTop || !!title}
      $fullWidth={fullWidth}
      flexDirection="column"
      {...rest}
    >
      {title && (
        <HeaderBox component="header" $gutterBottom={!primaryAction}>
          <h2>{title}</h2>

          {primaryAction && (
            <Button size="small" variant="contained" sx={{ alignSelf: 'flex-end' }} onClick={primaryAction}>
              Add Course
            </Button>
          )}
        </HeaderBox>
      )}

      {children}
    </Container>
  )
}
