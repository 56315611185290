import { Chip, Autocomplete as MaterialAutocomplete, IconButton as MaterialIconButton } from '@mui/material'
import { transparentize } from 'polished'
import { BsFilter } from 'react-icons/bs'
import styled from 'styled-components'

export const Autocomplete = styled(MaterialAutocomplete)<{ value: string }>`
  &.MuiAutocomplete-root {
    width: 100%;
    border-radius: 5px;
    background: ${props => props.theme.palette.background.paper};

    .MuiInputBase-root {
      padding-right: ${({ value }) => (value?.length || value === '' ? 60 : 40)}px !important;
      transition:
        box-shadow 0.3s ease,
        padding-right ease-out 0.2s;

      input {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    &:not(.Mui-focused) {
      .MuiAutocomplete-inputRoot {
        padding-right: 40px !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.transparent.default};
    }

    .MuiFormLabel-root {
      color: ${({ theme }) => theme.palette.text.primary};
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.transparent.default};
      }

      .MuiInputBase-root {
        box-shadow:
          0px 11px 15px -7px rgb(0 0 0 / 20%),
          0px 24px 38px 3px rgb(0 0 0 / 14%),
          0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
      }

      .MuiAutocomplete-inputRoot {
        padding-right: ${({ value }) => (value?.length || value === '' ? 60 : 40)}px !important;
      }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .MuiAutocomplete-clearIndicator svg path,
  .MuiAutocomplete-popupIndicator svg path,
  .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiCircularProgress-root {
    margin-left: 8px;
  }
`
export const Listbox = styled.ul`
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${props => props.theme.palette.background.paper};
`
export const IconButton = styled(MaterialIconButton)`
  z-index: 10;
`
export const FilterIcon = styled(BsFilter)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
`
export const TotalCount = styled(Chip)`
  &.MuiChip-root {
    font-size: 14px;
    margin-right: 8px;
    color: ${({ theme }) => transparentize(0.2, theme.palette.text.primary)};
    border: 1px solid ${({ theme }) => transparentize(0.5, theme.palette.primary.main)};
    background: ${({ theme }) => transparentize(0.7, theme.palette.primary.main)};
  }
`
