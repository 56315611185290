import build from 'redux-object'

import { FavoriteScoreEndpoint } from 'routes/locations/favorite-scores'
import api from 'services/api'

import {
  updateFavoriteScoreEnd,
  updateFavoriteScoreError,
  updateFavoriteScoreStart,
  updateFavoriteScoreSuccess,
} from './creators'

export const updateFavoriteScore = (scoreId, params) => {
  return async dispatch => {
    dispatch(updateFavoriteScoreStart())

    const response = await api(FavoriteScoreEndpoint, 'PUT', params, {
      score_id: scoreId,
    })
    const { status, data, error } = response

    if (status !== 200) {
      const message = { ...errors.update, description: error }
      dispatch(updateFavoriteScoreError(message))
      return dispatch(updateFavoriteScoreEnd())
    }

    const score = build(data, 'favoriteScores')
    dispatch(updateFavoriteScoreSuccess(score))
    dispatch(updateFavoriteScoreEnd())
  }
}

const errors = {
  update: { title: 'Update Score Error!' },
}
