/* eslint-disable */
import { useState } from 'react'

export const useThrottle = (func: (args?: any) => void, delay: number) => {
  const [timeoutId, setTimeoutId] = useState<Nullable<NodeJS.Timeout>>(null)

  // @ts-ignore
  const throttledFunc = function (...args) {
    if (timeoutId) clearTimeout(timeoutId)

    const newTimeoutId = setTimeout(() => {
      // @ts-ignore
      func(...args)
      if (newTimeoutId === timeoutId) setTimeoutId(null)
    }, delay)

    setTimeoutId(newTimeoutId)
  }

  return throttledFunc
}
