import { MusicalElement } from '.'

export class Bar extends MusicalElement {
  constructor({ value }) {
    super({ value })
    this.abc = value
    this.type = 'bar'
  }

  toAbc() {
    return this.abc
  }

  /* eslint-disable class-methods-use-this */
  getElementsAfterCreate({ selectedElement, notes }) {
    if (selectedElement.constructor !== Bar) return

    return { notes, rests: [] }
  }

  replace(measure) {
    const { newElements } = measure
    if (this.isSkipReplace(measure)) return

    return newElements
  }

  isSkipReplace(measure) {
    /* Bars cannot be deleted and can only replaced by another bar */
    const newElement = measure.newElements[0]
    const isDeleteBar = !newElement
    const isNewBar = newElement?.type === 'bar'

    return isDeleteBar || !isNewBar
  }
}
