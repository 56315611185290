import { ChangeEvent, MouseEvent, useLayoutEffect, useState } from 'react'

import { useFavoriteScoresController } from 'controllers/favoriteScores'
import { useBreakpoint } from 'hooks'
import { useFavoriteScoreContext, useScoreContext } from 'providers'

import { fingerboardSettings } from '../components/Fingerboard/fingerboardSettings'

import { ScorePlayerControlsProps } from './types'

const COUNTDOWN_TIMER = 5

const ACTIVE_PANELS_BY_SCREEN_SIZE = {
  xl: ['violin', 'fingerboard', 'sheet-music'],
  lg: ['violin', 'fingerboard', 'sheet-music'],
  md: ['fingerboard', 'sheet-music'],
  sm: ['sheet-music'],
  xs: ['sheet-music'],
} as const

type ActivePanelsByScreenSize = keyof typeof ACTIVE_PANELS_BY_SCREEN_SIZE

type PlayerPanels = (typeof ACTIVE_PANELS_BY_SCREEN_SIZE.lg)[number]

export const useScorePlayerControls = ({
  audioPlayer,
  playerNotes,
  abcTune,
  tune,
  setTune,
}: ScorePlayerControlsProps) => {
  const { score } = useScoreContext()
  const { createFavoriteScore } = useFavoriteScoresController()
  const { favoriteScore, fetchFavoriteScore, destroyFavoriteScore } = useFavoriteScoreContext()

  const [isPlaying, setIsPlaying] = useState(false)

  const [isCountdownActive, setIsCountdownActive] = useState(false)
  const [countdown, setCountdown] = useState(COUNTDOWN_TIMER)
  const [isFingerboardPlaying, setIsFingerboardPlaying] = useState(false)

  const { breakpoint } = useBreakpoint()

  const [activePanels, setActivePanels] = useState<readonly PlayerPanels[]>(['sheet-music'])

  const { bpm, originalBpm } = abcTune
  const { getTriggerLineDuration } = fingerboardSettings({ bpm, originalBpm })

  useLayoutEffect(() => {
    if (!breakpoint) return
    setActivePanels(ACTIVE_PANELS_BY_SCREEN_SIZE[breakpoint as ActivePanelsByScreenSize])
  }, [breakpoint])

  const handlePlay = () => {
    setIsCountdownActive(true)

    setTimeout(() => {
      setIsFingerboardPlaying(true)
    }, countdown * 1000)

    setTimeout(
      () => {
        setIsPlaying(true)
        audioPlayer.handlePlay()
      },
      (countdown + getTriggerLineDuration()) * 1000,
    )
  }

  const handleStop = () => {
    setIsPlaying(false)
    setIsCountdownActive(false)
    setIsFingerboardPlaying(false)

    audioPlayer.handleStop()
  }

  const handleFinish = () => {
    setIsPlaying(false)
    setIsCountdownActive(false)
    setIsFingerboardPlaying(false)

    audioPlayer.state.handleFinish()
  }

  const handleCaption = () => {
    const tuneWithCaption = abcTune.toggleCaption(tune, playerNotes)
    setTune(tuneWithCaption)
  }

  const handleTranspose = (step: number) => {
    const transposedTune = abcTune.transpose(tune, step)
    setTune(transposedTune)
  }

  const handleChangeBpm = (bpmValue: number) => {
    const newTune = abcTune.changeBpm(tune, bpmValue)
    setTune(newTune)
  }

  const handleFavorite = async () => {
    const action = favoriteScore ? destroyFavoriteScore : createFavoriteScore
    await action({ favorite_score: { score_id: score.id } })
    fetchFavoriteScore()
  }

  const handlePanelVisibility = (_: MouseEvent<HTMLElement>, activeItems: PlayerPanels[]) => {
    if (!activeItems.length) return
    setActivePanels(activeItems)
  }

  const handleCountdown = (e: ChangeEvent<HTMLInputElement>) => {
    const timer = parseInt(e.target.value)
    if (timer < 0) return

    setCountdown(timer)
  }

  const synthOptions = {
    onEnded: handleFinish,
  }

  return {
    handlePlay,
    handleStop,
    handleCaption,
    handleTranspose,
    handleFavorite,
    handleChangeBpm,
    handlePanelVisibility,
    handleCountdown,
    isPlaying,
    isCountdownActive,
    isFingerboardPlaying,
    synthOptions,
    activePanels,
    countdown,
    setCountdown,
  }
}
