import { NOTE_FREQUENCIES } from 'utils'

const ACCURACY_PERCENTAGE_MARGIN_ERROR = 0.0018

export const getPointerAngle = tuner => {
  const noteName = `${tuner.note}${tuner.octave}` // A4
  const noteFrequency = NOTE_FREQUENCIES[noteName]?.frequency // 440
  if (!noteFrequency) return 0
  const accuracyPercentage = tuner.frequency / noteFrequency
  const accuracyPercentageWithError = getAccuracyPercentageWithError(accuracyPercentage)

  return normalizeAngle(accuracyPercentageWithError) * -1
}

const normalizeAngle = accuracyPercentage => {
  /* (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed 😎 */
  return ((0 - 90) * (accuracyPercentage - 0.9719)) / (1 - 0.9719) + 90
}

const getAccuracyPercentageWithError = accuracyPercentage => {
  const isInsideErrorMargin = Math.abs(1 - Math.abs(accuracyPercentage)) < ACCURACY_PERCENTAGE_MARGIN_ERROR
  return isInsideErrorMargin ? 1 : accuracyPercentage
}
