import { ToggleButton as MaterialToggleButton } from '@mui/material'
import styled from 'styled-components'

export const ToggleButton = styled(MaterialToggleButton)`
  &&.MuiToggleButton-root {
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
  }
`

export const drawerPaperSx = {
  width: '280px',
  padding: '0 16px',
}
export const listSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}
export const listItemSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'initial',
  gap: '24px',
}
export const textFieldsSx = {
  width: '60%',
  alignSelf: 'center',
}
export const switchSx = {
  top: 20,
  right: 0,
  position: 'absolute',
}
