import { AudioPlayerState } from './AudioPlayerState'
import { ReadyState } from './ReadyState'

export class PlayingState extends AudioPlayerState {
  handlePlay() {
    return null
  }

  handleStop() {
    this.player.changeState(new ReadyState(this.player))

    this.player.renderer.playButton.click()
    setTimeout(() => this.player.renderer.resetButton.click())
  }

  handleFinish() {
    this.player.changeState(new ReadyState(this.player))
  }
}
