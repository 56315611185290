import {
  CREATE_FOLDER_END,
  CREATE_FOLDER_ERROR,
  CREATE_FOLDER_START,
  CREATE_FOLDER_SUCCESS,
  DELETE_FOLDER_END,
  DELETE_FOLDER_ERROR,
  DELETE_FOLDER_START,
  DELETE_FOLDER_SUCCESS,
  FETCH_FOLDER_END,
  FETCH_FOLDER_ERROR,
  FETCH_FOLDER_START,
  FETCH_FOLDER_SUCCESS,
  RESET_FOLDERS,
  SET_FOLDER_TYPE,
  SET_SELECTED_FOLDER_END,
  SET_SELECTED_FOLDER_ERROR,
  SET_SELECTED_FOLDER_START,
  SET_SELECTED_FOLDER_SUCCESS,
  UPDATE_FOLDER_END,
  UPDATE_FOLDER_ERROR,
  UPDATE_FOLDER_START,
  UPDATE_FOLDER_SUCCESS,
} from './types'

export const fetchFolderStart = (folderId, loadingSelect) => ({
  type: FETCH_FOLDER_START,
  payload: { folderId, loadingSelect },
})
export const fetchFolderSuccess = normalized => ({
  type: FETCH_FOLDER_SUCCESS,
  payload: { normalized },
})
export const fetchFolderError = error => ({
  type: FETCH_FOLDER_ERROR,
  payload: error,
})
export const fetchFolderEnd = () => ({
  type: FETCH_FOLDER_END,
})

export const createFolderStart = parentId => ({
  type: CREATE_FOLDER_START,
  payload: { folderId: parentId, action: 'create' },
})
export const createFolderSuccess = () => ({
  type: CREATE_FOLDER_SUCCESS,
})
export const createFolderError = error => ({
  type: CREATE_FOLDER_ERROR,
  payload: error,
})
export const createFolderEnd = () => ({
  type: CREATE_FOLDER_END,
})

export const deleteFolderStart = parentId => ({
  type: DELETE_FOLDER_START,
  payload: { folderId: parentId, action: 'delete' },
})
export const deleteFolderSuccess = () => ({
  type: DELETE_FOLDER_SUCCESS,
})
export const deleteFolderError = error => ({
  type: DELETE_FOLDER_ERROR,
  payload: error,
})
export const deleteFolderEnd = () => ({
  type: DELETE_FOLDER_END,
})

export const updateFolderStart = parentId => ({
  type: UPDATE_FOLDER_START,
  payload: { folderId: parentId },
})
export const updateFolderSuccess = () => ({
  type: UPDATE_FOLDER_SUCCESS,
})
export const updateFolderError = error => ({
  type: UPDATE_FOLDER_ERROR,
  payload: error,
})
export const updateFolderEnd = () => ({
  type: UPDATE_FOLDER_END,
})

export const setSelectedFolderStart = id => ({
  type: SET_SELECTED_FOLDER_START,
  payload: { id },
})
export const setSelectedFolderSuccess = (folder, normalized) => ({
  type: SET_SELECTED_FOLDER_SUCCESS,
  payload: { folder, normalized },
})
export const setSelectedFolderError = error => ({
  type: SET_SELECTED_FOLDER_ERROR,
  payload: error,
})
export const setSelectedFolderEnd = () => ({
  type: SET_SELECTED_FOLDER_END,
})

export const setType = ({ type }) => ({
  type: SET_FOLDER_TYPE,
  payload: type,
})
export const reset = () => ({
  type: RESET_FOLDERS,
})
