import { AbcNotation } from 'components/Composer/models/types'

import { MusicalElementDecorator } from './MusicalElementDecorator'

export class MusicalElementSettingDecorator extends MusicalElementDecorator {
  get abcNotation(): AbcNotation {
    const elementAbc = this.musicalElementAbc()
    return `${this.settings.value}${elementAbc}` as AbcNotation
  }

  musicalElementAbc(): AbcNotation {
    return this.musicalElement.abcNotation
  }
}
