import { AbcVisualParams, DragTypes } from 'abcjs'

export type TimeSignature = { beatsPerMeasure: number; durationOfBeat: number; label?: string }

export const EMPTY_MEASURE = 'z8|'
export const EMPTY_STAFF = 'z8|z8|z8|z8|'
const DEFAULT_TIME_SIGNATURE: TimeSignature = { beatsPerMeasure: 4, durationOfBeat: 4, label: '4/4' }
const INITIAL_TIME_SIGNATURE = `${DEFAULT_TIME_SIGNATURE.beatsPerMeasure}/${DEFAULT_TIME_SIGNATURE.durationOfBeat}`

export const SELECTABLE_ELEMENTS: DragTypes[] = ['bar', 'note', 'ending', 'part', 'slur']

const INITIAL_TUNE_HEADER = `\
X: 1
T: Title
C: Composer
L: 1/8
M: ${INITIAL_TIME_SIGNATURE}
K: C
Q: 120\
`

export const INITIAL_TUNE = `\
  ${INITIAL_TUNE_HEADER}
  ${EMPTY_STAFF}
  ${EMPTY_STAFF}
  ${EMPTY_STAFF}
  ${EMPTY_STAFF}\
  `.replace(/  +/g, '')

export const INITIAL_COMPOSER_SETTINGS = {
  keySignature: 'C',
  octave: '4',
  duration: 0.25,
  timeSignature: DEFAULT_TIME_SIGNATURE,
} as const

export const INITIAL_COMPOSER_META = {
  title: 'Title',
  composer: 'Composer',
} as const

export const ABCJS_PARAMS: AbcVisualParams = {
  responsive: 'resize',
  format: {
    composerfont: 'Times New Roman 12 italic',
    stretchlast: true,
  },
  add_classes: true,
  selectTypes: SELECTABLE_ELEMENTS,
}
