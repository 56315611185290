import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { variables } from 'styles/variables'

export const Container = styled.div`
  min-height: calc(100vh - ${variables.topbarHeight}px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to left, rgba(0, 0, 0, 1) 15%, rgba(16, 19, 26, 1) 80%);
  background-size: auto 100%;
  background-position: right;
`
export const Background = styled.div`
  flex: 1;
  max-width: 30%;
  min-width: 380px;
  min-height: inherit;
  background: url(${variables.authBgUrl}) no-repeat;
  background-size: auto 100%;
  background-position: 70%;

  @media (max-width: 550px) {
    display: none;
  }
`
export const FormContainer = styled.div`
  width: 50%;
  min-width: 550px;
  display: flex;
  justify-content: center;

  @media (max-width: 550px) {
    width: 100%;
    min-width: unset;
  }
`
export const Form = styled.form`
  width: 400px;
  padding: 30px 40px;
  display: flex;
  /* gap: 0.8rem; */
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 1px 2px 35px rgba(0, 0, 0, 0.7);
`
export const Title = styled.h1`
  color: ${props => props.theme.palette.primary.main};
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
`
export const Link = styled(RouterLink)`
  margin-top: 0.5rem;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[500]};

  &:hover {
    text-decoration: underline;
  }
`
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
