import {
  AddCircleOutlined as AddCircleIcon,
  ExpandMore as ExpandMoreIcon,
  DeleteOutlineOutlined as TrashIcon,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  IconButton,
  List,
  Tooltip,
} from '@mui/material'
import { Controller, useFieldArray, UseFormReturn, useWatch } from 'react-hook-form'

import { EditableTextField } from 'components/ui'

import { TeacherCourseLectureCard } from '..'
import {
  TEACHER_COURSE_INITIAL_VALUES,
  TeacherCourseFormValues,
  TeacherCourseModuleFormValues,
} from '../teacherCourseSchema'

type Props = {
  courseModule: TeacherCourseModuleFormValues
  form: UseFormReturn<TeacherCourseFormValues>
  moduleIndex: number
  handleDeleteModule: (moduleIndex: number) => void
} & Omit<AccordionProps, 'children'>

export const TeacherCourseModuleFields = ({
  courseModule,
  moduleIndex,
  form,
  handleDeleteModule,
  ...rest
}: Props) => {
  const { control } = form

  const lectureFieldArray = useFieldArray({
    control,
    name: `courseModules.${moduleIndex}.courseLectures`,
  })

  const { fields, append, update } = lectureFieldArray

  const watchFieldArray = useWatch({ control, name: `courseModules.${moduleIndex}.courseLectures` })
  const lectureFields = fields.map((field, lectureIndex) => {
    return { ...field, ...watchFieldArray?.[lectureIndex] }
  })

  const handleAddLecture = () => {
    append(TEACHER_COURSE_INITIAL_VALUES.courseModules[0].courseLectures)
  }

  const handleDeleteLecture = (lectureIndex: number) => {
    update(lectureIndex, {
      ...lectureFields[lectureIndex],
      _destroy: true,
    })
  }

  if (!courseModule) return

  return (
    <Accordion {...rest}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Controller
          name={`courseModules.${moduleIndex}.title`}
          control={control}
          render={({ field }) => <EditableTextField inputProps={{ ...field }} />}
        />
      </AccordionSummary>

      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        {!!courseModule.description && (
          <Controller
            name={`courseModules.${moduleIndex}.description`}
            control={control}
            render={({ field }) => <EditableTextField inputProps={field} sx={{ mb: 2 }} />}
          />
        )}

        <Box display="flex" gap="8px" alignSelf="flex-end">
          <Tooltip title="Add Lecture" placement="top">
            <IconButton size="small" color="primary" onClick={handleAddLecture}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Module" placement="top">
            <IconButton size="small" color="primary" onClick={() => handleDeleteModule(moduleIndex)}>
              <TrashIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {!!lectureFields?.length && (
          <List>
            {lectureFields.map((lecture, lectureIndex) =>
              !lecture._destroy ? (
                <TeacherCourseLectureCard
                  form={form}
                  key={lecture.id}
                  moduleIndex={moduleIndex}
                  lectureIndex={lectureIndex}
                  handleDeleteLecture={handleDeleteLecture}
                />
              ) : (
                <span key={lecture.id} />
              ),
            )}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
