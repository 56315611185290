import { isEmpty } from 'utils'
import { humanize } from 'utils/string'

type ApiErrorResponse = {
  error: string | Record<string, string | string[]>
  errors: string | Record<string, string | string[]>
}

export const buildErrorMessage = (apiErrorResponse: ApiErrorResponse) => {
  const errorMessage = apiErrorResponse.errors || apiErrorResponse.error || apiErrorResponse

  if (typeof errorMessage === 'string') return errorMessage

  if (isEmpty(errorMessage)) return

  return Object.entries(errorMessage)
    .map(([key, value]) => `${humanize(key)} ${value[0]}`)
    .toString()
}
