export const QUARTER_NOTE_HEIGHT = 300
export const COUNTER_DELAY = 5
export const LOWEST_FREQUENCY = 196 /* G3 */
export const HIGHEST_FREQUENCY = 880 /* A5 */
export const EMPTY_STAFF = 'z8|z8|z8|z8|'
export const MEASURE_DURATION = 8

export const PITCH_DETECTOR_NOTES = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']
export const ALPHABETICAL_NOTATION = ['C', 'D', 'E', 'F', 'G', 'A', 'B']

export const TUNER_RANGE = {
  low: LOWEST_FREQUENCY - 90,
  high: HIGHEST_FREQUENCY + 300,
}

export const STRING_POSITIONS = {
  G: {
    0: [16, 0],
    l1: [15, 27],
    1: [15, 57],
    l2: [15, 87],
    2: [15, 117],
    3: [15, 147],
    l4: [14, 177],
    4: [14, 207],
  },
  D: {
    0: [23, 0],
    l1: [23, 27],
    1: [23, 57],
    l2: [23, 87],
    2: [23, 117],
    3: [21, 147],
    l4: [21, 177],
  },
  A: {
    0: [31, 0],
    l1: [32, 27],
    1: [32, 57],
    l2: [33, 87],
    2: [33, 117],
    3: [34, 147],
    l4: [34, 177],
    4: [35, 207],
  },
  E: {
    0: [38, 0],
    l1: [39, 27],
    1: [40, 57],
    l2: [41, 87],
    2: [42, 117],
    3: [43, 147],
    l4: [43, 177],
    4: [44, 207],
    l5: [-9999, -9999],
  },
}

export const SLIDER_STRINGS = {
  G: [67, 2],
  D: [124, 1],
  A: [176, -1],
  E: [234, -2],
}

export const NOTE_MAPPING = {
  // G
  G3: 'G.0',
  'G#3': 'G.l1',
  A3: 'G.1',
  'A#3': 'G.l2',
  B3: 'G.2',
  C4: 'G.3',
  'C#4': 'G.l4',
  // D
  D4: 'D.0',
  'D#4': 'D.l1',
  E4: 'D.1',
  F4: 'D.l2',
  'F#4': 'D.2',
  G4: 'D.3',
  'G#4': 'D.l4',
  // A
  A4: 'A.0',
  'A#4': 'A.l1',
  B4: 'A.1',
  C5: 'A.l2',
  'C#5': 'A.2',
  D5: 'A.3',
  'D#5': 'A.l4',
  // E
  E5: 'E.0',
  F5: 'E.l1',
  'F#5': 'E.1',
  G5: 'E.l2',
  'G#5': 'E.2',
  A5: 'E.3',
  'A#5': 'E.l4',
  B5: 'E.4',
  C6: 'E.l5',
  // outside violin range
  'C#6': 'E.l5',
  D6: 'E.l5',
  'D#6': 'E.l5',
  E6: 'E.l5',
  'F#6': 'E.l5',
  F6: 'E.l5',
  G6: 'E.l5',
  'G#6': 'E.l5',
  A6: 'E.l5',
  'A#6': 'E.l5',
  B6: 'E.l5',
  C7: 'E.l5',
  D7: 'E.l5',
  E7: 'E.l5',
  F7: 'E.l5',
  G7: 'E.l5',
  G2: 'E.l5',
  A2: 'E.l5',
  B1: 'E.l5',
  B2: 'E.l5',
  C2: 'E.l5',
  C3: 'E.l5',
  D2: 'E.l5',
  'D#2': 'E.l5',
  D3: 'E.l5',
  'D#3': 'E.l5',
  E2: 'E.l5',
  E3: 'E.l5',
  'F#2': 'E.l5',
  F3: 'E.l5',
  'F#3': 'E.l5',
}

export const SOUND_OPTIONS = [
  'acoustic_bass',
  'acoustic_grand_piano',
  'acoustic_guitar_nylon',
  'acoustic_guitar_steel',
  'banjo',
  'celesta',
  'clavinet',
  'dulcimer',
  'electric_grand_piano',
  'electric_piano_1',
  'electric_piano_2',
  'fx_3_crystal',
]

export const BARS = [
  { label: '𝄀', value: '|' },
  { label: '𝄁', value: '||' },
  { label: '𝄆', value: '|:' },
  { label: '𝄇', value: ':|' },
  { label: '𝄇𝄆', value: '::' },
  { label: '𝄂', value: '|]' },
  { label: '𝄃', value: '[|' },
]

export const RESTS = [
  { label: 'ë', value: 'z8', duration: '8', symbol: true },
  { label: 'ê', value: 'z4', duration: '4', symbol: true },
  { label: 'é', value: 'z2', duration: '2', symbol: true },
  { label: 'è', value: 'z1', duration: '1', symbol: true },
  { label: 'ç', value: 'z/2', duration: '0.5', symbol: true },
  { label: '𝅀', value: 'z/4', duration: '0.25' },
]

export const NOTE_DURATIONS = [
  { label: 'ä', value: '8', key: 1, symbol: true },
  { label: 'ã', value: '4', key: 2, symbol: true },
  { label: 'â', value: '2', key: 3, symbol: true },
  { label: 'á', value: '1', key: 4, symbol: true },
  { label: 'à', value: '1/2', key: 5, symbol: true },
  { label: '𝅘𝅥𝅰', value: '1/4', key: 6 },
]

export const ACCIDENTALS = [
  { label: 'î', value: '=', symbol: true, sign: '♮' },
  { label: 'ì', value: '^', symbol: true, sign: '♯' },
  { label: '𝄪', value: '^^', symbol: true, sign: '𝄪' },
  { label: 'í', value: '_', symbol: true, sign: '♭' },
  { label: 'íí', value: '__', symbol: true, sign: '𝄫' },
]

export const OCTAVES = [
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
]

export const TIME_SIGNATURES = [
  { label: '𝄴', value: 'C' },
  { label: '𝄵', value: 'C|' },
  { label: '4/4', value: '4/4' },
  { label: '3/4', value: '3/4' },
  { label: '2/4', value: '2/4' },
  { label: '6/8', value: '6/8' },
]

export const NOTE_OPTIONS = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
]

export const KEYS = [
  { label: 'C/Am', value: 'C' },
  { label: 'F/Dm', value: 'F' },
  { label: 'Bb/Gm', value: 'Bb' },
  { label: 'Eb/Cm', value: 'Eb' },
  { label: 'Ab/Fm', value: 'Ab' },
  { label: 'Db/Bbm', value: 'Db' },
  { label: 'Gb/Ebm', value: 'Gb' },
  { label: 'Cb/Abm', value: 'Cb' },
  { label: 'G/Em', value: 'G' },
  { label: 'D/Bm', value: 'D' },
  { label: 'A/F#m', value: 'A' },
  { label: 'E/C#m', value: 'E' },
  { label: 'B/G#m', value: 'B' },
  { label: 'F#/D#m', value: 'F#' },
  { label: 'C#/A#m', value: 'C#' },
]

export const MODIFIERS = [{ label: '.', value: '.', name: 'dot' }]

export const ARTICULATIONS = [
  { label: 'Π', value: 'v' },
  { label: 'V', value: 'u' },
]

export const OTHER_OPTIONS = [{ label: '☰', value: `\n${EMPTY_STAFF}` }]

export const VIOLIN_RANGE_NOTES = {
  // G
  G3: 'G,',
  'G#3': '^G,',
  A3: 'A,',
  'A#3': '^A,',
  B3: 'B,',
  C4: 'C',
  'C#4': '^C',
  // D
  D4: 'D',
  'D#4': '^D',
  E4: 'E',
  F4: 'F',
  'F#4': '^F',
  G4: 'G',
  'G#4': '^G',
  // A
  A4: 'A',
  'A#4': '^A',
  B4: 'B',
  C5: 'c',
  'C#5': '^c',
  D5: 'd',
  'D#5': '^d',
  // E
  E5: 'e',
  F5: 'f',
  'F#5': '^f',
  G5: 'g',
  'G#5': '^g',
  A5: 'a',
  'A#5': '^a',
}

export const NOTE_FREQUENCIES = {
  C0: { frequency: 16.35 },
  'C#0': { frequency: 17.32, alias: 'Db0' },
  D0: { frequency: 18.35 },
  'D#0': { frequency: 19.45, alias: 'Eb0' },
  E0: { frequency: 20.6 },
  F0: { frequency: 21.83 },
  'F#0': { frequency: 23.12, alias: 'Gb0' },
  G0: { frequency: 24.5 },
  'G#0': { frequency: 25.96, alias: 'Ab0' },
  A0: { frequency: 27.5 },
  'A#0': { frequency: 29.14, alias: 'Bb0' },
  B0: { frequency: 30.87 },
  C1: { frequency: 32.7 },
  'C#1': { frequency: 34.65, alias: 'Db1' },
  D1: { frequency: 36.71 },
  'D#1': { frequency: 38.89, alias: 'Eb1' },
  E1: { frequency: 41.2 },
  F1: { frequency: 43.65 },
  'F#1': { frequency: 46.25, alias: 'Gb1' },
  G1: { frequency: 49.0 },
  'G#1': { frequency: 51.91, alias: 'Ab1' },
  A1: { frequency: 55.0 },
  'A#1': { frequency: 58.27, alias: 'Bb1' },
  B1: { frequency: 61.74 },
  C2: { frequency: 65.41 },
  'C#2': { frequency: 69.3, alias: 'Db2' },
  D2: { frequency: 73.42 },
  'D#2': { frequency: 77.78, alias: 'Eb2' },
  E2: { frequency: 82.41 },
  F2: { frequency: 87.31 },
  'F#2': { frequency: 92.5, alias: 'Gb2' },
  G2: { frequency: 98.0 },
  'G#2': { frequency: 103.83, alias: 'Ab2' },
  A2: { frequency: 110.0 },
  'A#2': { frequency: 116.54, alias: 'Bb2' },
  B2: { frequency: 123.47 },
  C3: { frequency: 130.81 },
  'C#3': { frequency: 138.59, alias: 'Db3' },
  D3: { frequency: 146.83 },
  'D#3': { frequency: 155.56, alias: 'Eb3' },
  E3: { frequency: 164.81 },
  F3: { frequency: 174.61 },
  'F#3': { frequency: 185.0, alias: 'Gb3' },
  G3: { frequency: 196.0 },
  'G#3': { frequency: 207.65, alias: 'Ab3' },
  A3: { frequency: 220.0 },
  'A#3': { frequency: 233.08, alias: 'Bb3' },
  B3: { frequency: 246.94 },
  C4: { frequency: 261.63 },
  'C#4': { frequency: 277.18, alias: 'Db4' },
  D4: { frequency: 293.66 },
  'D#4': { frequency: 311.13, alias: 'Eb4' },
  E4: { frequency: 329.63 },
  F4: { frequency: 349.23 },
  'F#4': { frequency: 369.99, alias: 'Gb4' },
  G4: { frequency: 392.0 },
  'G#4': { frequency: 415.3, alias: 'Ab4' },
  A4: { frequency: 440.0 },
  'A#4': { frequency: 466.16, alias: 'Bb4' },
  B4: { frequency: 493.88 },
  C5: { frequency: 523.25 },
  'C#5': { frequency: 554.37, alias: 'Db5' },
  D5: { frequency: 587.33 },
  'D#5': { frequency: 622.25, alias: 'Eb5' },
  E5: { frequency: 659.25 },
  F5: { frequency: 698.46 },
  'F#5': { frequency: 739.99, alias: 'Gb5' },
  G5: { frequency: 783.99 },
  'G#5': { frequency: 830.61, alias: 'Ab5' },
  A5: { frequency: 880.0 },
  'A#5': { frequency: 932.33, alias: 'Bb5' },
  B5: { frequency: 987.77 },
  C6: { frequency: 1046.5 },
  'C#6': { frequency: 1108.73, alias: 'Db6' },
  D6: { frequency: 1174.66 },
  'D#6': { frequency: 1244.51, alias: 'Eb6' },
  E6: { frequency: 1318.51 },
  F6: { frequency: 1396.91 },
  'F#6': { frequency: 1479.98, alias: 'Gb6' },
  G6: { frequency: 1567.98 },
  'G#6': { frequency: 1661.22, alias: 'Ab6' },
  A6: { frequency: 1760.0 },
  'A#6': { frequency: 1864.66, alias: 'Bb6' },
  B6: { frequency: 1975.53 },
  C7: { frequency: 2093.0 },
  'C#7': { frequency: 2217.46, alias: 'Db7' },
  D7: { frequency: 2349.32 },
  'D#7': { frequency: 2489.02, alias: 'Eb7' },
  E7: { frequency: 2637.02 },
  F7: { frequency: 2793.83 },
  'F#7': { frequency: 2959.96, alias: 'Gb7' },
  G7: { frequency: 3135.96 },
  'G#7': { frequency: 3322.44, alias: 'Ab7' },
  A7: { frequency: 3520.0 },
  'A#7': { frequency: 3729.31, alias: 'Bb7' },
  B7: { frequency: 3951.07 },
  C8: { frequency: 4186.01 },
  'C#8': { frequency: 4434.92, alias: 'Db8' },
  D8: { frequency: 4698.63 },
  'D#8': { frequency: 4978.03, alias: 'Eb8' },
  E8: { frequency: 5274.04 },
  F8: { frequency: 5587.65 },
  'F#8': { frequency: 5919.91, alias: 'Gb8' },
  G8: { frequency: 6271.93 },
  'G#8': { frequency: 6644.88, alias: 'Ab8' },
  A8: { frequency: 7040.0 },
  'A#8': { frequency: 7458.62, alias: 'Bb8' },
  B8: { frequency: 7902.13 },
}

export const NOTE_SCALE = Object.keys(NOTE_FREQUENCIES)

export const selectables = ['bar', 'note', 'ending', 'part']

export const TEST_ABC = `D2|EB{c}BA B2 EB|~B2 AB dBAG|FDAD BDAD|FDAD dAFD|
EBBA B2 EB|B2 AB defg|afe^c dBAF|DEFD E2
gf|eB B2 efge|eB B2 gedB|A2 FA DAFA|A2 FA defg|
eB B2 eBgB|eB B2 defg|afe^c dBAF|DEFD E2
D2|EB{c}BA B2 EB|~B2 AB dBAG|FDAD BDAD|FDAD dAFD|
EBBA B2 EB|B2 AB defg|afe^c dBAF|DEFD E2
gf|eB B2 efge|eB B2 gedB|A2 FA DAFA|A2 FA defg|
eB B2 eBgB|eB B2 defg|afe^c dBAF|DEFD E2
D2|EB{c}BA B2 EB|~B2 AB dBAG|FDAD BDAD|FDAD dAFD|
EBBA B2 EB|B2 AB defg|afe^c dBAF|DEFD E2
gf|eB B2 efge|eB B2 gedB|A2 FA DAFA|A2 FA defg|
eB B2 eBgB|eB B2 defg|afe^c dBAF|DEFD E2
D2|EB{c}BA B2 EB|~B2 AB dBAG|FDAD BDAD|FDAD dAFD|
EBBA B2 EB|B2 AB defg|afe^c dBAF|DEFD E2
gf|eB B2 efge|eB B2 gedB|A2 FA DAFA|A2 FA defg|
eB B2 eBgB|eB B2 defg|afe^c dBAF|DEFD E2
D2|EB{c}BA B2 EB|~B2 AB dBAG|FDAD BDAD|FDAD dAFD|
EBBA B2 EB|B2 AB defg|afe^c dBAF|DEFD E2
gf|eB B2 efge|eB B2 gedB|A2 FA DAFA|A2 FA defg|
eB B2 eBgB|eB B2 defg|afe^c dBAF|DEFD E2`
