import { BoxProps } from '@mui/material'
import { IconType } from 'react-icons'
import { FiFileText } from 'react-icons/fi'

import { TypographyEllipsis } from '../TypographyEllipsis'

import { attachmentTypographySx, Container } from './styles'

type FileLinkProps = {
  fileName: string
  index?: number
  icon?: IconType
} & BoxProps

export const Attachment = ({ fileName, index = 0, icon: Icon = FiFileText, ...rest }: FileLinkProps) => {
  return (
    <Container $index={index} {...rest}>
      <Icon />
      <TypographyEllipsis lineClamp={2} sx={attachmentTypographySx}>
        {fileName}
      </TypographyEllipsis>
    </Container>
  )
}
