import { ButtonProps } from '@mui/material'
import { IconType } from 'react-icons'
import { FiFileText } from 'react-icons/fi'
import { Link, LinkProps } from 'react-router-dom'

import { Attachment } from '../Attachment'

import { ButtonLink } from './styles'

type FileLinkProps = {
  fileName: string
  index?: number
  icon?: IconType
} & ButtonProps &
  LinkProps

export const AttachmentLink = ({
  fileName,
  index = 0,
  icon: Icon = FiFileText,
  sx = {},
  ...rest
}: FileLinkProps) => {
  return (
    <ButtonLink component={Link} sx={{ width: 100, height: 120, ...sx }} $index={index} {...rest}>
      <Attachment fileName={fileName} icon={Icon} />
    </ButtonLink>
  )
}
