import { VIOLIN_RANGE_NOTES } from 'utils/settings'

export const convertToAbc = notes => notes?.map(note => VIOLIN_RANGE_NOTES[note])

export const convertToNoteName = abcNotes => {
  return abcNotes.map(note => Object.keys(VIOLIN_RANGE_NOTES).find(key => VIOLIN_RANGE_NOTES[key] === note))
}

export const buildQuerySearch = params => {
  const { query, notes, levels, exclusive } = params
  const queryStr = query ? `query=${query}` : ''
  const notesStr = notes?.length ? `&notes=${notes}` : ''
  const levelsStr = levels?.length ? `&levels=${levels}` : ''
  const exclusiveStr = exclusive ? `&exclusive=${exclusive}` : ''

  return `?${queryStr}${notesStr}${exclusiveStr}${levelsStr}`
}

export const convertQueryString = queryString => {
  const urlQuery = new URLSearchParams(queryString)
  const query = urlQuery.get('query')
  const exclusive = urlQuery.get('exclusive')
  const notes = urlQuery.get('notes')?.split(',') || []
  const levels = urlQuery.get('levels')?.split(',') || []

  const parsedNotes = notes
    .map((note, index) => (notes[index + 1] === '' ? `${note},` : note))
    .filter(note => note !== '')

  return { query, notes: parsedNotes, exclusive, levels }
}

export const hasQuery = query => Object.values(query).some(item => item?.length)
