import { Measure } from '..'
import { AbcComposition, AbcNotation } from '../types'

import { ElementReplacer, ElementReplacerConstructor } from '.'

export class SameDurationElementReplacer extends ElementReplacer {
  constructor(args: ElementReplacerConstructor) {
    super(args)
    this._measures = Measure.for(Measure.measures)
  }

  buildFromDuration(): AbcComposition {
    const { measures } = this
    const newElementsAbc = measures.map(measure => measure.abcNotation()).join('') as AbcNotation

    return [
      newElementsAbc,
      {
        startChar: this.startChar,
        endChar: this.endChar,
        lastElementEndChar: this.startChar + newElementsAbc.length,
      },
    ]
  }

  get endChar() {
    return this.maestro.selectedElements.at(-1)!.endChar
  }

  get startChar() {
    return this.maestro.selectedElements.at(0)!.startChar
  }
}
