import styled, { css } from 'styled-components'

export const Container = styled.div`
  max-width: 350px;
  height: 136vh;
  position: relative;
  flex: 0.6 1 33.333%;

  img {
    width: 100%;
  }
`
export const Bullet = styled.span<{ $active: boolean }>`
  width: 10px;
  height: 10px;
  left: -99999px;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  background: #fafafa;

  ${({ $active }) =>
    !$active &&
    css`
      opacity: 0;
    `}

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 7px;
    height: 7px;
  }
`
