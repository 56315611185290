import { ReactNode } from 'react'

import { CurrentUserProvider } from './CurrentUserProvider'
import { MobileProvider } from './MobileProvider'
import { StoreProvider } from './StoreProvider'
import { ThemeProvider } from './ThemeProvider'
import { ToastProvider } from './ToastProvider'

type Props = {
  children: ReactNode
}

export const AppProvider = ({ children }: Props) => (
  <StoreProvider>
    <CurrentUserProvider>
      <ThemeProvider>
        <MobileProvider>
          <ToastProvider>{children}</ToastProvider>
        </MobileProvider>
      </ThemeProvider>
    </CurrentUserProvider>
  </StoreProvider>
)
