import { APP_END, APP_ERROR, APP_LOADING } from 'store/global/types'

export const appLoading = () => ({
  type: APP_LOADING,
})
export const appError = error => ({
  type: APP_ERROR,
  payload: { error },
})
export const appEnd = () => ({
  type: APP_END,
})
