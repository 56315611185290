import { useCallback, useEffect } from 'react'

const useDragDrop = () => {
  const handleDragStart = useCallback(e => {
    const data = JSON.stringify({
      id: e.target.id,
      parentId: e.target.parentElement.closest('.MuiTreeItem-root')?.id,
      type: e.target.dataset.type,
    })

    e.dataTransfer.setData('text/plain', data)
    e.dataTransfer.dropEffect = 'move'
    e.target.classList.add('drag')
  }, [])

  const handleDragEnter = useCallback(e => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
    e.target.classList.add('drag-over')
  }, [])

  const handleDragOver = useCallback(e => {
    e.preventDefault()
  }, [])

  const handleDragLeave = useCallback(e => {
    e.preventDefault()
    if (e.currentTarget.contains(e.relatedTarget)) return
    e.target.classList.remove('drag-over')
  }, [])

  const handleDragEnd = useCallback(e => {
    e.preventDefault()

    const droppables = Array.from(document.querySelectorAll('.drag-over'))
    droppables.map(droppable => droppable.classList.remove('drag-over'))
    e.target.classList.remove('drag')
    e.target.classList.add('shrink')
  }, [])

  const init = useCallback(() => {
    const draggables = Array.from(document.querySelectorAll('[draggable]'))
    draggables.forEach(draggable => {
      draggable.addEventListener('dragstart', handleDragStart)
      draggable.addEventListener('dragover', handleDragOver)
      draggable.addEventListener('dragenter', handleDragEnter)
      draggable.addEventListener('dragleave', handleDragLeave)
      draggable.addEventListener('dragend', handleDragEnd)
    })
  }, [handleDragStart, handleDragOver, handleDragEnter, handleDragLeave, handleDragEnd])

  useEffect(() => {
    return () => {
      const draggables = Array.from(document.querySelectorAll('[draggable]'))
      draggables.forEach(draggable => {
        draggable.removeEventListener('dragstart', handleDragStart)
        draggable.removeEventListener('dragover', handleDragOver)
        draggable.removeEventListener('dragenter', handleDragEnter)
        draggable.removeEventListener('dragleave', handleDragLeave)
        draggable.removeEventListener('dragend', handleDragEnd)
      })
    }
  }, []) /* eslint-disable-line */

  return { init }
}

export default useDragDrop
