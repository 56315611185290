import { ReactNode } from 'react'

import { useParams } from 'react-router-dom'

import { useScoreController } from 'controllers'

import { createCtx } from './utils'

type Props = {
  children: ReactNode
}

type ScoreContext = ReturnType<typeof useScoreController>

export const [useScoreContext, ScoreContextProvider] = createCtx<ScoreContext>()

export const ScoreProvider = ({ children }: Props) => {
  const { scoreId } = useParams()

  return <ScoreContextProvider value={useScoreController(scoreId!)}>{children}</ScoreContextProvider>
}
