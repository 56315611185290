// @ts-nocheck type useAuthToken
import { useAuthToken } from 'hooks'
import { AppRole } from 'models'
import { asArray } from 'utils'

export const useUserRole = () => {
  const { currentUser } = useAuthToken()

  const hasRoleAccess = (accessRoles: Potential<AppRole | AppRole[]>) => {
    if (!accessRoles?.length) return true
    return currentUser?.roles.some(role => asArray(accessRoles).includes(role))
  }

  return { hasRoleAccess }
}
