import axios from 'axios'
import normalize from 'json-api-normalizer'

import { getHeaders, handleUnauthorized } from 'hooks/useApi/utils'

const api = async (path, method, body, params, localParams, options = {}) => {
  const { file } = options
  const url = `${process.env.REACT_APP_API_URL}${path.toUrl(params)}`
  let type = path._path.match(/[^:\w+]\w+/)[0].split('/')[1]
  type = method === 'POST' || method === 'PUT' ? type.slice(0, -1) : type
  const payload = file ? body : { [type]: body }

  try {
    const response = await axios({
      method: method || 'GET',
      url,
      data: payload,
      params: localParams,
      headers: getHeaders(),
    })

    const { data, status, headers } = response
    const normalizedData = normalize(data)

    return await Promise.resolve({
      data: normalizedData,
      status,
      meta: data.meta,
      headers,
    })
  } catch (error) {
    handleUnauthorized(error.response?.status)
    console.log(error) /* eslint-disable-line */
    const responseError = error.response?.data?.errors
    const isArray = responseError && Array.isArray(responseError)
    const message = isArray ? responseError[0] : responseError

    return Promise.resolve({
      error: message || error.message,
      status: error.response?.status,
    })
  }
}

export default api
