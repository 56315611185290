import { memo, useEffect, useRef, useState } from 'react'

import { ReactComponent as ViolinSvg } from 'assets/images/violin-with-finger-markers.svg'
import { MidiJsonNote } from 'pages/ScorePlayerPage/models'

import { useScorePlayerContext } from '../../providers'

import { Bullet, Container } from './styles'
import { Finger, MarkerPositionMapping, ViolinString } from './types'

const timerIds: NodeJS.Timeout[] = []
const markerPositions: MarkerPositionMapping = {}

export const Violin = memo(() => {
  const bulletRef = useRef<HTMLElement>(null)
  const [isBulletVisible, setIsBulletVisible] = useState(false)

  const { playerNotes, playerControls } = useScorePlayerContext()
  const { isPlaying, activePanels } = playerControls
  const isVisible = activePanels.includes('violin')
  const containerRect = bulletRef.current?.parentElement?.getBoundingClientRect()

  const setViolinMarkerPositions = (mapping: MarkerPositionMapping, marker: Element) => {
    const [string, finger] = marker.id.split('-') as [ViolinString, Finger]
    const elementRect = marker.getBoundingClientRect()

    mapping[string] = {
      ...mapping[string],
      [finger]: [elementRect.x, elementRect.y],
    }

    return mapping
  }

  const mapViolinElementsPosition = () => {
    const markerPos = document.querySelectorAll('.notes *')
    Array.from(markerPos).reduce(setViolinMarkerPositions, markerPositions)
  }

  const setBulletTimeout = (note: MidiJsonNote) => {
    const [markerPosX, markerPosY] = markerPositions[note.string!]![note.finger!] || []
    const leftPos = Math.abs((containerRect?.x || 0) - markerPosX!)

    const timerId = setTimeout(() => {
      if (!bulletRef.current) return

      const bulletRect = bulletRef.current?.getBoundingClientRect()
      bulletRef.current.style.top = `${markerPosY! - bulletRect!.height / 2}px`
      bulletRef.current.style.left = `${leftPos - bulletRect!.width / 2}px`
    }, note.time * 1000)

    timerIds.push(timerId)
  }

  const handlePlay = () => {
    setIsBulletVisible(true)
    playerNotes.forEach(setBulletTimeout)
  }

  const handleStop = () => {
    setIsBulletVisible(false)
    timerIds.map(clearTimeout)
  }

  const clearBulletTimeouts = () => {
    timerIds.forEach(clearTimeout)
  }

  useEffect(() => {
    if (!playerNotes.length || !isVisible) return

    mapViolinElementsPosition()
  }, [playerNotes, activePanels, isVisible])

  useEffect(() => {
    if (!isPlaying || !isVisible) return

    handlePlay()

    return clearBulletTimeouts
  }, [isPlaying])

  useEffect(() => {
    if (isPlaying) return

    handleStop()
  }, [isPlaying])

  if (!isVisible) return

  return (
    <Container>
      <ViolinSvg />

      <Bullet ref={bulletRef} $active={isBulletVisible} />
    </Container>
  )
})
