import { Box } from '@mui/material'
import styled, { css } from 'styled-components'

type Props = {
  $isEditMode?: boolean
}

export const Container = styled(Box)`
  width: 100%;
  position: relative;
  height: 100%;

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputBase-root {
    padding: 4px;
    font-size: inherit;
    text-align: inherit;
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.background.default};
    background: inherit;
  }
`
export const Text = styled.p<Props>`
  width: 100%;
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      opacity: 0;
      height: 0;
    `}
`
