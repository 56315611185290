import { Rest, SoundManager } from 'components/ScoreEditor/models'
import { RESTS } from 'utils'

import { filterNonRests, filterRests, sum } from '../helpers'

export class TempoManager {
  static calculateAvailableTempo = (emptyBeats, notes, index) => {
    if (emptyBeats < 0) {
      const rests = filterRests(notes, index)
      if (!rests.length) return SoundManager.play('error')

      const restsTempo = sum(rests.map(({ duration }) => duration))
      if (sum([emptyBeats, restsTempo]) < 0) return SoundManager.play('error')

      emptyBeats += restsTempo
      notes = filterNonRests(notes, index)
    }

    return { notes, emptyBeats }
  }

  static findAvailableTempos(duration) {
    const result = []
    let [tempo, nearest] = [duration, null]
    const durations = RESTS.map(rest => parseFloat(rest.duration))

    while (tempo > 0) {
      /* eslint-disable @typescript-eslint/no-loop-func */
      nearest = durations.find(dur => dur <= tempo)
      result.push(nearest)
      tempo -= nearest
    }

    return result.sort()
  }

  static createRestsfromTempo(tempo) {
    const availableTempo = TempoManager.findAvailableTempos(tempo)
    return availableTempo.map(duration => Rest.createfromDuration(duration))
  }
}
