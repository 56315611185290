import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined, LockOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { Button, Input } from 'components/ui'
import { useAuth } from 'hooks'
import { useToastContext } from 'providers'
import { SignInEndpoint } from 'routes/locations'

import { Background, Container, Footer, Form, FormContainer, Link, Title } from '../styles'

const schema = Yup.object().shape({
  email: Yup.string().required().email().label('Email'),
  password: Yup.string().required().label('Password'),
})

type SignInFormValues = Yup.InferType<typeof schema>

export const SignIn = () => {
  const [signIn, error, loading] = useAuth(SignInEndpoint)
  const { showToast } = useToastContext()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = useCallback(
    (data: SignInFormValues) => {
      signIn({ user: data })
    },
    [signIn],
  )

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: 'Sign in failed!',
      description: error.message,
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Sign In</Title>
          <Box display="flex" gap="0.8rem" flexDirection="column">
            <Input<SignInFormValues>
              type="email"
              name="email"
              label="Email"
              placeholder="Email"
              icon={EmailOutlined}
              control={control}
              autoComplete="username"
              errorMessage={errors.email?.message as string}
            />

            <Input
              type="password"
              name="password"
              label="Password"
              placeholder="Password"
              icon={LockOutlined}
              control={control}
              autoComplete="current-password"
              errorMessage={errors.password?.message as string}
            />
          </Box>
          <Button type="submit" sx={{ mt: '1rem' }} loading={loading}>
            Submit
          </Button>

          <Footer>
            <Link to="/forgot-password" style={{ opacity: 0.7 }}>
              Forgot password
            </Link>
            <Link to="/sign-up">Create an account</Link>
          </Footer>
        </Form>
      </FormContainer>

      <Background />
    </Container>
  )
}
