import { useCallback, useRef, useState } from 'react'

import { PitchDetector as Pitchy } from 'pitchy'

import { PITCH_DETECTOR_NOTES, TUNER_RANGE } from 'utils/settings'

import { AnimationFrame, TunerData } from './types'

const FPS = 7
const DEACTIVATE_TUNER_DELAY = 500
export const REQUEST_ANIMATION_DELAY = 1000 / FPS

export const useTuner = (range = TUNER_RANGE) => {
  const stream = useRef<MediaStream>()
  const animationFrame = useRef<AnimationFrame>({ frame: null, timeout: null })

  const [tuner, setTuner] = useState<Partial<TunerData>>({})

  const noteFromPitch = useCallback((frequency: number) => {
    const noteNum = 12 * (Math.log(frequency / 440.0) / Math.log(2))
    const noteFrequency = Math.round(noteNum) + 69
    const octave = Math.round(noteFrequency / 12 - 1)

    return {
      note: PITCH_DETECTOR_NOTES[noteFrequency % 12],
      octave,
    }
  }, [])

  const updatePitch = useCallback(
    (analyserNode: AnalyserNode, detector: Pitchy<Float32Array>, input: Float32Array, sampleRate: number) => {
      analyserNode.getFloatTimeDomainData(input)

      const [pitch, percentage] = detector.findPitch(input, sampleRate)
      const { note, octave } = noteFromPitch(pitch)
      const isInsideFrequencyRange = pitch >= range.low && pitch <= range.high

      isInsideFrequencyRange
        ? setTuner({
            note,
            octave,
            frequency: pitch,
            percentage,
            on: isInsideFrequencyRange,
          })
        : setTimeout(() => setTuner({ on: false }), DEACTIVATE_TUNER_DELAY)

      animationFrame.current.frame = window.requestAnimationFrame(() => {
        animationFrame.current.timeout = setTimeout(
          () => updatePitch(analyserNode, detector, input, sampleRate),
          REQUEST_ANIMATION_DELAY,
        )
      })
    },
    [noteFromPitch, range.low, range.high],
  )

  const deactivate = useCallback(() => {
    if (!stream.current) return

    stream.current.getTracks().forEach(track => track.stop())
    window.cancelAnimationFrame(animationFrame.current.frame!)
    window.clearTimeout(animationFrame.current.timeout!)
  }, [])

  const activate = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(mediaStream => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      const analyserNode = audioContext.createAnalyser()

      const sourceNode = audioContext.createMediaStreamSource(mediaStream)
      sourceNode.connect(analyserNode)
      const detector = Pitchy.forFloat32Array(analyserNode.fftSize)
      const input = new Float32Array(detector.inputLength)

      stream.current = mediaStream
      updatePitch(analyserNode, detector, input, audioContext.sampleRate)
    })
  }, [updatePitch])

  return { activate, deactivate, tuner }
}
