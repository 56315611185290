import { ReactNode } from 'react'

import { useCoursesController } from 'controllers'

import { createCtx } from '../utils'

type Props = {
  children: ReactNode
}

type CoursesContext = ReturnType<typeof useCoursesController>

export const [useCoursesContext, CoursesContextProvider] = createCtx<CoursesContext>()

export const CoursesProvider = ({ children }: Props) => {
  return <CoursesContextProvider value={useCoursesController()}>{children}</CoursesContextProvider>
}
