import { TempoManager } from '..'
import { AbcElementDuration, AbcNotation } from '../types'

import { MusicalElement } from '.'

export class Note extends MusicalElement {
  abcDuration: AbcElementDuration

  constructor(...args: ConstructorParameters<typeof MusicalElement>) {
    super(...args)
    this.abcDuration = this.setAbcDuration()
  }

  get abcNotation(): AbcNotation {
    return `${this.pitchByOctave}${this.abcDuration}` as AbcNotation
  }

  private setAbcDuration() {
    return TempoManager.durationNumberToAbcNotation(this.duration)
  }

  private get pitchByOctave() {
    let note
    const { octave } = this.settings

    switch (parseInt(octave!, 10)) {
      case 3:
        note = `${this.settings.value?.toUpperCase()},`
        break
      case 4:
        note = `${this.settings.value?.toUpperCase()}`
        break
      case 5:
        note = `${this.settings.value?.toLowerCase()}`
        break
      case 6:
        note = `${this.settings.value?.toLowerCase()}'`
        break
      default:
        note = this.settings.value
    }

    return note
  }
}
