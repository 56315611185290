import {
  ACCIDENTALS,
  ARTICULATIONS,
  BARS,
  DURATION_MODIFIERS,
  DURATIONS,
  KEY_SIGNATURES,
  MISCELLANEOUS,
  OCTAVES,
  PITCHES,
  RESTS,
  TIME_SIGNATURES,
} from 'components/Composer/constants'

export const COMPOSER_PANEL_MAPPING = {
  note: {
    label: 'Pitches',
    controls: PITCHES,
    handler: 'handleControlClick',
  },
  duration: {
    label: 'Durations',
    controls: DURATIONS,
    handler: 'handleDurationClick',
    icon: true,
  },
  rest: {
    label: 'Rests',
    controls: RESTS,
    handler: 'handleControlClick',
    icon: true,
  },
  modifiers: {
    label: 'Modifiers',
    controls: DURATION_MODIFIERS,
    handler: 'handleModifierClick',
    icon: true,
  },
  articulation: {
    label: 'Articulation',
    controls: ARTICULATIONS,
    handler: 'handleControlClick',
    icon: true,
  },
  accidental: {
    label: 'Accidentals',
    controls: ACCIDENTALS,
    handler: 'handleControlClick',
    icon: true,
  },
  octave: {
    label: 'Octaves',
    controls: OCTAVES,
    handler: 'handleControlAndSettingClick',
  },
  keySignature: {
    label: 'Key Signatures',
    controls: KEY_SIGNATURES,
    handler: 'handleTuneSettingClick',
    icon: true,
  },
  timeSignature: {
    label: 'Time Signatures',
    controls: TIME_SIGNATURES,
    handler: 'handleTimeSignatureClick',
    icon: true,
  },
  bar: {
    label: 'Bars',
    controls: BARS,
    handler: 'handleControlClick',
  },
  misc: {
    label: 'Misc',
    controls: MISCELLANEOUS,
    handler: 'handleControlClick',
  },
} as const
