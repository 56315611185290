import { TreeItem as MaterialTreeItem, TreeView as MaterialTreeView } from '@mui/lab'
import { List, ListItem } from '@mui/material'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { fadeSlideAnimation, shrink } from 'styles/keyframes'

export const Container = styled.section`
  display: flex;
`
export const Aside = styled.aside`
  min-width: 200px;
  max-width: 240px;
  margin-right: 35px;
  overflow-y: scroll;
  min-height: 70vh;

  .play-loader {
    width: 50%;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
`
export const FolderDisplayContainer = styled.div`
  width: 100%;

  > header {
    text-align: right;
  }
`
export const FolderDisplay = styled(List)`
  &.MuiList-root {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: 70vh;
    padding-left: 10px;
    border-left: 1px solid ${({ theme }) => theme.palette.background.paper};
    border-top: 1px solid ${({ theme }) => theme.palette.background.paper};
  }

  .play-loader {
    width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const iconStyles = css`
  &.MuiListItem-root {
    width: calc(20% - 8px);
    margin: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
    opacity: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    animation: ${() => fadeSlideAnimation()} 0.3s ${({ $index }) => ($index || 0) * 0.04}s forwards;

    svg {
      width: auto;
      stroke-width: 0.5px;
      color: ${({ theme }) => theme.palette.primary.main};
      fill: rgba(164, 18, 77, 0.1);
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      text-align: center;
    }

    &.drag-over {
      background-color: rgba(164, 18, 77, 0.1);
    }

    &.shrink {
      animation: ${shrink} 0.7s forwards;
    }

    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: rgba(164, 18, 77, 0.1);
      border: 1px solid rgba(164, 18, 77, 0.7);
    }

    .MuiInputBase-input:focus {
      z-index: 10;
    }

    .MuiTouchRipple-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    ${({ theme }) =>
      theme.palette.mode === 'dark' &&
      css`
        &:hover {
          background-color: rgba(0, 0, 0, 0.07);
        }
      `}
  }

  @media (max-width: 768px) {
    &.MuiListItem-root {
      width: calc(33.333% - 8px);
    }
  }
`
export const Folder = styled(ListItem)`
  ${iconStyles}

  &.MuiListItem-root {
    svg {
      min-height: 88px;
      height: 88px;
    }
  }
`
export const File = styled(ListItem)`
  ${iconStyles}

  &.MuiListItem-root {
    svg {
      height: 84px;
      margin-bottom: 8px;
    }
  }
`
export const Controls = styled.header`
  margin-left: -8px;
  padding-bottom: 4px;

  .MuiIconButton-root {
    margin-left: 12px;

    &:hover {
      background: ${({ theme }) => `rgba(164, 18, 77, ${theme.palette.mode === 'dark' ? 0.3 : 0.1})`};
    }

    &.Mui-disabled {
      svg {
        color: ${({ theme }) => lighten(0.1, theme.palette.background.paper)};
      }
    }

    svg {
      color: ${({ theme }) => theme.palette.text.primary};
      opacity: 1;
      transition: opacity 0.1s;

      &:not(.no-stroke) {
        stroke-width: 1.5px;
      }
    }
  }
`
export const TreeView = styled(MaterialTreeView)`
  .MuiTreeItem-root {
    &.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
    &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
      background-color: transparent;
    }
  }

  .MuiTreeItem-root[id=${props => props.$selected}] {
    > .MuiTreeItem-content .MuiTreeItem-label,
    &.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
    &.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
    &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
      background-color: ${({ theme }) => `rgba(164, 18, 77, ${theme.palette.mode === 'dark' ? 0.2 : 0.1})`};
    }
  }
`
export const TreeItem = styled(MaterialTreeItem)`
  position: relative;

  &.MuiTreeItem-root {
    opacity: 0;
    animation: ${() => fadeSlideAnimation()} 0.7s ${({ $index }) => ($index || 0) * 0.07}s forwards;

    &.drag {
      background-color: ${({ theme }) => `rgba(164, 18, 77, ${theme.palette.mode === 'dark' ? 0.2 : 0.1})`};

      .MuiTreeItem-iconContainer {
        opacity: 0;
      }
    }

    .MuiTreeItem-label {
      padding: 4px;
      border-radius: 4px;

      &.drag-over {
        background-color: ${({ theme }) => `rgba(164, 18, 77, ${theme.palette.mode === 'dark' ? 0.3 : 0.2})`};
      }
    }
  }
`
export const EmptyState = styled.div`
  top: 50%;
  left: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  transform: translate(-50%, -50%);

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.palette.background.paper};
  }

  svg path {
    stroke-width: 16px;
    color: ${({ theme }) => theme.palette.background.paper};
  }
`
export const MidiUploadInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
`
