import { useCallback, useEffect, useMemo, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { Feedbacker } from 'components'
import { DotLoader, Loader, PageContainer, Search } from 'components/ui'
import Card from 'components/ui/Card'
import { useScoreOptionsController, useScoresController } from 'controllers'
import { parameterize } from 'utils'

import Filters from './components/Filters'
import { buildQuerySearch, convertQueryString, convertToAbc, convertToNoteName, hasQuery } from './helpers'
import { Divider, Form, LevelMarker, loaderStyles, Section } from './styles'

const SCORE_CARD_TARGET = process.env.NODE_ENV === 'development' ? '' : '_blank'

export const scoreSearchSchema = Yup.object().shape({
  query: Yup.string(),
  exclusive: Yup.boolean(),
  notes: Yup.array().of(Yup.string()),
  levels: Yup.array().of(Yup.string()),
})

export type ScoreSearchFormValues = Yup.InferType<typeof scoreSearchSchema>

export const HomePage = () => {
  const perPage = 15
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [page, setPage] = useState(1)
  const [openFilter, setOpenFilter] = useState(false)

  const { fetchScores, scores, fetchScoresLoading, fetchScoresMeta } = useScoresController()
  const { fetchScoreOptions, scoreOptions, fetchScoreOptionsLoading } = useScoreOptionsController()

  const initialQuery = convertQueryString(location.search) as unknown as ScoreSearchFormValues // TODO: remove when util is typed
  const defaultValues = {
    ...initialQuery,
    query: initialQuery.query || '',
    exclusive: initialQuery.exclusive || false,
    notes: convertToNoteName(initialQuery.notes) as ScoreSearchFormValues['notes'],
  }

  const form = useForm<ScoreSearchFormValues>({ defaultValues })
  const { watch, control, setValue, handleSubmit: handleSubmitForm } = form
  const formValues = watch()

  const searchParams = useMemo(() => {
    const { notes, ...filters } = formValues
    const notesAbc = convertToAbc(notes)
    const validFilters = Object.keys(filters).reduce((acc, key) => {
      // @ts-ignore: util type
      if (filters[key]) acc[key] = filters[key]
      return acc
    }, {})

    return { ...validFilters, notes: notesAbc }
  }, [formValues])

  const onSubmit = useCallback(
    (data: ScoreSearchFormValues) => {
      const params = { ...data, notes: convertToAbc(data.notes), page: 1 }
      const search = buildQuerySearch(params)
      navigate({ pathname: '/', search })

      setPage(1)
      fetchScores({}, params)
    },
    [dispatch, navigate],
  )

  const handleScroll = useCallback(() => {
    const scrolledOffset = window.scrollY + window.innerHeight + 200
    const isBottom = document.body.scrollHeight < scrolledOffset
    const hasNextPage = fetchScoresMeta.next

    if (!isBottom || !hasNextPage || fetchScoresLoading) return

    setPage(page + 1)
    fetchScores({}, { ...searchParams, page: page + 1, per: perPage })
  }, [searchParams, page, fetchScoresLoading, fetchScoresMeta.next, dispatch])

  const handleFilterToggle = useCallback(() => {
    setOpenFilter(prevOpen => !prevOpen)
  }, [])

  useEffect(() => {
    hasQuery(initialQuery) && setTimeout(() => setOpenFilter(true), 300)
    fetchScores({}, initialQuery)
  }, [])

  useEffect(() => {
    if (formValues.query === undefined) {
      setValue('query', '')
      navigate({ pathname: '/' })
      return
    }
    if (formValues.query.length < 3) return

    const search = buildQuerySearch(searchParams)
    navigate({ pathname: '/', search })
    fetchScoreOptions({}, searchParams)
  }, [formValues.query])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <PageContainer title="Sheet Music">
      <Form onSubmit={handleSubmitForm(onSubmit)}>
        <div>
          <Controller
            name="query"
            control={control}
            render={({ field: { value, onChange, name } }) => (
              <Search
                name={name}
                value={value || ''}
                onChange={onChange}
                placeholder="Search sheet music by title or composer..."
                options={scoreOptions}
                loading={fetchScoreOptionsLoading}
                onEnter={handleSubmitForm(onSubmit)}
                onSelectOption={handleSubmitForm(onSubmit)}
                onFilterToggle={handleFilterToggle}
                meta={fetchScoresMeta}
              />
            )}
          />
        </div>

        <Filters open={openFilter} form={form} loading={fetchScoresLoading} />
      </Form>

      <Divider />

      <Section>
        {page === 1 && (fetchScoresLoading || !scores) ? (
          <Loader sx={loaderStyles} />
        ) : (
          <>
            {scores.map((sheet, index) => {
              const { title, composer, level } = sheet
              const { id } = sheet
              const hasComposer = composer.length && composer !== 'Traditional'
              const author = hasComposer ? `-${parameterize(composer)}` : ''
              const link = `/sheet-music/${parameterize(title)}${author}/${id}`

              return (
                <Card
                  key={`${sheet.id}${index}`} /* eslint-disable-line */
                  to={link}
                  index={index % perPage}
                  target={SCORE_CARD_TARGET}
                >
                  <LevelMarker $level={level} />
                  <p className="card-title">{title}</p>
                  <p className="card-composer">{composer}</p>
                </Card>
              )
            })}
          </>
        )}
        {page !== 1 && fetchScoresLoading && <DotLoader />}
      </Section>

      <Feedbacker />
    </PageContainer>
  )
}
