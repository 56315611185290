import { useEffect } from 'react'

import { useApi, useAuthToken } from 'hooks'
import { User } from 'models'
import { useCurrentUserContext, useToastContext } from 'providers'
import { UserEndpoint, UsersEndpoint } from 'routes/locations'

export const useUserAccountController = () => {
  const { showToast } = useToastContext()
  const { currentUser: user } = useAuthToken()
  const { setCurrentUser } = useCurrentUserContext()

  const [fetchCurrentUser, currentUser, { loading: fetchCurrentUserLoading }] = useApi<User>(
    UserEndpoint,
    'GET',
    {
      userId: user.id,
    },
  )
  const [
    updateDeviseCurrentUser,
    ,
    {
      error: updateDeviseCurrentUserError,
      loading: updateDeviseCurrentUserLoading,
      status: updateDeviseCurrentUserStatus,
    },
  ] = useApi<User>(UsersEndpoint, 'PUT')
  const [
    updatePassword,
    ,
    { error: updatePasswordError, loading: updatePasswordLoading, status: updatePasswordStatus },
  ] = useApi<User>(UsersEndpoint, 'PUT')
  const [
    updateCurrentUser,
    userWithAvatar,
    { error: updateCurrentUserError, loading: updateCurrentUserLoading, status: updateCurrentUserStatus },
  ] = useApi<User>(UserEndpoint, 'PUT', { userId: user.id })

  const isSuccess =
    updateDeviseCurrentUserStatus < 400 || updatePasswordStatus < 400 || updateCurrentUserStatus < 400
  const isError = updateDeviseCurrentUserError || updatePasswordError
  const errorMessage = updateDeviseCurrentUserError?.message || updatePasswordError?.message

  useEffect(() => {
    if (isSuccess) {
      showToast({
        type: 'success',
        title: 'Account update successful!',
        description: 'Your account info has been updated successfully',
      })
    }
  }, [isSuccess, showToast])

  useEffect(() => {
    if (isError) {
      showToast({
        type: 'error',
        title: 'Account update error!',
        description: errorMessage,
      })
    }
  }, [isError, errorMessage, showToast])

  useEffect(() => {
    if (!updateCurrentUserError) return

    showToast({
      type: 'error',
      title: 'Account update error!',
      description: updateCurrentUserError.message,
    })
  }, [updateCurrentUserError, showToast])

  useEffect(() => {
    if (updateCurrentUserStatus === 200) {
      setCurrentUser(userWithAvatar)
    }
  }, [updateCurrentUserStatus, userWithAvatar, setCurrentUser])

  return {
    fetchCurrentUser,
    currentUser,
    fetchCurrentUserLoading,
    updateDeviseCurrentUser,
    updateDeviseCurrentUserLoading,
    updateDeviseCurrentUserStatus,
    updatePassword,
    updatePasswordLoading,
    updatePasswordStatus,
    updateCurrentUser,
    updateCurrentUserLoading,
    updateCurrentUserStatus,
    updateCurrentUserError,
  }
}
