import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { resetFolders, setFolderType } from 'store/folders/actions'

const withFolderType = (Page, fileType) => () => {
  const dispatch = useDispatch()
  const { type } = useSelector(state => state.folders)

  useEffect(() => {
    dispatch(setFolderType(fileType))

    return () => dispatch(resetFolders())
  }, [fileType])

  return type ? <Page /> : null
}

export default withFolderType
