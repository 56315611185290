export const INIT_COMPOSER_START = 'INIT_COMPOSER_START'
export const INIT_COMPOSER_SUCCESS = 'INIT_COMPOSER_SUCCESS'
export const INIT_COMPOSER_END = 'INIT_COMPOSER_END'

export const SET_COMPOSER_TUNE = 'SET_COMPOSER_TUNE'
export const SET_COMPOSER_SETTING = 'SET_COMPOSER_SETTING'

export const SET_CLIPBOARD = 'SET_CLIPBOARD'

export const SET_COMPOSER_META = 'SET_COMPOSER_META'

export const RESET_COMPOSER = 'RESET_COMPOSER'
