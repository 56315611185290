import { Theme } from '@mui/material/styles'
import { animated } from 'react-spring'
import styled, { css } from 'styled-components'

import { ToastMessage } from 'hooks'

import { ToastContentProps } from '.'

const TOAST_VARIANT_STYLES = (theme: Theme) =>
  ({
    info: {
      color: `${theme.palette.info.contrastText}`,
      background: `${theme.palette.info.main}`,
    },
    success: {
      color: `${theme.palette.success.contrastText}`,
      background: `${theme.palette.success.main}`,
    },
    error: {
      color: `${theme.palette.error.contrastText}`,
      background: `${theme.palette.error.main}`,
    },
  }) as const

type ContentProps = {
  type: string
  $hasDescription: number
} & Pick<ToastContentProps, 'style'>

export const Content = styled(animated.div)<ContentProps>`
  width: 360px;
  padding: 16px 30px 16px 16px;
  display: flex;
  border-radius: 10px;
  position: relative;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  ${({ theme, type }) => {
    const key = (type || 'info') as ToastMessage['type']
    return TOAST_VARIANT_STYLES(theme)[key]
  }}

  & + div {
    margin-top: 8px;
  }

  > svg {
    margin: 4px 12px 00;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    right: 8px;
    top: 8px;
    opacity: 0.6;
    border: 0;
    position: absolute;
    color: inherit;
    background: transparent;

    &:focus {
      outline: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }

  ${({ $hasDescription }) =>
    !$hasDescription &&
    css`
      align-items: center;

      > svg {
        margin-top: 0;
      }
    `}
`
