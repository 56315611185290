import styled from 'styled-components'

import { animateSuccessLong, animateSuccessTip, rotatePlaceholder } from './keyframes'
import { containerStyles, Fix, iconStyles, placeholderStyles } from './sharedStyles'

const SuccessIcon = () => {
  return (
    <Container>
      <CheckTip />
      <CheckLine />

      <Placeholder />
      <Fix />
    </Container>
  )
}

export default SuccessIcon

const Container = styled.div`
  ${containerStyles}

  &:after {
    animation: ${rotatePlaceholder} 4.25s ease-in;
  }
`
const CheckTip = styled.span`
  ${iconStyles}

  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  animation: ${animateSuccessTip} 0.75s;
`
const CheckLine = styled.span`
  ${iconStyles}

  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
  animation: ${animateSuccessLong} 0.75s;
`
const Placeholder = styled.div`
  ${placeholderStyles}
`
