import { CourseLecturePlayableScore, CustomScore, Score } from 'models'

export class CourseLecture {
  id: Id
  title: string
  description: string
  videoUrl: string
  playableScores: Score[] | CustomScore[] = []
  courseLecturePlayableScores: CourseLecturePlayableScore[] = []

  constructor(courseLectureParams: CourseLecture) {
    Object.assign(this, {
      ...courseLectureParams,
    })
  }
}
