const foldersState = {
  folders: {
    data: [],
    root: null,
    error: null,
    normalized: {},
    selectedFolder: {},
    loadingId: null,
    loadingAction: null,
    type: null,
  },
}

export default foldersState
