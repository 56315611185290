import { useCallback, useEffect, useState } from 'react'

import { ClickListener } from 'abcjs'
import { useDispatch, useSelector } from 'react-redux'

import { DURATIONS } from 'components/Composer/constants/controls'
import { maestro } from 'components/Composer/hooks'
import { TextAreaSelection } from 'components/Composer/models/types'
import { paste, setClipboard, setComposerTune } from 'store/composerV2/actions'
import { RootState } from 'store/rootReducer'
import { modulo } from 'utils'

import { ABCJS_PARAMS, ALL_PITCHES } from '../constants'
import { MusicalElement } from '../models'

import { useComposer } from '.'

export const useComposerKeyboard = (composerHandlers: ReturnType<typeof useComposer>['handlers']) => {
  const dispatch = useDispatch()
  const [currentSelection, setCurrentSelection] = useState<Potential<TextAreaSelection>>()
  const { present: composerState } = useSelector((state: RootState) => state.composerV2)

  const { handleControlClick, handleSettingClick, handleUndo, handleRedo, handleComposerSaveClick } =
    composerHandlers

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.target as HTMLInputElement)?.localName === 'input') return

      if (e.ctrlKey || e.metaKey) {
        switch (e.key) {
          case 'c': {
            dispatch(setClipboard())
            break
          }
          case 'v': {
            dispatch(paste())
            break
          }
          case 's': {
            e.preventDefault()
            handleComposerSaveClick()
            break
          }
          case 'z': {
            e.preventDefault()
            if (e.shiftKey) {
              handleRedo()
              break
            }
            handleUndo()
            break
          }

          default:
        }

        return
      }

      switch (e.key) {
        case 'Shift': {
          const selection = maestro.getTextareaSelection()
          setCurrentSelection(selection)
          break
        }

        case 'a':
        case 'b':
        case 'c':
        case 'd':
        case 'e':
        case 'f':
        case 'g': {
          handleControlClick({ value: e.key, label: '' }, 'note')
          break
        }

        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7': {
          const keyCode = parseInt(e.key, 10)
          const duration = DURATIONS[keyCode - 1]
          const settings = { value: duration.duration.toString(), label: duration.label }
          handleSettingClick(settings, 'duration')
          break
        }

        case 'ArrowLeft':
        case 'ArrowRight': {
          e.preventDefault()
          handleArrowSideways(e.key)
          break
        }

        case 'ArrowUp':
        case 'ArrowDown': {
          e.preventDefault()
          handleTranspose(e.key)
          break
        }

        default:
      }
    },
    [composerState],
  )

  const handleElementClick = (...args: AbcjsCustomClickListenerParams) => {
    const [abcelem, _tuneNumber, _classes, _analysis, _drag, e, selection] = args
    const { startChar, endChar } = abcelem
    const start = e.shiftKey ? selection.start : startChar
    maestro.setTextareaSelection(start, endChar)
  }

  const handleArrowSideways = (key: 'ArrowLeft' | 'ArrowRight') => {
    const offset = key === 'ArrowRight' ? 1 : -1
    const selectedElement = maestro.getElementFromChar(maestro.selectedElement.startChar)
    const selectedElementIndex = maestro.getElementIndex(selectedElement.abselem.abcelem)
    const sideElement = maestro.selectableElements[selectedElementIndex + offset]

    if (!sideElement) return

    maestro.setTextareaSelection(sideElement.elem.abcelem.startChar, sideElement.elem.abcelem.endChar)
  }

  const handleTranspose = (key: 'ArrowUp' | 'ArrowDown') => {
    const step = key === 'ArrowUp' ? 1 : -1
    const selectedRange = maestro.getTextareaSelection()
    const selectedElements = MusicalElement.convertAbcjsToElementsSettings(maestro.selectedElements)

    const transposedElements = selectedElements.map(element => {
      if (element.type !== 'note') return element
      const index = ALL_PITCHES.findIndex(pitch => pitch === element.value)
      const transposedPitch = ALL_PITCHES[modulo(index + step, ALL_PITCHES.length)]
      if (!transposedPitch) return element
      return { ...element, value: transposedPitch }
    })

    const composition = maestro.compose(transposedElements)
    if (!composition) return

    maestro.write(composition)
    dispatch(setComposerTune(maestro.textarea.value))
    setTimeout(() => maestro.selectElementsInRange(selectedRange.start, composition[1].lastElementEndChar))
  }

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown)
    return () => document.body.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  useEffect(() => {
    if (!currentSelection) return
    maestro.abcjsEditor.current?.paramChanged({
      ...ABCJS_PARAMS,
      // @ts-ignore event not in abcjs function signature
      clickListener: (...args: [...AbcjsClickListenerParams]) =>
        handleElementClick(...args, currentSelection),
    })
  }, [currentSelection])

  return { currentSelection }
}

type AbcjsClickListenerParams = [
  Parameters<ClickListener>[0],
  Parameters<ClickListener>[1],
  Parameters<ClickListener>[2],
  Parameters<ClickListener>[3],
  Parameters<ClickListener>[4],
  MouseEvent,
]

type AbcjsCustomClickListenerParams = [...AbcjsClickListenerParams, TextAreaSelection]
