import { Route, Routes } from 'react-router-dom'

import Composer from 'pages/Composer'
import CustomScores from 'pages/CustomScores'
import FavoriteScores from 'pages/FavoriteScores'
import { HomePage } from 'pages/HomePage'
import { ScorePlayerPage } from 'pages/ScorePlayerPage'
import TunerPage from 'pages/TunerPage'
import { UserAccountPage } from 'pages/UserAccountPage'
import { ConfirmEmail, ForgotPassword, ResetPassword, SignIn, SignUp } from 'pages/auth'
import { CourseLecturePage, CoursePage, CoursesPage } from 'pages/courses'
import NotFound from 'pages/errors/NotFound'
import { TeacherCoursePage, TeacherCoursesPage } from 'pages/teachers'

import { RouteManager } from './RouteManager'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RouteManager isPublic />}>
        <Route index element={<HomePage />} />
      </Route>

      <Route path="/tuner" element={<RouteManager isPublic />}>
        <Route index element={<TunerPage />} />
      </Route>

      <Route path="/sheet-music/:scoreTitle?/:scoreId" element={<RouteManager isPublic />}>
        <Route index element={<ScorePlayerPage />} />
      </Route>

      <Route path="/composer" element={<RouteManager />}>
        <Route index element={<Composer />} />
        <Route path=":scoreId" element={<Composer />} />
      </Route>

      <Route path="/me" element={<RouteManager />}>
        <Route index element={<UserAccountPage />} />
        <Route path="sheet-music" element={<CustomScores />} />
        <Route path="favorites" element={<FavoriteScores />} />
      </Route>

      <Route path="/sign-in" element={<RouteManager auth />}>
        <Route index element={<SignIn />} />
      </Route>
      <Route path="/sign-up" element={<RouteManager auth />}>
        <Route index element={<SignUp />} />
      </Route>
      <Route path="/forgot-password" element={<RouteManager auth />}>
        <Route index element={<ForgotPassword />} />
      </Route>
      <Route path="/reset-password" element={<RouteManager auth />}>
        <Route index element={<ResetPassword />} />
      </Route>
      <Route path="/confirm-email" element={<RouteManager isPublic />}>
        <Route index element={<ConfirmEmail />} />
      </Route>

      <Route path="/courses/violin" element={<RouteManager isPublic />}>
        <Route index element={<CoursesPage />} />
        <Route path=":courseTitle?/:courseId">
          <Route index element={<CoursePage />} />
          <Route path="lectures/:courseLectureTitle?/:courseLectureId" element={<CourseLecturePage />} />
        </Route>
      </Route>

      <Route path="/teacher/courses" element={<RouteManager roles={['teacher']} />}>
        <Route index element={<TeacherCoursesPage />} />
        <Route path=":teacherCourseTitle?/:teacherCourseId" element={<TeacherCoursePage />} />
        <Route path="new" element={<TeacherCoursePage />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
