import { getElementsDuration } from '../../helpers'

import { ElementReplacerOld } from '.'

export class LargerDurationReplacer extends ElementReplacerOld {
  static replace(measure) {
    const { newElements, nextElementsDuration, selectedDuration, finalBar } = measure
    const canReplace = selectedDuration + nextElementsDuration >= getElementsDuration(newElements)

    if (!canReplace) return ElementReplacerOld.triggerError()

    const rearrangedNextElements = measure.rearrangeNextElements()
    const rests = measure.getRestsToFillEmptyBeats()

    return [...newElements, ...rearrangedNextElements, ...rests, finalBar]
  }
}
