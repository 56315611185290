import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { AppRoutes } from 'routes'

export const Container = () => {
  const error = useSelector(state => state.global.error)

  useEffect(() => {
    if (error) throw Error()
  }, [error])

  return <AppRoutes />
}
