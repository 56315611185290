/* eslint-disable class-methods-use-this */
import { TempoManager } from '..'

import { Bar, GeneralElement, Note, Rest } from '.'

export class MusicalElement {
  constructor({ value }) {
    this.value = value
    this.abc = value
  }

  static for({ type, value, settings }) {
    let MusicalElementClass

    switch (type) {
      case 'note':
        MusicalElementClass = Note
        break
      case 'rest':
        MusicalElementClass = Rest
        break
      case 'bar':
        MusicalElementClass = Bar
        break
      default:
        MusicalElementClass = GeneralElement
        break
    }

    return new MusicalElementClass({ type, value, settings })
  }

  replace(measure) {
    return measure.replace()
  }

  getElementsAfterCreate({ notes, selectedElement, index, emptyBeats }) {
    if (selectedElement.constructor === Bar) return

    const availableTempo = TempoManager.calculateAvailableTempo(emptyBeats, notes, index)

    if (!availableTempo) return

    const rests = TempoManager.createRestsfromTempo(availableTempo.emptyBeats)
    return { notes: availableTempo.notes, rests }
  }
}
