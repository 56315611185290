import { VoiceItemNote } from 'abcjs'

import { Measure, TempoManager } from '..'
import { AbcComposition, AbcNotation } from '../types'

import { ElementReplacer, ElementReplacerConstructor } from '.'

export class SmallerDurationElementReplacer extends ElementReplacer {
  constructor(args: ElementReplacerConstructor) {
    super(args)
    this._measures = Measure.for(Measure.measures)
  }

  buildFromDuration(): AbcComposition {
    const { selectedElement } = this.maestro
    const { measures } = this
    const newElementsAbc = measures.map(measure => measure.abcNotation()).join('') as AbcNotation
    const composition = `${newElementsAbc}${this.rests?.join('')}` as AbcNotation

    return [
      composition,
      {
        startChar: this.startChar,
        endChar: this.endChar,
        lastElementEndChar: selectedElement.startChar + newElementsAbc.length,
      },
    ]
  }

  get rests() {
    const { newElements, selectedElement } = this.maestro
    const emptyBeats = selectedElement.duration - TempoManager.getElementsDuration(newElements)
    return TempoManager.getRestsFromBeats(emptyBeats)
  }

  get endChar() {
    const { selectedElements, newElements } = this.maestro
    const newElementsDuration = TempoManager.getElementsDuration(newElements)
    const elementsToReplace = TempoManager.getElementsForDuration(
      selectedElements,
      newElementsDuration,
    ) as VoiceItemNote[]

    return elementsToReplace.at(-1)!.endChar
  }
}
