import { ChangeEvent, ClipboardEvent, useRef } from 'react'

import { useSelector } from 'react-redux'

import { RootState } from 'store/rootReducer'

import { ComposerTopbar } from './components'
import { useComposer, useComposerKeyboard } from './hooks'
import {
  ComposerAudio,
  ComposerInput,
  SheetMusic,
  SheetMusicContainer,
  Textarea,
  TextareaContainer,
  TitleInput,
} from './styles'

import 'abcjs/abcjs-audio.css'

export const Composer = () => {
  const displayRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const audioRef = useRef<HTMLDivElement>(null)

  const { present: composerState } = useSelector((state: RootState) => state.composerV2)

  const composer = useComposer(displayRef, textareaRef, audioRef)
  const { handlers } = composer
  const { title, composer: author } = composerState.meta

  useComposerKeyboard(handlers)

  const handleChangeMeta = (e: ChangeEvent<HTMLInputElement>, metaName: 'title' | 'composer') => {
    handlers.handleTuneSettingClick({ value: e.target.value, label: '' }, metaName)
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    const isTextInput = e.relatedTarget instanceof HTMLInputElement && e.relatedTarget.type === 'text'
    if (isTextInput) return

    e.target.focus()
  }

  return (
    <>
      <ComposerTopbar composer={composer} />
      <SheetMusicContainer>
        <TitleInput
          placeholder="Title"
          value={title}
          size={title.length || 5}
          onChange={e => handleChangeMeta(e, 'title')}
          title="Title"
        />
        <ComposerInput
          placeholder="Composer"
          value={author}
          size={author.length + 2}
          onChange={e => handleChangeMeta(e, 'composer')}
        />

        <SheetMusic ref={displayRef} />

        <TextareaContainer>
          <Textarea
            id="editor-textarea"
            ref={textareaRef}
            spellCheck="false"
            title="composer-textarea"
            onBlur={handleBlur}
            {...textareaClipboardLockProps}
          />
        </TextareaContainer>

        <ComposerAudio ref={audioRef} id="composer-audio" />
      </SheetMusicContainer>
    </>
  )
}

const textareaClipboardLockProps =
  process.env.NODE_ENV === 'production'
    ? {
        onPaste: (e: ClipboardEvent<HTMLTextAreaElement>) => e.preventDefault(),
        onCopy: (e: ClipboardEvent<HTMLTextAreaElement>) => e.preventDefault(),
        onCut: (e: ClipboardEvent<HTMLTextAreaElement>) => e.preventDefault(),
        readOnly: true,
      }
    : {}
