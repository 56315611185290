import { APP_END, APP_ERROR, APP_LOADING } from 'store/global/types'
import initialState from 'store/state'

const globalReducer = (state = initialState.global, action) => {
  switch (action.type) {
    case APP_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case APP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case APP_END:
      return {
        ...state,
        loading: false,
        error: null,
      }

    default:
      return state
  }
}

export default globalReducer
