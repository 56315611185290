import { useTheme } from 'styled-components'

import { ReactComponent as DoubleFlat } from 'assets/images/composer/accidentals/double-flat.svg'
import { ReactComponent as DoubleSharp } from 'assets/images/composer/accidentals/double-sharp.svg'
import { ReactComponent as Flat } from 'assets/images/composer/accidentals/flat.svg'
import { ReactComponent as Natural } from 'assets/images/composer/accidentals/natural.svg'
import { ReactComponent as Sharp } from 'assets/images/composer/accidentals/sharp.svg'
import { ReactComponent as UpBow } from 'assets/images/composer/articulation/down-bow.svg'
import { ReactComponent as Slur } from 'assets/images/composer/articulation/slur.svg'
import { ReactComponent as DownBow } from 'assets/images/composer/articulation/up-bow.svg'
import { ReactComponent as AMajorKey } from 'assets/images/composer/keySignatures/A-Fsharpm.svg'
import { ReactComponent as AFlatMajorKey } from 'assets/images/composer/keySignatures/Ab-Fm.svg'
import { ReactComponent as BMajorKey } from 'assets/images/composer/keySignatures/B-Gsharpm.svg'
import { ReactComponent as BFlatMajorKey } from 'assets/images/composer/keySignatures/Bb-Gm.svg'
import { ReactComponent as CMajorKey } from 'assets/images/composer/keySignatures/C-Am.svg'
import { ReactComponent as CFlatMajorKey } from 'assets/images/composer/keySignatures/Cb-Abm.svg'
import { ReactComponent as CSharpMajorKey } from 'assets/images/composer/keySignatures/Csharp-Asharpm.svg'
import { ReactComponent as DMajorKey } from 'assets/images/composer/keySignatures/D-Bm.svg'
import { ReactComponent as DFlatMajorKey } from 'assets/images/composer/keySignatures/Db-Bbm.svg'
import { ReactComponent as EMajorKey } from 'assets/images/composer/keySignatures/E-Csharpm.svg'
import { ReactComponent as EFlatMajorKey } from 'assets/images/composer/keySignatures/Eb-Cm.svg'
import { ReactComponent as FMajorKey } from 'assets/images/composer/keySignatures/F-Dm.svg'
import { ReactComponent as FSharpMajorKey } from 'assets/images/composer/keySignatures/Fsharp-Dsharpm.svg'
import { ReactComponent as GMajorKey } from 'assets/images/composer/keySignatures/G-Em.svg'
import { ReactComponent as GFlatMajorKey } from 'assets/images/composer/keySignatures/Gb-Ebm.svg'
import { ReactComponent as Dot } from 'assets/images/composer/notes/dot.svg'
import { ReactComponent as DoubleDot } from 'assets/images/composer/notes/double-dot.svg'
import { ReactComponent as EighthNote } from 'assets/images/composer/notes/eighth-note.svg'
import { ReactComponent as HalfNote } from 'assets/images/composer/notes/half-note.svg'
import { ReactComponent as QuarterNote } from 'assets/images/composer/notes/quarter-note.svg'
import { ReactComponent as SixteenthNote } from 'assets/images/composer/notes/sixteenth-note.svg'
import { ReactComponent as SixtyFourthNote } from 'assets/images/composer/notes/sixty-fourth-note.svg'
import { ReactComponent as ThirtySecondNote } from 'assets/images/composer/notes/thirty-second-note.svg'
import { ReactComponent as WholeNote } from 'assets/images/composer/notes/whole-note.svg'
import { ReactComponent as EighthRest } from 'assets/images/composer/rests/eighth-rest.svg'
import { ReactComponent as HalfRest } from 'assets/images/composer/rests/half-rest.svg'
import { ReactComponent as QuarterRest } from 'assets/images/composer/rests/quarter-rest.svg'
import { ReactComponent as SixteenthRest } from 'assets/images/composer/rests/sixteenth-rest.svg'
import { ReactComponent as SixtyFourthRest } from 'assets/images/composer/rests/sixty-fourth-rest.svg'
import { ReactComponent as ThirtySecondRest } from 'assets/images/composer/rests/thirty-second-rest.svg'
import { ReactComponent as WholeRest } from 'assets/images/composer/rests/whole-rest.svg'
import { ReactComponent as Time2by4 } from 'assets/images/composer/timeSignatures/2-4.svg'
import { ReactComponent as Time3by4 } from 'assets/images/composer/timeSignatures/3-4.svg'
import { ReactComponent as Time3by8 } from 'assets/images/composer/timeSignatures/3-8.svg'
import { ReactComponent as Time4by4 } from 'assets/images/composer/timeSignatures/4-4.svg'
import { ReactComponent as Time6by8 } from 'assets/images/composer/timeSignatures/6-8.svg'
import { ReactComponent as CommonTime } from 'assets/images/composer/timeSignatures/common-time.svg'
import { ReactComponent as CutTime } from 'assets/images/composer/timeSignatures/cut-time.svg'

const ICON_MAPPING = {
  'whole-note': WholeNote,
  'half-note': HalfNote,
  'quarter-note': QuarterNote,
  'eighth-note': EighthNote,
  'sixteenth-note': SixteenthNote,
  'thirty-second-note': ThirtySecondNote,
  'sixty-fourth-note': SixtyFourthNote,
  'whole-rest': WholeRest,
  'half-rest': HalfRest,
  'quarter-rest': QuarterRest,
  'eighth-rest': EighthRest,
  'sixteenth-rest': SixteenthRest,
  'thirty-second-rest': ThirtySecondRest,
  'sixty-fourth-rest': SixtyFourthRest,
  dot: Dot,
  'double-dot': DoubleDot,
  slur: Slur,
  'up-bow': UpBow,
  'down-bow': DownBow,
  sharp: Sharp,
  'double-sharp': DoubleSharp,
  flat: Flat,
  'double-flat': DoubleFlat,
  natural: Natural,
  'G-Em': GMajorKey,
  'D-Bm': DMajorKey,
  'A-F♯m': AMajorKey,
  'E-C♯m': EMajorKey,
  'B-G♯m': BMajorKey,
  'F♯-D♯m': FSharpMajorKey,
  'C♯-A♯m': CSharpMajorKey,
  'F-Dm': FMajorKey,
  'Bb-Gm': BFlatMajorKey,
  'Eb-Cm': EFlatMajorKey,
  'Ab-Fm': AFlatMajorKey,
  'Db-Bbm': DFlatMajorKey,
  'Gb-Ebm': GFlatMajorKey,
  'Cb-Abm': CFlatMajorKey,
  'C-Am': CMajorKey,
  'common-time': CommonTime,
  'cut-time': CutTime,
  '2/4': Time2by4,
  '3/4': Time3by4,
  '4/4': Time4by4,
  '3/8': Time3by8,
  '6/8': Time6by8,
} as const

export type IconMapping = typeof ICON_MAPPING

type Props = {
  name: keyof IconMapping
  color?: string
  width?: number
  height?: number
}

export const Icon = ({ color, name, width, height }: Props) => {
  const theme = useTheme()
  const fill = color || theme.palette.text.primary

  if (!name) return null

  const Svg = ICON_MAPPING[name]
  return <Svg fill={fill} width={width} height={height} />
}
