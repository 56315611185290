import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useTeacherCoursesController } from 'controllers'

import { TeacherCourseForm } from '..'
import {
  TEACHER_COURSE_INITIAL_VALUES,
  TeacherCourseFormValues,
  teacherCourseSchema,
} from '../teacherCourseSchema'
import { buildTeacherCourseParams } from '../utils'

export const TeacherCourseCreateForm = () => {
  const { createTeacherCourse } = useTeacherCoursesController()
  const navigate = useNavigate()

  const form = useForm<TeacherCourseFormValues>({
    resolver: yupResolver(teacherCourseSchema),
    defaultValues: TEACHER_COURSE_INITIAL_VALUES,
  })

  const handleCreateCourse = async (data: TeacherCourseFormValues) => {
    const payload = buildTeacherCourseParams(data)
    const response = await createTeacherCourse({ teacherCourse: payload })

    const { status, data: responseData } = response
    // @ts-ignore: useApi type
    if (status === 200) navigate(`../${responseData.data.id}`)
  }

  return <TeacherCourseForm form={form} handleSaveCourse={handleCreateCourse} />
}
