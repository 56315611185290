export const FETCH_CUSTOM_SCORE_START = 'FETCH_CUSTOM_SCORE_START'
export const FETCH_CUSTOM_SCORE_SUCCESS = 'FETCH_CUSTOM_SCORE_SUCCESS'
export const FETCH_CUSTOM_SCORE_ERROR = 'FETCH_CUSTOM_SCORE_ERROR'
export const FETCH_CUSTOM_SCORE_END = 'FETCH_CUSTOM_SCORE_END'

export const DELETE_CUSTOM_SCORE_START = 'DELETE_CUSTOM_SCORE_START'
export const DELETE_CUSTOM_SCORE_SUCCESS = 'DELETE_CUSTOM_SCORE_SUCCESS'
export const DELETE_CUSTOM_SCORE_ERROR = 'DELETE_CUSTOM_SCORE_ERROR'
export const DELETE_CUSTOM_SCORE_END = 'DELETE_CUSTOM_SCORE_END'

export const UPDATE_CUSTOM_SCORE_START = 'UPDATE_CUSTOM_SCORE_START'
export const UPDATE_CUSTOM_SCORE_SUCCESS = 'UPDATE_CUSTOM_SCORE_SUCCESS'
export const UPDATE_CUSTOM_SCORE_ERROR = 'UPDATE_CUSTOM_SCORE_ERROR'
export const UPDATE_CUSTOM_SCORE_END = 'UPDATE_CUSTOM_SCORE_END'

export const CREATE_CUSTOM_SCORE_START = 'CREATE_CUSTOM_SCORE_START'
export const CREATE_CUSTOM_SCORE_SUCCESS = 'CREATE_CUSTOM_SCORE_SUCCESS'
export const CREATE_CUSTOM_SCORE_ERROR = 'CREATE_CUSTOM_SCORE_ERROR'
export const CREATE_CUSTOM_SCORE_END = 'CREATE_CUSTOM_SCORE_END'

export const RESET_CUSTOM_SCORE = 'RESET_CUSTOM_SCORE'

export const UPLOAD_MIDI_START = 'UPLOAD_MIDI_START'
export const UPLOAD_MIDI_SUCCESS = 'UPLOAD_MIDI_SUCCESS'
export const UPLOAD_MIDI_END = 'UPLOAD_MIDI_END'
export const UPLOAD_MIDI_ERROR = 'UPLOAD_MIDI_ERROR'
