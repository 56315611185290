import { AbcComposition, AbcNotation } from '../types'

import { ElementReplacer } from '.'

export class ElementInserter extends ElementReplacer {
  buildFromDuration(): AbcComposition {
    const { newElements } = this.maestro
    const newElementsAbc = newElements.map(newElement => newElement.abcNotation).join('') as AbcNotation

    return [
      newElementsAbc,
      {
        startChar: this.startChar,
        endChar: this.endChar,
        lastElementEndChar: this.startChar,
      },
    ]
  }

  get endChar() {
    return this.startChar
  }

  get startChar() {
    const lastSelectedElement = this.maestro.selectedElements.at(-1)!
    const { el_type: selectedElType, endChar } = lastSelectedElement
    // @ts-ignore: abcjs type mismatch
    if (selectedElType === 'bar') return endChar

    const firstBarAfterSelectedElement = this.maestro.allElements.find(element => {
      const { startChar, el_type: elType } = element.elem.abcelem
      return startChar > lastSelectedElement.startChar && elType === 'bar'
    })

    return firstBarAfterSelectedElement!.elem.abcelem.endChar
  }
}
