import { MusicalElement } from './MusicalElement'

export class GeneralElement extends MusicalElement {
  constructor({ value }) {
    super({ value })
    this.type = 'general-element'
  }

  toAbc() {
    return this.abc
  }
}
