import { Maestro } from '../Maestro'

import {
  TIME_SIGNATURE_MAPPING,
  TimeSignatureMapping,
  TUNE_COMPOSER_REGEX,
  TUNE_KEY_SIGNATURE_REGEX,
  TUNE_TIME_SIGNATURE_REGEX,
  TUNE_TITLE_REGEX,
} from './constants'

export class TuneSettingsManager {
  maestro: Maestro

  constructor(maestro: Maestro) {
    this.maestro = maestro
  }

  getTimeSignature() {
    return this.tune.match(TUNE_TIME_SIGNATURE_REGEX)![1].trim()
  }

  setTitle(title: string) {
    return this.tune.replace(TUNE_TITLE_REGEX, `T: ${title}`)
  }

  setComposer(composer: string) {
    return this.tune.replace(TUNE_COMPOSER_REGEX, `C: ${composer}`)
  }

  setKeySignature(keySignature: string) {
    return this.tune.replace(TUNE_KEY_SIGNATURE_REGEX, `K: ${keySignature}`)
  }

  setTimeSignature(timeSignature: string) {
    return this.tune.replace(TUNE_TIME_SIGNATURE_REGEX, `M: ${timeSignature}`)
  }

  // eslint-disable-next-line class-methods-use-this
  getTimeSignatureValues(timeSignature: string) {
    let timeSignatureStr = timeSignature
    if (!timeSignature.match('/')) {
      timeSignatureStr = TIME_SIGNATURE_MAPPING[timeSignature as TimeSignatureMapping]
    }
    const [beatsPerMeasure, durationOfBeat] = timeSignatureStr.split('/')

    return {
      beatsPerMeasure: parseInt(beatsPerMeasure, 10),
      durationOfBeat: parseInt(durationOfBeat, 10),
      label: timeSignature,
    }
  }

  private get tune() {
    const { currentAbc } = this.maestro
    return currentAbc || ''
  }
}
