import { Menu as MaterialMenu } from '@mui/material'
import styled from 'styled-components'

export const Menu = styled(MaterialMenu)`
  .MuiMenuItem-root {
    min-width: 120px;
    height: 48px;
    background: ${({ theme }) => theme.palette.background.paper};

    &:hover {
      background: transparent !important;
      * {
        color: ${({ theme }) => theme.palette.primary.main} !important;
      }
    }
  }
`
