import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { PageContainer } from 'components/ui'
import { useTeacherCoursesController } from 'controllers'
import { CourseList } from 'pages/components'

export const TeacherCoursesPage = () => {
  const navigate = useNavigate()
  const { fetchTeacherCourses, teacherCourses } = useTeacherCoursesController()

  useEffect(() => {
    fetchTeacherCourses()
  }, [])

  const handleAddCourse = () => {
    navigate('new/')
  }

  return (
    <PageContainer title="Teacher Dashboard" primaryAction={handleAddCourse}>
      <CourseList courses={teacherCourses} />
    </PageContainer>
  )
}
