import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { EmailOutlined } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { Button, Input } from 'components/ui'
import { useAuth } from 'hooks'
import { useToastContext } from 'providers'
import { ForgotPasswordEndpoint } from 'routes/locations'

import { Container, Form, FormContainer, Title } from '../styles'

const schema = Yup.object().shape({
  email: Yup.string().required().email().label('Email'),
})

type ForgotPasswordFormValues = Yup.InferType<typeof schema>

export const ForgotPassword = () => {
  const [forgotPassword, error, loading, status] = useAuth(ForgotPasswordEndpoint)
  const { showToast } = useToastContext()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { email: '' },
  })

  const onSubmit = useCallback(
    (data: ForgotPasswordFormValues) => {
      forgotPassword({ user: data })
    },
    [forgotPassword],
  )

  useEffect(() => {
    if (status !== 201) return

    showToast({
      type: 'success',
      title: 'Email received successfully',
      description:
        'An email containing instructions to reset your password will be sent to your mailbox shortly.',
    })
  }, [status, showToast])

  useEffect(() => {
    if (!error) return

    showToast({
      type: 'error',
      title: 'Forgot password failed!',
      description: error.message,
    })
  }, [error, showToast])

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Forgot password</Title>
          <Input<ForgotPasswordFormValues>
            type="email"
            name="email"
            label="Email"
            placeholder="Email"
            icon={EmailOutlined}
            control={control}
            autoComplete="username"
            errorMessage={errors.email?.message as string}
          />

          <Button type="submit" sx={{ mt: '1rem' }} loading={loading}>
            Submit
          </Button>
        </Form>
      </FormContainer>
    </Container>
  )
}
