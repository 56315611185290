import { ActionCreators as UndoActions } from 'redux-undo'

import { INITIAL_TUNE } from 'components/Composer'
import { maestro } from 'components/Composer/hooks'
import { Clipboard } from 'components/Composer/models'
import { COMPOSER_STORAGE_KEY } from 'hooks'
import { fetchCustomScore } from 'store/customScores/actions'
import { getNewestObject } from 'utils'

import {
  initComposerEnd,
  initComposerStart,
  initComposerSuccess,
  resetComposer as reset,
  setClipboard as setClipboardSuccess,
  setComposerMeta as setMeta,
  setComposerSetting as setSetting,
  setComposerTune as setTune,
} from './creators'
import composerInitialState from './state'

export const initComposer = scoreId => {
  return async (dispatch, getState) => {
    dispatch(initComposerStart())
    const storageCustomScores = JSON.parse(localStorage.getItem(COMPOSER_STORAGE_KEY))

    if (scoreId) {
      await dispatch(fetchCustomScore(scoreId))

      const { customScores } = getState()
      const { data: dbCustomScore } = customScores.show
      const { id, title, composer } = dbCustomScore

      const storageCustomScore = storageCustomScores?.[id]
      const hasStorage = storageCustomScore && (id === storageCustomScore?.id || !storageCustomScore?.id)
      const customScore = hasStorage ? storageCustomScore : dbCustomScore
      const params = {
        id: customScore.id,
        abc: customScore.abc,
        meta: { title, composer },
      }

      dispatch(initComposerSuccess(params))
    } else {
      const newestStorageScore = getNewestObject(storageCustomScores, 'updatedAt')
      const { id, title, composer, abc: tune } = newestStorageScore || {}
      const abc = newestStorageScore ? tune : INITIAL_TUNE
      dispatch(
        initComposerSuccess({
          abc,
          id,
          ...(title && {
            meta: {
              title,
              composer,
            },
          }),
        }),
      )
    }

    dispatch(initComposerEnd())
    dispatch(UndoActions.clearHistory())
  }
}

export const resetComposer = () => {
  return async dispatch => {
    dispatch(
      reset({
        ...composerInitialState.composerV2,
        abc: INITIAL_TUNE,
      }),
    )
  }
}

export const setComposerTune = tune => {
  return async dispatch => {
    dispatch(setTune({ abc: tune }))
  }
}

export const setComposerSetting = (controlValue, settingName) => {
  return async dispatch => {
    const setting = { [settingName]: controlValue }
    dispatch(setSetting(setting))
  }
}

export const setClipboard = () => {
  return dispatch => {
    const selection = Clipboard.copy()
    dispatch(setClipboardSuccess(selection))
  }
}

export const paste = () => {
  return (dispatch, getState) => {
    const { clipboard } = getState().composerV2.present
    Clipboard.paste(clipboard)

    dispatch(setComposerTune(maestro.textarea.value))
  }
}

export const setComposerMeta = (metaValue, metaName) => {
  return async dispatch => {
    const meta = { [metaName]: metaValue }
    dispatch(setMeta({ meta }))
  }
}
