import {
  CREATE_CUSTOM_SCORE_END,
  CREATE_CUSTOM_SCORE_ERROR,
  CREATE_CUSTOM_SCORE_START,
  CREATE_CUSTOM_SCORE_SUCCESS,
  DELETE_CUSTOM_SCORE_END,
  DELETE_CUSTOM_SCORE_ERROR,
  DELETE_CUSTOM_SCORE_START,
  FETCH_CUSTOM_SCORE_END,
  FETCH_CUSTOM_SCORE_ERROR,
  FETCH_CUSTOM_SCORE_START,
  FETCH_CUSTOM_SCORE_SUCCESS,
  RESET_CUSTOM_SCORE,
  UPDATE_CUSTOM_SCORE_END,
  UPDATE_CUSTOM_SCORE_ERROR,
  UPDATE_CUSTOM_SCORE_START,
  UPDATE_CUSTOM_SCORE_SUCCESS,
  UPLOAD_MIDI_END,
  UPLOAD_MIDI_ERROR,
  UPLOAD_MIDI_START,
  UPLOAD_MIDI_SUCCESS,
} from 'store/customScores/types'
import initialState from 'store/state'

const scoresReducer = (state = initialState.customScores, action) => {
  switch (action.type) {
    case FETCH_CUSTOM_SCORE_START:
    case CREATE_CUSTOM_SCORE_START:
    case UPDATE_CUSTOM_SCORE_START:
    case DELETE_CUSTOM_SCORE_START:
    case UPLOAD_MIDI_START:
      return {
        ...state,
        show: {
          ...state.show,
          loading: action.payload?.loading || true,
        },
      }
    case FETCH_CUSTOM_SCORE_SUCCESS:
    case CREATE_CUSTOM_SCORE_SUCCESS:
    case UPDATE_CUSTOM_SCORE_SUCCESS:
    case UPLOAD_MIDI_SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          data: action.payload,
        },
      }
    case FETCH_CUSTOM_SCORE_ERROR:
    case DELETE_CUSTOM_SCORE_ERROR:
    case UPLOAD_MIDI_ERROR:
      return {
        ...state,
        show: {
          ...state.show,
          error: action.payload,
        },
      }
    case CREATE_CUSTOM_SCORE_ERROR:
    case UPDATE_CUSTOM_SCORE_ERROR:
      return {
        ...state,
        show: {
          ...state.show,
          ...action.payload,
        },
      }
    case FETCH_CUSTOM_SCORE_END:
    case CREATE_CUSTOM_SCORE_END:
    case UPDATE_CUSTOM_SCORE_END:
    case DELETE_CUSTOM_SCORE_END:
    case UPLOAD_MIDI_END:
      return {
        ...state,
        show: {
          ...state.show,
          loading: false,
          error: null,
          status: action.payload,
        },
      }

    case RESET_CUSTOM_SCORE:
      return {
        ...state,
        show: { ...initialState.customScores.show },
      }

    default:
      return state
  }
}

export default scoresReducer
