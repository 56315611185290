import {
  Checkbox as MaterialCheckbox,
  Collapse as MaterialCollapse,
  IconButton as MaterialIconButton,
} from '@mui/material'
import { lighten } from 'polished'
import { IoMusicalNotesOutline, IoMusicalNotesSharp } from 'react-icons/io5'
import styled from 'styled-components'

export const Collapse = styled(MaterialCollapse)`
  &.MuiCollapse-root {
    padding: 24px 4px 0;
  }

  .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
  }
`
export const FilterContainer = styled.div`
  @media (min-width: 920px) {
    display: flex;
  }
`
export const NotesContainer = styled.div`
  width: 460px;

  @media (min-width: 920px) {
    min-width: 460px;
  }
`
export const LevelsContainer = styled.div`
  margin-top: 8px;

  @media (min-width: 920px) {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 8px;
  }
`
export const Title = styled.h5`
  margin-bottom: 4px;
  color: ${({ theme }) => lighten(0.1, theme.palette.colors.pink)};
`
export const CheckedNoteIcon = styled(IoMusicalNotesSharp)`
  width: 18px;
`
export const CheckedNoteIconOutline = styled(IoMusicalNotesOutline)`
  width: 18px;
  color: ${({ theme }) => theme.palette.text.primary};
`
export const Checkbox = styled(MaterialCheckbox)`
  &.MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.colors.pink};
  }
`
export const IconButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    right: 4px;
    color: ${({ theme }) => theme.palette.text.primary};
    position: absolute;
    &:focus {
      outline: none;
    }
  }
`
