import { useEffect, useState } from 'react'

import { Container } from './styles'

type Props = {
  timeout?: number
  interval?: number
  onInterval?: () => void
  onTimeout?: () => void
}

export const Countdown = ({ timeout = 5000, interval = 1000, onInterval, onTimeout }: Props) => {
  const [counter, setCounter] = useState(timeout)

  let intervalId: NodeJS.Timeout
  let timeoutId: NodeJS.Timeout

  const clearTimerInterval = () => {
    clearInterval(intervalId)
  }

  useEffect(() => {
    intervalId = setInterval(() => {
      setCounter(prevCounter => prevCounter - interval)
      onInterval?.()
    }, interval)

    return clearTimerInterval
  }, [])

  useEffect(() => {
    timeoutId = setTimeout(() => {
      onTimeout?.()
      clearTimerInterval()
    }, timeout)

    return () => clearTimeout(timeoutId)
  }, [])

  return counter > 0 ? <Container>{counter / 1000}</Container> : null
}
