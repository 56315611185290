import { useEffect } from 'react'

import { Box, List, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { AttachmentLink, PageContainer } from 'components/ui'
import { useCourseController, useCourseLectureController } from 'controllers'
import { CourseModuleAccordion } from 'pages/courses/components/'
import { parameterize } from 'utils'

import { CourseLectureVideo } from './components'
import { lectureListSx } from './styles'

export const CourseLecturePage = () => {
  const { courseId, courseLectureId } = useParams()
  const { fetchCourseLecture, courseLecture } = useCourseLectureController(courseLectureId!)
  const { fetchCourse, course } = useCourseController(courseId!)

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    fetchCourseLecture()
  }, [courseLectureId])

  if (!course || !courseLecture) return <>Loading...</>

  const { title, description, playableScores } = courseLecture

  return (
    <PageContainer sx={{ flexDirection: 'row' }} fullWidth>
      <Box flex={3}>
        <CourseLectureVideo courseLecture={courseLecture} />
        <Box padding="16px 32px" display="flex" flexDirection="column" gap={3}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1">{description}</Typography>

          <Box display="flex" gap={1}>
            {playableScores.map((playableScore, index) => (
              <AttachmentLink
                to={`/sheet-music/${parameterize(playableScore.title)}/${playableScore.id}`}
                fileName={playableScore.title}
                key={playableScore.id}
                index={index}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <List sx={lectureListSx}>
        {course.courseModules.map((courseModule, index) => (
          <CourseModuleAccordion
            key={courseModule.id}
            courseModule={courseModule}
            defaultExpanded={index < 2}
            pathPrefix="../"
          />
        ))}
      </List>
    </PageContainer>
  )
}
