import { FiX } from 'react-icons/fi'
import styled from 'styled-components'

export const Container = styled.div`
  width: 400px;
  min-height: 300px;
  padding: 42px 42px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  top: 50%;
  left: 50%;
  position: fixed;
  color: ${({ theme }) => theme.palette.grey[500]};
  border-radius: 5px;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s ease-in-out;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  z-index: 100000;
  background: ${({ theme }) => theme.palette.colors.white};
`
export const Body = styled.div`
  text-align: center;

  p {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
`
export const CloseIcon = styled(FiX)`
  top: 10px;
  right: 10px;
  cursor: pointer;
  position: absolute;
`
export const Title = styled.h2`
  margin-top: 32px;
`
export const Footer = styled.footer`
  margin-top: 32px;

  button {
    width: 100px;
    height: 36px;
    margin: 0 10px;
  }
`
