import { SoundManager } from '..'
import { getElementsDuration } from '../../helpers'

import { LargerDurationReplacer, SameDurationReplacer, SmallerDurationReplacer } from '.'

export class ElementReplacerOld {
  static for({ selectedElements, newElements }) {
    const newElementDuration = getElementsDuration(newElements)
    const selectedDuration = getElementsDuration(selectedElements)
    let ReplacementClass

    if (newElementDuration === selectedDuration) {
      ReplacementClass = SameDurationReplacer
    } else if (newElementDuration < selectedDuration) {
      ReplacementClass = SmallerDurationReplacer
    } else {
      ReplacementClass = LargerDurationReplacer
    }

    return ReplacementClass
  }

  static triggerError() {
    SoundManager.play('error')
    return null
  }
}
