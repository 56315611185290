import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { PageContainer } from 'components/ui'
import { useTeacherCourseController } from 'controllers'

import { TeacherCourseCreateForm, TeacherCourseEditForm } from './components'

export const TeacherCoursePage = () => {
  const { teacherCourseId } = useParams()
  const { fetchTeacherCourse, teacherCourse } = useTeacherCourseController(teacherCourseId!)

  useEffect(() => {
    if (!teacherCourseId) return

    fetchTeacherCourse()
  }, [teacherCourseId])

  if (teacherCourseId && !teacherCourse) return <>Loading...</>

  return (
    <PageContainer gap="16px" gutterTop>
      {teacherCourseId ? (
        <TeacherCourseEditForm teacherCourse={teacherCourse} />
      ) : (
        <TeacherCourseCreateForm />
      )}
    </PageContainer>
  )
}
