const customScoresState = {
  customScores: {
    list: {
      data: [],
      loading: false,
      error: null,
      meta: {},
    },
    show: {
      data: {},
      loading: false,
      error: null,
      status: null,
    },
  },
}

export default customScoresState
