import { createContext, useContext } from 'react'

type Args = {
  errorMessage?: string
}

export const createCtx = <T extends object | null>({
  errorMessage = 'useCtx must be inside a Provider with a value',
}: Args = {}) => {
  const ctx = createContext<T | undefined>(undefined)
  const useCtx = () => {
    const context = useContext(ctx)
    if (context === undefined) {
      throw new Error(errorMessage)
    }
    return context
  }
  return [useCtx, ctx.Provider] as const
}
