import { MidiJsonNote } from '../../models'

import { FINGERBOARD_NOTE_WIDTH, SLIDER_QUARTER_NOTE_HEIGHT } from './constants'

type FingerboardSettings = {
  bpm: number
  originalBpm: number
}
type StringPosition = {
  G: { x: number; y: number }
  D: { x: number; y: number }
  A: { x: number; y: number }
  E: { x: number; y: number }
}

export const fingerboardSettings = ({ bpm, originalBpm }: FingerboardSettings) => {
  const getNoteAnimationDuration = () => {
    const fingerboardHeight = getFingerboardHeight()
    const animationDuration = fingerboardHeight / SLIDER_QUARTER_NOTE_HEIGHT / (bpm / (originalBpm / 2))

    return animationDuration
  }

  const getNoteAnimationDelay = (note: MidiJsonNote) => {
    return note.time / (bpm / originalBpm)
  }

  const getNoteHeight = (note: MidiJsonNote) => {
    return 2 * note.duration * SLIDER_QUARTER_NOTE_HEIGHT - 4 // 1t note 300px
  }

  const getFingerboardHeight = () => {
    return window.innerHeight + SLIDER_QUARTER_NOTE_HEIGHT * 8 // 4t note height
  }

  const getTriggerLineDuration = () => {
    const lineOffset = (window.innerHeight - 52) / getFingerboardHeight()
    return getNoteAnimationDuration() * lineOffset
  }

  const getStringsPosition = () => {
    const strings = document.querySelectorAll('.fingerboard-string path')
    return Array.from(strings).reduce((acc, item) => {
      const { x, y } = item.getBoundingClientRect()
      const stringName = item.id
      acc[stringName as keyof StringPosition] = { x, y }
      return acc
    }, {} as StringPosition)
  }

  const getContainerPosition = () => {
    const fingerboardString = document.querySelector('.fingerboard-string')
    const { x, y } = fingerboardString?.parentElement?.getBoundingClientRect() || {}
    return { x, y }
  }

  const getNoteXPos = (note: MidiJsonNote) => {
    const stringsPosition = getStringsPosition()
    const { x: containerPosX } = getContainerPosition()
    const { x } = stringsPosition[note.string!] || {}
    const offset = { G: 30, D: 16, A: 0, E: 0 }

    return Math.abs(x - containerPosX!) + offset[note.string!] - FINGERBOARD_NOTE_WIDTH / 2
  }

  return {
    getNoteAnimationDuration,
    getNoteAnimationDelay,
    getNoteHeight,
    getTriggerLineDuration,
    getStringsPosition,
    getContainerPosition,
    getNoteXPos,
  }
}
