import { useLayoutEffect, useState } from 'react'

export const useWindowSize = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  // add debounce
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return dimensions
}
