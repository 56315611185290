import Location from 'app-location'
import * as Yup from 'yup'

export const CustomScoreFoldersEndpoint = new Location('/custom_score_folders')
export const CustomScoreFolderEndpoint = new Location('/custom_score_folders/:id', {
  id: Yup.string(),
})

export const FavoriteScoreFoldersEndpoint = new Location('/favorite_score_folders')
export const FavoriteScoreFolderEndpoint = new Location('/favorite_score_folders/:id', {
  id: Yup.string(),
})
