import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'
import initialState from './state'

const params = [applyMiddleware(thunk)]

window.__REDUX_DEVTOOLS_EXTENSION__ && params.push(window.__REDUX_DEVTOOLS_EXTENSION__())

const store = createStore(rootReducer, initialState, compose(...params))

export default store
