import {
  INIT_COMPOSER_END,
  INIT_COMPOSER_START,
  INIT_COMPOSER_SUCCESS,
  RESET_COMPOSER,
  SET_CLIPBOARD,
  SET_COMPOSER_META,
  SET_COMPOSER_SETTING,
  SET_COMPOSER_TUNE,
} from './types'

export const initComposerStart = () => ({
  type: INIT_COMPOSER_START,
})
export const initComposerSuccess = payload => ({
  type: INIT_COMPOSER_SUCCESS,
  payload,
})
export const initComposerEnd = () => ({
  type: INIT_COMPOSER_END,
})

export const setComposerTune = payload => ({
  type: SET_COMPOSER_TUNE,
  payload,
})

export const setComposerSetting = payload => ({
  type: SET_COMPOSER_SETTING,
  payload,
})

export const setClipboard = payload => ({
  type: SET_CLIPBOARD,
  payload,
})

export const setComposerMeta = payload => ({
  type: SET_COMPOSER_META,
  payload,
})

export const resetComposer = payload => ({
  type: RESET_COMPOSER,
  payload,
})
