export const APP_COLORS = {
  offWhite: '#fafafa',
  offYellow: '#faf9f8',
  offBlack: '#10131A',
  primary: '#a4124d',
  grey: {
    200: '#eeeeee',
    400: '#a7a7b3',
    500: '#7A7A82',
    700: '#333336',
    800: '#222938',
  },
  green: '#25e651',
  yellow: '#e6b30e',
  red: '#e60217',
}
