import { RefObject, useEffect, useRef } from 'react'

import abcjs, { Editor } from 'abcjs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionCreators as UndoActions } from 'redux-undo'
import { v4 as uuidv4 } from 'uuid'

import { INITIAL_COMPOSER_META, INITIAL_TUNE } from 'components/Composer/constants'
import { COMPOSER_STORAGE_KEY } from 'hooks'
import { CustomScore, StorageCustomScore } from 'models'
import { useToastContext } from 'providers'
import {
  initComposer,
  resetComposer,
  setComposerMeta,
  setComposerSetting,
  setComposerTune,
} from 'store/composerV2/actions'
import { createCustomScore, updateCustomScore } from 'store/customScores/actions'
import { RootState } from 'store/rootReducer'

import { ABCJS_PARAMS } from '../constants'
import { Maestro } from '../models'
import { TUNE_SETTINGS_SETTER_MAPPING } from '../models/TuneSettingsManager/constants'
import { ComposerPanelControl, ComposerPanelControlWithDuration, ComposerPanelType } from '../models/types'

import { useComposerAutoSave, useComposerStorageAutoSave } from '.'

export const maestro = new Maestro()

const newCustomScoreId = uuidv4()

type TuneSettingsControlType = Extract<ComposerPanelType, 'keySignature'> | 'title' | 'composer'

export const useComposer = (
  displayRef: RefObject<HTMLDivElement>,
  textareaRef: RefObject<HTMLTextAreaElement>,
  audioRef: RefObject<HTMLDivElement>,
) => {
  const abcjsEditor = useRef<Potential<Editor>>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { scoreId } = useParams()
  const { showToast } = useToastContext()

  const { composerV2: composerStateHistory } = useSelector((state: RootState) => state)
  const { present: composerState } = composerStateHistory
  const {
    error: createCustomScoreError,
    status: createCustomScoreStatus,
    data: customScore,
  } = useSelector((state: RootState) => state.customScores.show)

  maestro.abcjsEditor = abcjsEditor

  const customScoreId = scoreId || composerState.id || newCustomScoreId
  const canUndo = composerStateHistory.past?.length > 0
  const canRedo = composerStateHistory.future?.length > 0
  const isInitialTitle = composerState.meta.title === INITIAL_COMPOSER_META.title

  const handleSaveCustomScore = (customScoreParams: Partial<CustomScore>) => {
    const { meta, settings } = composerState
    const { title, composer } = meta
    const { keySignature, timeSignature } = settings
    const params = {
      title,
      composer,
      key_signature: keySignature,
      time_signature: timeSignature.label,
      ...customScoreParams,
    }

    scoreId ? dispatch(updateCustomScore(scoreId, params)) : dispatch(createCustomScore(params))
  }

  const handleComposerSaveClick = () => {
    const updatedStorage = { ...composerStorage, ...customScoreStorageParams }

    handleSaveCustomScore(customScoreStorageParams[customScoreId])
    localStorage.setItem(COMPOSER_STORAGE_KEY, JSON.stringify(updatedStorage))
  }

  const customScoreStorageParams: StorageCustomScore = {
    [customScoreId]: {
      abc: composerState.abc,
      id: customScoreId,
      title: composerState.meta.title,
      composer: composerState.meta.composer,
      updatedAt: new Date(),
    },
  }

  const { handleAutoSave } = useComposerAutoSave(
    customScoreStorageParams[customScoreId],
    handleSaveCustomScore,
  )
  const { handleAutoSave: handleStorageAutoSave, composerStorage } =
    useComposerStorageAutoSave(customScoreStorageParams)

  const isInitialState = composerState.abc === INITIAL_TUNE && !customScore?.abc
  const isEqualDb = !!customScore.abc && customScore.abc === composerState.abc
  const isComposerDirty = !(isInitialState || isEqualDb)

  useEffect(() => {
    dispatch(initComposer(scoreId))
  }, [])

  useEffect(() => {
    if (!displayRef.current || !textareaRef.current || !audioRef.current || !composerState.abc) return

    textareaRef.current.value = composerState.abc

    const isFirstRender = !abcjsEditor.current

    abcjsEditor.current = new abcjs.Editor(textareaRef.current.id, {
      canvas_id: displayRef.current,
      abcjsParams: ABCJS_PARAMS,
      synth: {
        el: audioRef.current,
        options: {
          // @ts-ignore: optional
          displayLoop: true,
          displayRestart: true,
          displayPlay: true,
          displayProgress: true,
          displayWarp: true,
        },
      },
    })

    // FIXME: find a way to do this elsewhere
    // it should be called only on first render
    isFirstRender && maestro.selectElement()

    !isInitialTitle && handleAutoSave({ abc: composerState.abc, id: customScoreId })
    handleStorageAutoSave(customScoreStorageParams)
  }, [composerState.abc])

  useEffect(() => {
    if (!createCustomScoreError) return

    showToast({
      type: 'error',
      title: `Failed to save score "${composerState.meta.title}"`,
      description: createCustomScoreError.message,
    })
  }, [createCustomScoreError])

  useEffect(() => {
    if (createCustomScoreStatus !== 201 || !customScore.id) return
    navigate(`./${customScore.id}`)
  }, [createCustomScoreStatus])

  const handleControlClick = async (control: ComposerPanelControl, controlType: ComposerPanelType) => {
    control.type = controlType
    const composition = maestro.compose({ ...control, ...composerState.settings })
    if (!composition) return

    maestro.write(composition)
    dispatch(setComposerTune(textareaRef.current?.value))
  }

  const handleSettingClick = async (control: ComposerPanelControl, controlType: ComposerPanelType) => {
    dispatch(setComposerSetting(control.value, controlType))
  }

  const handleDurationClick = async (
    control: ComposerPanelControlWithDuration,
    controlType: ComposerPanelType,
  ) => {
    dispatch(setComposerSetting(control.duration, controlType))
  }

  const handleModifierClick = async (control: ComposerPanelControlWithDuration) => {
    const composition = maestro.changeElementDuration(control)
    if (!composition) return

    maestro.write(composition)
    dispatch(setComposerTune(textareaRef.current?.value))
  }

  const handleControlAndSettingClick = async (
    control: ComposerPanelControl,
    controlType: ComposerPanelType,
  ) => {
    handleSettingClick(control, controlType)
    if (maestro.selectedElement) handleControlClick(control, controlType)
  }

  const handleTuneSettingClick = async (
    control: ComposerPanelControl,
    controlType: TuneSettingsControlType,
  ) => {
    const setMethod = TUNE_SETTINGS_SETTER_MAPPING[controlType]
    const tune = maestro.tuneSettings[setMethod.setter](control.value)
    const action = setMethod.stateKey === 'settings' ? setComposerSetting : setComposerMeta

    dispatch(setComposerTune(tune))
    dispatch(action(control.value, controlType))
  }

  const handleTimeSignatureClick = (control: ComposerPanelControl, controlType: 'timeSignature') => {
    const timeSignature = maestro.tuneSettings.getTimeSignatureValues(control.value)
    const tune = maestro.tuneSettings.setTimeSignature(control.value)

    dispatch(setComposerTune(tune))
    dispatch(setComposerSetting(timeSignature, controlType))

    setTimeout(() => {
      maestro.selectElement()
      handleControlClick(control, controlType)
    })
  }

  const handleResetComposer = () => {
    dispatch(resetComposer())
    navigate('/composer')
  }

  const handleUndo = () => {
    dispatch(UndoActions.undo())
  }

  const handleRedo = () => {
    dispatch(UndoActions.redo())
  }

  return {
    handlers: {
      handleControlClick,
      handleModifierClick,
      handleSettingClick,
      handleControlAndSettingClick,
      handleDurationClick,
      handleComposerSaveClick,
      handleTuneSettingClick,
      handleTimeSignatureClick,
      handleUndo,
      handleRedo,
      handleResetComposer,
    },
    settings: {
      canUndo,
      canRedo,
      isComposerDirty,
    },
  }
}
