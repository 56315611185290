import { Slider as MaterialSlider } from '@mui/material'
import styled from 'styled-components'

export const RangeContainer = styled.div`
  position: relative;
`
export const Slider = styled(MaterialSlider)`
  color: #bfbfbf;

  &.MuiSlider-root {
    width: 250px;
    top: 50%;
    left: 60px;
    position: absolute;
    transform: translateY(-50%);
  }

  & .MuiSlider-thumb {
    background: #fff;
    border: 1px solid currentColor;
    box-shadow: 0 0 0 8px rgba(58, 133, 137, 0.16);
    &:hover {
      box-shadow: 0 0 0 8px rgba(58, 133, 137, 0.16);
    }
  }
`
