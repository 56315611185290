import { RESTS } from 'utils/settings'

import { MusicalElement } from '.'

export class Rest extends MusicalElement {
  constructor({ value, settings }) {
    super({ value, settings })
    this.abc = value
    this.type = 'rest'
  }

  get duration() {
    return RESTS.find(rest => rest.value === this.abc)?.duration
  }

  toAbc() {
    return this.abc
  }

  replace(measure) {
    if (this.isSkipReplace(measure)) return

    return super.replace(measure)
  }

  isSkipReplace(measure) {
    /* Rests cannot be deleted and can only replaced by either another rest or note */
    const newElement = measure.newElements[0]
    const isDeleteRest = newElement.type === 'rest' && newElement.duration === this.duration
    const isReplacerNoteOrRest = newElement.type === 'rest' || newElement.type === 'note'

    return isDeleteRest || !isReplacerNoteOrRest
  }

  static fromDuration(duration) {
    return RESTS.find(rest => rest.duration === duration)
  }

  static createfromDuration(duration) {
    const value = Rest.fromDuration(duration.toString()).value.toString()
    return new Rest({ value })
  }
}
