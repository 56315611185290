import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(120deg, rgba(0, 0, 0, 1) 15%, rgba(16, 19, 26, 1) 80%);
`
export const Boat = styled.img`
  height: 60%;
  margin-top: -120px;
`
export const Text = styled.h1`
  margin-top: 32px;
  text-transform: uppercase;
  color: #fff;
`
export const Link = styled.a`
  margin-top: 16px;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s;

  &:hover {
    border-bottom: 1px solid;
  }
`
