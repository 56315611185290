import { useEffect, useRef, useState } from 'react'

import { useLocalStorage } from 'usehooks-ts'

type UseAutoSaveOptions<T> = {
  storageKey?: string
  interval?: number
  callback?: (data: T) => void
}

export const AUTO_SAVE_DEFAULT_INTERVAL = 60_000

export const useAutoSave = <T>(
  initialData: T,
  {
    storageKey = '',
    interval: saveInterval = AUTO_SAVE_DEFAULT_INTERVAL,
    callback,
  }: UseAutoSaveOptions<T> = {},
) => {
  const composerStorageStr = localStorage.getItem(storageKey)
  const storedData = (composerStorageStr && JSON.parse(composerStorageStr)) || initialData || {}
  const lastSavedDataRef = useRef(storedData)
  const [newData, setNewData] = useState(storedData)
  const [storage, setStorage] = useLocalStorage<T>(storageKey, initialData)

  useEffect(() => {
    const interval = setInterval(() => {
      if (JSON.stringify(lastSavedDataRef.current) === JSON.stringify(newData)) return

      if (callback) {
        callback?.(newData)
      } else {
        setStorage(newData)
      }

      setNewData(newData)
      lastSavedDataRef.current = newData
    }, saveInterval)

    return () => clearInterval(interval)
  }, [newData, saveInterval])

  return [newData, setNewData, storage]
}
