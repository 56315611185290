import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'

import MusicNotationFont from 'assets/fonts/Notehedz.ttf'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Notehedz';
    src: url(${MusicNotationFont});
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    min-height: 100vh;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${props => props.theme.palette.text.primary};
    background: ${props => props.theme.palette.background.default};
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.palette.text.primary};
    transition: color .1s;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
  }

  mark {
    color: ${({ theme }) => theme.palette.primary.light};
    background: transparent;
  }

  input,
  button {
    border: none;
    color: ${props => props.theme.palette.text.primary};
  }

  button,
  input[type="submit"],
  textarea {
    cursor: pointer;
    transition: color 0.1s;

    &:focus {
      outline: 1px solid ${props => props.theme.palette.text.primary};
    }

    &.button--icon:hover {
      color: ${props => props.theme.palette.components.PaIcon.hover.main};
    }
  }

  *::selection {
    background: ${({ theme }) => transparentize(0.5, theme.palette.primary.main)};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  input:-webkit-autofill:active
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus ,
  select:-webkit-autofill:active {
    border: none;
    background: ${props => props.theme.palette.background.paper} !important;
    -webkit-text-fill-color: ${props => props.theme.palette.text.primary} !important;
    box-shadow: 0 0 0px 1000px ${props => props.theme.palette.background.paper} inset !important;
    -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.palette.grey[700]} inset !important;
  }

  .MuiAutocomplete-popper {
    .MuiAutocomplete-paper {
      border-radius: 5px;
      box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
        0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
    }

    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-loading {
      color: ${({ theme }) => theme.palette.text.primary};
      background: ${({ theme }) => theme.palette.background.paper};
    }
  }

  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.background.paper};
  }

  .MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 14px;
    text-align: center;
  }
`
