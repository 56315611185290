import { useApi } from 'hooks'
import { Course } from 'models'
import { TeacherCoursesEndpoint } from 'routes/locations'

export const useTeacherCoursesController = () => {
  const [fetchTeacherCourses, teacherCourses] = useApi<Course[]>(TeacherCoursesEndpoint, 'GET')
  const [createTeacherCourse] = useApi<Course>(TeacherCoursesEndpoint, 'POST')

  return {
    teacherCourses,
    fetchTeacherCourses,
    createTeacherCourse,
  }
}
