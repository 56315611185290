import { ReactNode } from 'react'

import { createCtx } from 'providers/utils'

import { useScorePlayer } from '../hooks'

type Props = {
  children: ReactNode
}

type ScorePlayerContext = ReturnType<typeof useScorePlayer>

export const [useScorePlayerContext, ScorePlayerContextProvider] = createCtx<ScorePlayerContext>()

export const ScorePlayerProvider = ({ children }: Props) => {
  return <ScorePlayerContextProvider value={useScorePlayer()}>{children}</ScorePlayerContextProvider>
}
