import { ReactNode } from 'react'

import { createCtx } from 'providers/utils'

import { useScoreSearch } from '.'

type Props = {
  children: ReactNode
}

type ScoreSearchContext = ReturnType<typeof useScoreSearch>

export const [useScoreSearchContext, ScoreSearchContextProvider] = createCtx<ScoreSearchContext>()

export const ScoreSearchProvider = ({ children }: Props) => {
  return <ScoreSearchContextProvider value={useScoreSearch()}>{children}</ScoreSearchContextProvider>
}
