import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useScoreContext } from 'providers'

import { AbcRenderer, AbcTune, AudioPlayer, MidiJsonNote, MidiManager } from '../models'

import { useScorePlayerControls } from '.'

const abcRenderer = new AbcRenderer()
const audioPlayer = new AudioPlayer({ renderer: abcRenderer })
const abcTune = new AbcTune({ renderer: abcRenderer })

export const useScorePlayer = () => {
  const audioRef = useRef(null)
  const sheetMusicRef = useRef(null)

  const [playerNotes, setPlayerNotes] = useState<MidiJsonNote[]>([])
  const [tune, setTune] = useState<string>('')

  const { score } = useScoreContext()
  const playerControls = useScorePlayerControls({
    audioPlayer,
    playerNotes,
    abcRenderer,
    abcTune,
    tune,
    setTune,
  })

  useLayoutEffect(() => {
    if (!score || !sheetMusicRef.current || !audioRef.current) return

    const newTune = abcTune.initTune(score)
    abcRenderer.init(sheetMusicRef, audioRef)
    setTune(newTune)
  }, [score])

  useEffect(() => {
    if (!tune) return

    abcRenderer.renderWithAudio(tune, playerControls.synthOptions)
    const midiBlob = abcRenderer.getMidiFromAbc(score.bpm)
    setPlayerNotes(MidiManager.midiToJson(midiBlob, score.bpm)!)
  }, [tune])

  return {
    audioPlayer,
    audioRef,
    abcTune,
    sheetMusicRef,
    playerNotes,
    playerControls,
  }
}
