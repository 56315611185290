import { useCallback, useEffect, useState } from 'react'

import ActionButton from 'components/ui/ActionButton'

import ErrorIcon from './Icons/ErrorIcon'
import SuccessIcon from './Icons/SuccessIcon'
import WarningIcon from './Icons/WarningIcon'
import { Body, CloseIcon, Container, Footer, Title } from './styles'

const Alert = ({
  title,
  content,
  kind,
  open,
  onOk,
  onCancel,
  onOkLabel = 'Save',
  onCancelLabel = 'Cancel',
  onClose = () => {},
}) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(open)
  }, [open])

  const Icon = useCallback(() => {
    switch (kind) {
      case 'success':
        return <SuccessIcon />
      case 'error':
        return <ErrorIcon />
      case 'warning':
        return <WarningIcon />
      default:
        return <SuccessIcon />
    }
  }, [kind])

  const handleOk = useCallback(() => {
    onOk && onOk()
    setIsVisible(false)
  }, [onOk])

  const handleClose = useCallback(() => {
    onClose()
    onCancel && onCancel()
    setIsVisible(false)
  }, [onClose, onCancel])

  if (!isVisible) return null

  return (
    <Container>
      <CloseIcon size={20} onClick={handleClose} />
      <Icon />

      <Body>
        <Title>{title}</Title>
        <p>{content}</p>
      </Body>

      <Footer $kind={kind}>
        {onOk && (
          <ActionButton
            label={onCancelLabel}
            onClick={handleClose}
            $color={({ theme }) => theme.palette.components.PaAlert[kind].background}
            outline
          />
        )}
        {onCancel && (
          <ActionButton
            label={onOkLabel}
            onClick={handleOk}
            $color={({ theme }) => theme.palette.components.PaAlert[kind].background}
          />
        )}
      </Footer>
    </Container>
  )
}

export default Alert
