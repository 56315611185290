import Fraction from 'fraction.js'

import { modulo } from 'utils/number'
import { NOTE_SCALE } from 'utils/settings'

import { MusicalElement } from '.'

export class Note extends MusicalElement {
  constructor({ value, settings }) {
    super({ value })
    this.settings = settings
    this.abc = this.toAbc()
    this.type = 'note'
  }

  get duration() {
    return this.setAbcDuration()
  }

  toAbc() {
    const { accidental, annotation } = this.settings
    let abc = accidental + annotation

    abc += this.getNoteByOctave()
    abc += this.setAbcDuration()
    return abc
  }

  next() {
    const step = 1
    return this.adjacent(step)
  }

  previous() {
    const step = -1
    return this.adjacent(step)
  }

  // private

  setAbcDuration() {
    const { dot, duration } = this.settings
    const durationFraction = new Fraction(duration).toString()
    const slash = durationFraction < 1 && !dot ? '/' : ''
    const factor = dot ? 1.5 : 1
    const fraction = new Fraction(durationFraction * factor)

    return dot ? `${slash}${fraction.toFraction()}` : duration
  }

  getNoteByOctave() {
    let note
    const { octave } = this.settings

    switch (parseInt(octave, 10)) {
      case 3:
        note = `${this.value.toUpperCase()},`
        break
      case 4:
        note = `${this.value.toUpperCase()}`
        break
      case 5:
        note = `${this.value.toLowerCase()}`
        break
      case 6:
        note = `${this.value.toLowerCase()}'`
        break
      default:
        note = `${this.value.toUpperCase()}`
    }

    return note
  }

  name() {
    const {
      value,
      settings: { octave, accidental },
    } = this
    return `${value}${accidental}${octave}`
  }

  adjacent(step) {
    /* [A, [4]]  || [A, [#, 4]] */
    const [noteName, ...noteChars] = Note.getAdjacentNoteByName(this.name(), step)
    const reversedNoteChars = noteChars.reverse()

    return new Note({
      value: noteName,
      settings: {
        ...this.settings,
        octave: reversedNoteChars[0],
        accidental: reversedNoteChars[1] || '',
      },
    })
  }

  static getAdjacentNoteByName(noteName, step) {
    const currentNoteIndex = NOTE_SCALE.findIndex(note => note === noteName)
    const adjacentNoteIndex = modulo(currentNoteIndex + step, NOTE_SCALE.length)
    return NOTE_SCALE[adjacentNoteIndex]
  }
}
