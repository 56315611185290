import { forwardRef, Ref } from 'react'

import { InputAdornment } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'

import { AlertIcon, Container, Error, StyledTextField } from './styles'
import { InputProps, InputRef } from './types'

export const InputComponent = <T extends FieldValues>(
  {
    label,
    name,
    errorMessage,
    placeholder,
    type = 'text',
    control,
    className,
    icon: Icon,
    ...rest
  }: InputProps<T>,
  _ref: Ref<InputRef>,
) => {
  return (
    <Container className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledTextField
            label={label}
            placeholder={placeholder}
            type={type}
            error={Boolean(errorMessage)}
            {...rest}
            {...field}
            InputProps={
              Icon && {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                ),
              }
            }
          />
        )}
      />
      {errorMessage && (
        <Error title={errorMessage} arrow>
          <AlertIcon color="error" />
        </Error>
      )}
    </Container>
  )
}

export const Input = forwardRef(InputComponent) as <T extends FieldValues>(
  props: InputProps<T> & { ref?: React.ForwardedRef<InputRef> },
) => ReturnType<typeof InputComponent>
