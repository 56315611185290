import {
  UPDATE_FAVORITE_SCORE_END,
  UPDATE_FAVORITE_SCORE_ERROR,
  UPDATE_FAVORITE_SCORE_START,
  UPDATE_FAVORITE_SCORE_SUCCESS,
} from './types'

export const updateFavoriteScoreStart = () => ({
  type: UPDATE_FAVORITE_SCORE_START,
})
export const updateFavoriteScoreSuccess = score => ({
  type: UPDATE_FAVORITE_SCORE_SUCCESS,
  payload: score,
})
export const updateFavoriteScoreError = error => ({
  type: UPDATE_FAVORITE_SCORE_ERROR,
  payload: error,
})
export const updateFavoriteScoreEnd = () => ({
  type: UPDATE_FAVORITE_SCORE_END,
})
