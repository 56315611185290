import { Clipboard } from 'components/ScoreEditor/models'
import { Composer } from 'components/ScoreEditor/models/Composer'

import {
  addElement as addElementSuccess,
  addStaff as addStaffSuccess,
  deleteElement as deleteElementSuccess,
  dragNote as dragNoteSuccess,
  paste as pasteSuccess,
  reset,
  setArticulation as setArticulationSuccess,
  setClipboard as setClipboardSuccess,
  setModifier as setModifierSuccess,
  setSelected as setSelectedSuccess,
  setSettings as setSettingsSuccess,
  setTune as setTuneSuccess,
} from './creators'

export const composer = new Composer()

export const addElement = params => {
  return dispatch => {
    composer.addElement(params)
    const { tune, selectedAbc, staffs } = composer
    dispatch(addElementSuccess({ tune, selectedAbc, staffs }))
  }
}

export const deleteElement = () => {
  return dispatch => {
    composer.deleteElement()
    const { tune, selectedAbc, staffs } = composer
    dispatch(deleteElementSuccess({ tune, selectedAbc, staffs }))
  }
}

export const updateHeader = ({ tune, name, value }) => {
  const key = dynamicSettings[name]
  if (!key) return tune

  const regex = new RegExp(`${key}:(.*)`)
  return tune.replace(regex, `${key}: ${value}`)
}

export const dragNote = ({ step }) => {
  return dispatch => {
    composer.dragNote({ step })
    const { tune, selectedAbc, staffs } = composer
    dispatch(dragNoteSuccess({ tune, selectedAbc, staffs }))
  }
}

export const setSettings = ({ name, value }) => {
  return (dispatch, getState) => {
    const { tune, settings } = getState().composer.present
    const newTune = updateHeader({ tune, name, value })
    const newSettings = { ...settings, [name]: value }

    composer.setSettings(newSettings)
    dispatch(setSettingsSuccess({ tune: newTune, name, value, settings: newSettings }))
  }
}

export const setModifier = ({ value }) => {
  return dispatch => {
    const settings = {
      ...composer.settings,
      dot: value,
    }
    composer.setSettings(settings)
    dispatch(setModifierSuccess({ settings }))
  }
}

export const setArticulation = ({ value }) => {
  return dispatch => {
    const settings = {
      ...composer.settings,
      annotation: value,
    }
    composer.setSettings(settings)
    dispatch(setArticulationSuccess({ settings }))
  }
}

export const setTune = ({ tune }) => {
  return dispatch => {
    composer.setTune(tune)
    dispatch(setTuneSuccess({ tune }))
  }
}

export const setSelected = (currentSelection, isShift) => {
  return dispatch => {
    const { selectedAbc } = composer

    const newSelection = {
      start: { ...(isShift ? selectedAbc.start : currentSelection) },
      end: currentSelection,
    }

    composer.setSelected(newSelection)
    dispatch(setSelectedSuccess(newSelection))
  }
}

export const addStaff = () => {
  return dispatch => {
    composer.addStaff()
    const { tune, selectedAbc, staffs } = composer
    dispatch(addStaffSuccess({ tune, selectedAbc, staffs }))
  }
}

export const setClipboard = () => {
  return dispatch => {
    const copiedElements = Clipboard.copy(composer)
    dispatch(setClipboardSuccess(copiedElements))
  }
}

export const paste = () => {
  return (dispatch, getState) => {
    const { clipboard } = getState().composer.present
    Clipboard.paste(composer, clipboard)

    const { tune, staffs } = composer
    dispatch(pasteSuccess({ tune, staffs }))
  }
}

export const resetAbcjs = () => {
  return dispatch => {
    composer.reset()
    dispatch(reset())
  }
}

export const undoRedo = () => {
  return (_, getState) => {
    const { tune, selectedAbc, staffs } = getState().composer.present

    composer.tune = tune
    composer.selectedAbc = selectedAbc
    composer.staffs = Composer.buildFromJson(staffs)
  }
}

const dynamicSettings = { key: 'K', time: 'M' }
