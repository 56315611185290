import { useCallback } from 'react'

import { FormControlLabel } from '@mui/material'
import { Controller, useController } from 'react-hook-form'
import { HiOutlineTrash } from 'react-icons/hi'

import { Button } from 'components/ui'
import { VIOLIN_RANGE_NOTES } from 'utils/settings'

import {
  Checkbox,
  CheckedNoteIcon,
  CheckedNoteIconOutline,
  Collapse,
  FilterContainer,
  IconButton,
  LevelsContainer,
  NotesContainer,
  Title,
} from './styles'

const Filters = ({ form, open, loading }) => {
  const { control, watch, setValue } = form
  const notes = watch('notes')
  const exclusive = watch('exclusive')
  const levels = watch('levels')

  const { field: notesField } = useController({
    name: 'notes',
    control,
    defaultValue: [],
  })

  const { field: levelsField } = useController({
    name: 'levels',
    control,
    defaultValue: [],
  })

  const handleCheckbox = useCallback(
    (e, isChecked, name) => {
      e.persist()
      const collections = { notes, levels }
      const collection = collections[name]
      const list = isChecked
        ? [...collection, e.target.name]
        : collection.filter(note => note !== e.target.name)

      setValue(name, list)
    },
    [notes, levels, control],
  )

  const handleClearForm = useCallback(() => {
    setValue('notes', [])
    setValue('levels', [])
    setValue('exclusive', null)
  }, [control])

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <FilterContainer>
        <NotesContainer>
          <Title>notes</Title>

          {Object.keys(VIOLIN_RANGE_NOTES).map(note => (
            <FormControlLabel
              key={note}
              label={note}
              control={
                <Checkbox
                  {...notesField}
                  name={note}
                  size="large"
                  onChange={(e, isChecked) => handleCheckbox(e, isChecked, 'notes')}
                  icon={<CheckedNoteIconOutline />}
                  checkedIcon={<CheckedNoteIcon />}
                  checked={notes.includes(note)}
                />
              }
            />
          ))}

          <Controller
            name="exclusive"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                label="exclusive"
                onChange={e => field.onChange(e.target.checked)}
                control={<Checkbox checked={exclusive || exclusive === 'true'} size="small" />}
              />
            )}
          />
        </NotesContainer>

        <LevelsContainer>
          <Title>levels</Title>

          {LEVELS.map(level => (
            <FormControlLabel
              key={level}
              label={level}
              control={
                <Checkbox
                  {...levelsField}
                  name={level}
                  size="small"
                  onChange={(e, isChecked) => handleCheckbox(e, isChecked, 'levels')}
                  checked={levels.includes(level)}
                />
              }
            />
          ))}
        </LevelsContainer>
      </FilterContainer>

      <IconButton size="small" onClick={handleClearForm}>
        <HiOutlineTrash />
      </IconButton>

      <Button type="submit" loading={loading} size="medium" sx={{ alignSelf: 'flex-end', height: 'unset' }}>
        Submit
      </Button>
    </Collapse>
  )
}

export default Filters

const LEVELS = ['easy', 'medium', 'hard']
