import { CourseLecture } from '.'

export class CourseModule {
  id: Id
  title: string
  description: string

  courseLectures: CourseLecture[]

  constructor(courseModuleParams: CourseModule) {
    const { courseLectures: courseLecturesParams } = courseModuleParams
    const courseLectures = courseLecturesParams?.map(params => new CourseLecture(params)) || []
    Object.assign(this, {
      ...courseModuleParams,
      courseLectures,
    })
  }
}
