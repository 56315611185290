import Location from 'app-location'
import * as Yup from 'yup'

export const SignInEndpoint = new Location('/users/sign_in')
export const SignOutEndpoint = new Location('/users/sign_out')
export const ForgotPasswordEndpoint = new Location('/users/password')
export const ResetPasswordEndpoint = new Location('/users/password', null, {
  reset_password_token: Yup.string(),
})

export const ConfirmEmailEndpoint: Location = new Location('/users/confirmation', null, {
  confirmation_token: Yup.string(),
})
