export type SearchOptionApi = {
  id: string
  title: string
  highlight: { title: string }
}

export const buildSearchOptions = (options: SearchOptionApi[]) => {
  if (!options) return []
  return options?.map(option => {
    const { id, title, highlight } = option
    return { id, title, highlight: highlight?.title }
  })
}
