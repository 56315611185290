import { useApi } from 'hooks'
import { Course } from 'models'
import { TeacherCourseEndpoint } from 'routes/locations'

export const useTeacherCourseController = (teacherCourseId: Id) => {
  const [fetchTeacherCourse, teacherCourse] = useApi<Course>(
    TeacherCourseEndpoint,
    'GET',
    { teacherCourseId },
    Course,
  )

  const [updateTeacherCourse] = useApi<Course>(TeacherCourseEndpoint, 'PUT', { teacherCourseId })

  return { fetchTeacherCourse, updateTeacherCourse, teacherCourse }
}
