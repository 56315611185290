import { useEffect } from 'react'

import { Capacitor } from '@capacitor/core'
import { StatusBar } from '@capacitor/status-bar'
import { useLocation } from 'react-router-dom'

import { useThemeContext } from 'providers'

// const ROUTES_WITHOUT_NAVIGATION_BAR = ['/sheet-music', '/tuner']

export const useMobileTheme = () => {
  const { theme } = useThemeContext()

  const location = useLocation()
  const currentPath = location.pathname

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    StatusBar.setBackgroundColor({ color: theme.palette.components.PaTopbar.main })
  }, [theme.palette.mode, currentPath])
}
