import FileManager from 'components/FileManager'
import { PageContainer } from 'components/ui'
import withFolderType from 'hocs/withFolderType'

const FavoriteScores = () => {
  return (
    <PageContainer title="Favorite Scores">
      <FileManager readOnly />
    </PageContainer>
  )
}

export default withFolderType(FavoriteScores, 'favoriteScores')
