import { ForwardedRef, forwardRef, memo } from 'react'

import { useScorePlayerContext } from '../../providers'

import { AbcDisplay, Audio, Container, EditorContainer } from './styles'

type Props = {
  audioRef: ForwardedRef<HTMLDivElement>
}

export const Sheet = forwardRef(({ audioRef, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { playerControls } = useScorePlayerContext()
  const { activePanels } = playerControls
  const isVisible = activePanels.includes('sheet-music')

  return (
    <Container $visible={isVisible} {...rest}>
      <EditorContainer id="sheet-music-container">
        <AbcDisplay id="sheet-music" ref={ref} />
      </EditorContainer>

      <Audio ref={audioRef} />
    </Container>
  )
})

export const SheetMusic = memo(Sheet)
