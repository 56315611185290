import { Container, Content } from './styles'
import Wave from './wave'

const Card = ({ children, index, ...rest }) => {
  return (
    <Container index={index} {...rest}>
      <Content>
        <Wave />

        {children}
      </Content>
    </Container>
  )
}

export default Card
