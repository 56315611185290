import { AbcNotation } from 'components/Composer/models/types'

import { MusicalElementDecorator } from './MusicalElementDecorator'

export class BarDecorator extends MusicalElementDecorator {
  get abcNotation(): AbcNotation {
    if (this.musicalElement.settings.type !== 'bar') {
      const bar = this.settings.value
      return `${bar}${this.musicalElement.abcNotation}` as AbcNotation
    }

    return this.settings.value as AbcNotation
  }
}
