import { subtract, toDecimal } from '.'

export const copyElement = (element, currentIndex, measureIndex, noteIndex, lastSelectedElement) => {
  const unselectedStart = currentIndex < noteIndex && measureIndex === 0
  const unselectedEnd =
    currentIndex > lastSelectedElement.note && measureIndex === lastSelectedElement.measure
  const shouldCopy = element.type !== 'bar'

  if (unselectedStart || unselectedEnd || !shouldCopy) return
  return element
}

export const copyMeasure = (measure, currentIndex, measureIndex, noteIndex, lastSelectedElement) => {
  if (currentIndex < measureIndex || currentIndex > lastSelectedElement.measure) return
  return measure.notes
    .map((element, index) => copyElement(element, index, measureIndex, noteIndex, lastSelectedElement))
    .filter(Boolean)
}

export const copyStaff = (staff, measureIndex, noteIndex, lastSelectedElement) => {
  return staff.measures
    .map((measure, index) => {
      const copiedMeasure = copyMeasure(measure, index, measureIndex, noteIndex, lastSelectedElement)
      measureIndex++
      return copiedMeasure
    })
    .filter(Boolean)
    .flat()
}

export const pasteMeasureNote = ({ measure, element, arr, acc }) => {
  measure.emptyBeats = subtract([measure.emptyBeats, element.duration])
  if (measure.emptyBeats < 0) {
    arr.length = 0
    return acc
  }

  acc.push(element)
  return acc
}

export const pasteMeasureNotes = (measure, clipboard) => {
  return clipboard.slice(0).reduce((acc, element, _, arr) => {
    return pasteMeasureNote({ measure, element, arr, acc })
  }, [])
}

export const populateWithNotes = (measure, notes, elements) => {
  const hasNote = notes.some(elem => elem.type === 'note')
  const elementsDuration = getElementsDuration(elements)

  while (hasNote && notes.length && elementsDuration <= measure.emptyBeats) {
    const notesDuration = getElementsDuration(notes)

    if (notesDuration <= measure.emptyBeats) {
      measure.notes = [...measure.notes, ...notes]
      measure.emptyBeats -= notesDuration
      notes.length = 0
    } else {
      notes.shift()
    }
  }

  return measure.notes
}

export const getElementsDuration = elements => {
  const elementsList = Array.isArray(elements) ? elements : [elements]
  return elementsList.reduce((acc, curr) => acc + toDecimal(curr.duration), 0)
}

export const getRestDuration = elements => {
  const rests = elements.filter(element => element.type === 'rest')
  return getElementsDuration(rests)
}

export const prepareMeasurePaste = ({ measure, measureIndex, noteIndex, clipboard }) => {
  let emptyBeats
  let elements

  if (measureIndex === 0) {
    const notesToReplace = measure.notes.slice(noteIndex)
    const durationToReplace = getElementsDuration(notesToReplace)

    if (durationToReplace < clipboard[0].duration) {
      return { emptyBeats: 0, elements: measure.notes }
    }

    emptyBeats = durationToReplace
    elements = measure.notes.slice(0, noteIndex)
  } else {
    emptyBeats = 8
    elements = []
  }

  return { emptyBeats, elements }
}

export const sliceClipboard = ({ measure, clipboard, measureIndex, initial }) => {
  const prevNotesIndex = measureIndex === 0 ? initial.elements.length : 0
  return clipboard.slice(measure.notes.length - 1 - prevNotesIndex)
}
