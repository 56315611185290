import { Container, Ghost, Link, Text } from './styles'

const NotFound = () => {
  return (
    <Container>
      <Ghost src="https://i.imgur.com/QIxIKBH.png" alt="Page not found" />

      <Text>Page not found</Text>
      <Link to="/">Back to Home</Link>
    </Container>
  )
}

export default NotFound
