import { Box, BoxProps, SxProps, Theme } from '@mui/material'
import styled from 'styled-components'

import { fadeSlideAnimation } from 'styles/keyframes'

type FileBox = {
  $index: number
}

export const attachmentTypographySx = {
  all: 'unset',
  flex: 1,
  textAlign: 'center',
  maxHeight: '48px',
  textTransform: 'none',
  fontSize: 15,
  color: theme => `${theme.palette.text.primary}!important`,
} as SxProps<Theme>

export const Container = styled(Box)<BoxProps & FileBox>`
  width: 120px;
  height: 110px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  opacity: 0;
  animation: ${() => fadeSlideAnimation()} 0.3s ${({ $index }) => $index * 0.04}s forwards;

  svg {
    width: auto;
    flex: 1;
    stroke: ${({ theme }) => theme.palette.primary.main};
    stroke-width: 0.5px;
    fill: rgba(164, 18, 77, 0.1);
  }
`
