export class User {
  id: Id
  email: string
  firstName: string
  lastName: string
  createdAt: string
  admin: boolean
  avatarUrl?: string
  avatarThumbUrl?: string
  pendingConfirmation: boolean

  constructor(userParams: User) {
    Object.assign(this, {
      ...userParams,
    })
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}
