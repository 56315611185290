import { getElementsDuration } from '../../helpers'

export class Accessors {
  get prevElements() {
    return this._prevElements
  }

  set prevElements(elements) {
    this._prevElements = elements
  }

  setPrevElements(elements) {
    if (this.index === 0) {
      this._prevElements = []
      return
    }

    const list = elements || this.notes
    this.prevElements = list.slice(0, this.index)
  }

  get nextElements() {
    return this._nextElements
  }

  set nextElements(elements) {
    this._nextElements = elements
  }

  setNextElements(elements) {
    /* Remove final bar if present */
    const list = elements || this.notes
    const hasFinalBar = list[list.length - 1]?.type === 'bar'
    const end = hasFinalBar ? -1 : 0
    this.nextElements = list.slice(this.index + 1, end)
  }

  get selectedElements() {
    return this._selectedElements
  }

  set selectedElements(element) {
    this._selectedElements = element
  }

  get prevElementsEmptyBeats() {
    return this.calculateRestsDuration(this.prevElements)
  }

  get prevElementsDuration() {
    return getElementsDuration(this.prevElements)
  }

  get nextElementsDuration() {
    return getElementsDuration(this.nextElements)
  }

  get newElementsDuration() {
    return getElementsDuration(this.newElements)
  }

  get selectedDuration() {
    return getElementsDuration(this.selectedElements)
  }

  get emptyBeats() {
    return this._emptyBeats
  }

  set emptyBeats(value) {
    this._emptyBeats = value
  }

  setEmptyBeats() {
    this._emptyBeats = this.calculateRestsDuration()
  }

  get finalBar() {
    return this._finalBar
  }

  set finalBar(value) {
    this._finalBar = value
  }

  setFinalBar() {
    this.finalBar = this.notes[this.notes.length - 1]
  }
}
