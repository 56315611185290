import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  List,
  Typography,
} from '@mui/material'

import { CourseModule } from 'models'
import { CourseLectureCard } from 'pages/courses/components'
import { lectureListSx } from 'pages/courses/components/styles'
import { parameterize } from 'utils'

type Props = {
  courseModule: CourseModule
  pathPrefix?: string
} & Omit<AccordionProps, 'children'>

export const CourseModuleAccordion = ({ courseModule, pathPrefix = '', ...rest }: Props) => {
  if (!courseModule) return

  return (
    <Accordion {...rest}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{courseModule.title}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {!!courseModule.description && (
          <Typography variant="body1" gutterBottom>
            {courseModule.description}
          </Typography>
        )}

        {!!courseModule.courseLectures.length && (
          <List sx={lectureListSx}>
            {courseModule.courseLectures.map(lecture => (
              <CourseLectureCard
                to={`${pathPrefix}lectures/${parameterize(lecture.title)}/${lecture.id}`}
                courseLecture={lecture}
                key={lecture.id}
              />
            ))}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
