import { Midi } from '@tonejs/midi'
import { Note as MidiNote } from '@tonejs/midi/dist/Note'

import { NOTE_MAPPING } from 'utils/settings'

import { Finger, ViolinString } from '../components/Violin/types'

import { DEFAULT_BPM } from './AbcTune/constants'

export type MidiJsonNote = {
  finger?: Finger
  string?: ViolinString
} & MidiNote

type ParseNotes = {
  notes: MidiJsonNote[]
  note: MidiJsonNote
  index: number
}

export class MidiManager {
  static midiToJson(midiBlob: ArrayBuffer, tempo: number = DEFAULT_BPM) {
    const midi = new Midi(midiBlob)
    midi.header.setTempo(tempo)

    const midiJson = MidiManager.convertMidiToJson(midi.clone())
    return midiJson?.tracks?.[0].notes as MidiJsonNote[]
  }

  private static convertMidiToJson(midi: Midi) {
    try {
      const { notes } = midi.tracks[0]

      MidiManager.parseMeta(midi)
      notes.forEach((note, index) => MidiManager.parseNotes({ notes, note, index }))

      return midi
    } catch (error) {
      console.log(error) /* eslint-disable-line */
      return null
    }
  }

  private static parseNotes({ notes, note, index }: ParseNotes) {
    const [string, finger] = NOTE_MAPPING[note.name as keyof typeof NOTE_MAPPING].split('.')

    notes[index].string = string as ViolinString
    notes[index].finger = finger as Finger
  }

  private static parseMeta(json: Midi) {
    // @ts-ignore: make it immutable
    delete json.header.meta
    // @ts-ignore: make it immutable
    delete json.header.name
    // @ts-ignore: make it immutable
    delete json.tracks[0].name
    // @ts-ignore: make it immutable
    delete json.tracks[0].controlChanges

    json.tracks = json.tracks.filter(track => track.notes.length > 0)
  }
}
