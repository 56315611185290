// @ts-nocheck add types
import { useEffect, useState } from 'react'

import Location from 'app-location'
import axios, { AxiosResponse, Method } from 'axios'
import { deserialize as deserializeJsonApi } from 'deserialize-json-api'
import snakecaseKeys from 'snakecase-keys'

import { ApiCall, ApiError } from 'hooks'

import { deserialize, getHeaders, handleUnauthorized } from './utils'

export type Meta = {
  page: number
  per: number
  total: number
  totalPages: number
  next: number
}

type ReturnMeta = {
  loading: boolean
  error: ApiError
  status: number
  meta: Meta
}

const INITIAL_VALUES = {
  loading: false,
  meta: {} as Meta,
} as const

export const useApi = <T>(
  path: Location,
  method?: Method,
  positionalParams?: object,
  Model?: new (...args: T[]) => T,
): [ApiCall<AxiosResponse<T>>, T, ReturnMeta] => {
  const [result, setResult] = useState<T>()
  const [status, setStatus] = useState<number>()
  const [error, setError] = useState<ApiError>()
  const [loading, setLoading] = useState<boolean>(INITIAL_VALUES.loading)
  const [meta, setMeta] = useState<Meta>(INITIAL_VALUES.meta)

  useEffect(() => {
    if (!result) return

    setLoading(false)
  }, [result])

  const call = async (body, queryParams, overlapPositionalParams) => {
    reset()

    const combinedParams = { ...positionalParams, ...overlapPositionalParams }
    const url = `${process.env.REACT_APP_API_URL}${path.toUrl(combinedParams)}`

    setLoading(true)

    try {
      const response: AxiosResponse<T> = await axios({
        method: method || 'GET',
        url,
        data: snakecaseKeys(body || {}, { exclude: ['_destroy'] }),
        params: queryParams,
        headers: getHeaders(),
        validateStatus,
      })

      const normalizedData = deserializeJsonApi(response.data, { transformKeys: 'camelCase' })
      setResult(buildResult(normalizedData.data, Model))
      setStatus(response.status)
      setMeta(deserialize(normalizedData.meta))
      setError(null)

      return response
    } catch (e) {
      const { response } = e
      handleUnauthorized(response?.status)
      const message = response?.data.errors || response?.data.error || response?.data

      setStatus(response?.status)
      setError({ message })
      setLoading(false)
      console.log(e) // eslint-disable-line

      return response
    }
  }

  const reset = () => {
    setStatus(undefined)
    setError(undefined)
    setLoading(INITIAL_VALUES.loading)
    setMeta(INITIAL_VALUES.meta)
  }

  return [call, result, { loading, error, status, meta }]
}

const validateStatus = (requestStatus: number) => {
  return requestStatus >= 200 && requestStatus <= 302
}

const buildResult = <T>(data: T | T[], Model?: new (...args: T[]) => T) => {
  if (Array.isArray(data)) {
    return data.map(record => (Model ? new Model(record) : record))
  }
  return Model ? new Model(data) : data
}
